import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import './Login.css'

import UofTLogo from '../../../styles/global/uoft-logo.gif'
import { connect } from 'react-redux';
import { logInAction } from '../../../store/actions/authActions';

import EmailIcon from '@material-ui/icons/EmailRounded';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import KeyIcon from '@material-ui/icons/VpnKey';
import Footer from '../Footer/Footer';

import {userSignup} from "../../../service/users/registration/dbService";

class Signup extends Component {

    state = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if (!this.state.email || !this.state.firstName || !this.state.lastName || !this.state.password){
            alert("Please fill in every item!")
        }
        else{
            userSignup(this.state.email, this.state.firstName, this.state.lastName, this.state.password)
        }
        
    }

    /* Render function of the class Login*/
    render() {
        const { authError, auth, profile } = this.props;
        
        
        if (auth.uid && profile.onboarding === true) return <Redirect to='/onboarding'></Redirect>;
        if (auth.uid && profile.onboarding === false) return <Redirect to='/overview'></Redirect>;
        return (
            <Container className="square align-self-center login-container">
                <Row style={{ 'height': '100%' }}>
                    <Col
                        style={{ "padding": "0px" }}
                    >
                        {/* <img className="login-image" src={LoginImage} alt="LoginImage" /> */}
                        <div className="login-image">
                            <div className="login-image-inner">
                                <h2> Academic Platform for Language in University Settings</h2>
                                <br></br>
                                <h5>University of Toronto’s online platform to support students’ communication skills</h5>
                               <div className="stay-bottom" style={{ "width":"100%" }}>
                                <a href="https://www.oise.utoronto.ca/ejanglab/research/issd" target="_blank" rel="noreferrer noopener">About Us</a>
                               </div>
                            </div>
                            <Footer></Footer>
                        </div>
                    </Col>
                    <Col
                        className="align-self-center login-right"
                    >
                        <div className="login-logo">
                            <img alt="uoftLogo" src={UofTLogo}></img>
                        </div>
                        <div className="uoft-container">
                            <h2>Register</h2>
                        </div>
                        <Form className="login-form" onSubmit={this.handleSubmit}>
                            <Form.Group >
                                {/* <Form.Label>Email <mark className="ast">*</mark></Form.Label> */}
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <EmailIcon></EmailIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="email" id="email" placeholder="Email" onChange={this.handleChange} />
                                </InputGroup>

                            </Form.Group>
                            <Form.Group >
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                            <AccountCircleRoundedIcon></AccountCircleRoundedIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" id="firstName" placeholder="First Name" onChange={this.handleChange} />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group >
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                        <AccountCircleRoundedIcon></AccountCircleRoundedIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" id="lastName" placeholder="Last Name" onChange={this.handleChange} />
                                </InputGroup>
                            </Form.Group>
                            <Form.Group >
                                <InputGroup>
                                    <InputGroup.Prepend>
                                        <InputGroup.Text>
                                        <KeyIcon></KeyIcon>
                                        </InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="password" id="password" placeholder="Password" onChange={this.handleChange} />
                                </InputGroup>
                            </Form.Group>

                            <Button className="login-button" style={{'background': 'rgba(0, 32, 78)', 'border-color': 'rgba(0, 32, 78)', 'margin-left': "10px"}} type="submit">
                                Sign Up
                            </Button>
                            <div>
                                {authError ? <div> <p style={{ "color": "red" }}>{authError}*</p></div> : null}
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
        );
    }

}
const mapStateTOProps = (state) => {
    
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        logInAction: (credentials) => dispatch(logInAction(credentials))
    }
}

export default connect(mapStateTOProps, mapDispatchToProps)(Signup);