import React, { Component } from "react";
import "./Quiz.css";
//import QuizData from './QuizData'
import QuizCard from "./QuizCard";
import QuizExp from "./QuizExp";
import RichTextEditor from "./RichTextEditor";
//import { ContactSupportOutlined } from '@material-ui/icons';
import { CSSTransition } from "react-transition-group";
import { Alert, AlertTitle } from "@material-ui/lab";

//popup
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
//import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
//import { ContactSupportOutlined } from '@material-ui/icons';
import PrintIcon from "@material-ui/icons/PrintRounded";
import { storage } from "../../../FirestoreRedux";
import { Button } from "react-bootstrap";

const MCQ = "mcq";
const MSQ = "msq";
const openRes = "open-res";
const defaultC = "default";
const correct = "correct";
const incorrect = "incorrect";

//Didn't put these two to state because..
var answer_Data;
var exp_Data;

var QuizData;
var shuffledAnswerOptions;
var index_origin;

//temp max attemp
const maxAttemp = 1;

var studentData = {};

//For Demo: popup hint
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>

      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Quiz extends Component {
  constructor(props) {
    super(props);

    QuizData = this.props.questions;
    //Init
    var CheckedArray = new Array(QuizData.length).fill(false);
    var backColor = new Array(QuizData.length);
    var quizAnswered = new Array(QuizData.length).fill(false);
    answer_Data = new Array(QuizData.length);
    exp_Data = new Array(QuizData.length);
    index_origin = new Array(QuizData.length);

    for (var i = 0; i < QuizData.length; i++) {
      //Create return Object
      studentData[i] = {
        timesAttempted: 0,
        score: 0,
        completed: false,
      };

      if (QuizData[i].qType === MCQ || QuizData[i].qType === MSQ) {
        CheckedArray[i] = new Array(QuizData[i].options.length).fill(false);
        for (var j = 0; j < QuizData[i].userState.response.length; j++) {
          CheckedArray[i][QuizData[i].userState.response[j]] = true;
        }
        backColor[i] = new Array(QuizData[i].options.length).fill("default");

        let tempA = [];
        let tempE = [];
        QuizData[i].options.forEach((each) => {
          tempA.push(each.answer);
          tempE.push(each.exp);
        });
        answer_Data[i] = tempA;
        exp_Data[i] = tempE;

        studentData[i]["answers"] = new Array(QuizData[i].options.length).fill(
          false
        );
      } else {
        backColor[i] = 0;
        studentData[i]["answers"] = null;
      }
    }

    this.state = {
      counter: 0, //# of completed questions
      totalLength: QuizData.length, //# of questions
      questionId: 0,
      question: "",
      answerOptions: [],
      expOptions: exp_Data,
      answerData: answer_Data,
      type: "", //type of question
      image: "",
      wordCount: 0,
      done: false,
      //helpers
      checked: CheckedArray, //which option(s) has been checked
      backColor: backColor, //background color of each option
      qAnswered: quizAnswered, //which questions have been answered
      attempt: maxAttemp,
      changeCard: false, //change to next quiz card
      //helpers for text edior
      editorState: "",
      readState: false,
      //helpers for  popup
      popupOpen: false,
      submit: 0,
    };

    this.getNextQuestion = this.getNextQuestion.bind(this);
    this.setCardState = this.setCardState.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.getCheckedIndex = this.getCheckedIndex.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRetry = this.handleRetry.bind(this);
    this.getRetryButton = this.getRetryButton.bind(this);
    this.getNextTopicButton = this.getNextTopicButton.bind(this);
    this.editorSaveText = this.editorSaveText.bind(this);

    //helpers
    this.colorInit = this.colorInit.bind(this);
    this.getNextQButton = this.getNextQButton.bind(this);
    this.popupOpen = this.popupOpen.bind(this);
    this.popupClose = this.popupClose.bind(this);
    this.answerHelper = this.answerHelper.bind(this);
    // this.getUserAnswers = this.getUserAnswers.bind(this);
    this.updateStudentData = this.updateStudentData.bind(this);
    this.checkQuestionCompletion = this.checkQuestionCompletion.bind(this);
  }

  componentDidMount() {
    try {
      shuffledAnswerOptions = QuizData.map((question, index) => {
        if (question.qType !== openRes) {
          let tempOptions = [];
          index_origin[index] = question.options.map((_opt, idx) => idx);
          question.options.forEach((each) => {
            if ("image" in each) {
              tempOptions.push({
                content: each.text,
                image: each.image,
              });
            } else {
              tempOptions.push({
                content: each.text,
              });
            }
          });
          // not shuffling currently as feedback order is incorrect
          return tempOptions;
        } else {
          return "NULL";
        }
      });

      if ("image" in QuizData[0]) {
        storage
          .ref(QuizData[0].image)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              image: url,
            });
          });
      }

      if (QuizData[0].qType === MCQ || QuizData[0].qType === MSQ) {
        this.setState({
          question: QuizData[0].qText,
          answerOptions: shuffledAnswerOptions[0],
          type: QuizData[0].qType,
        });
      } else {
        this.setState({
          question: QuizData[0].qText,
          type: QuizData[0].qType,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  shuffleArray(options, index) {
    if (options !== null) {
      var array = options.slice();

      var currentIndex = array.length,
        temporaryValue,
        randomIndex;
      var tempIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;

        tempIndex = index_origin[index][currentIndex];
        index_origin[index][currentIndex] = index_origin[index][randomIndex];
        index_origin[index][randomIndex] = tempIndex;
      }
      return array;
    }
  }

  getNextQuestion() {
    // console.log('getNextQuestion')
    if (this.state.counter < this.state.totalLength) {
      const counter = this.state.counter + 1;
      const questionId = this.state.questionId + 1;

      setTimeout(
        () =>
          this.setState({
            counter: counter,
            questionId: questionId,
            question: QuizData[questionId].qText,
            answerOptions: shuffledAnswerOptions[questionId],
            type: QuizData[questionId].qType,
            changeCard: true,
            attempt: maxAttemp,
          }),
        100
      );
    }
  }

  setCardState() {
    this.setState({
      changeCard: false,
    });
  }

  handleCheck(event) {
    var temp = [this.state.checked[0]];
    if (this.state.type === MCQ) {
      temp[this.state.questionId].fill(false);
    }
    temp[this.state.questionId][event.target.name] =
      !this.state.checked[this.state.questionId][event.target.name];
    this.setState({
      checked: temp,
    });
  }

  getCheckedIndex() {
    var temp = [];

    this.state.checked[this.state.questionId].forEach((each, index) => {
      if (each === true) temp.push(index);
    });
    return temp;
  }

  handleSubmit() {
    // console.log('handleSubmit')
    if (this.state.attempt > 0) {
      if (this.state.type === MCQ || this.state.type === MSQ) {
        //Null Check
        let count = 0;
        for (const each of this.state.checked[this.state.questionId]) {
          if (each === true) break;
          else count++;
        }
        if (count !== this.state.checked[this.state.questionId].length) {
          //set question status
          let tempQA = this.state.qAnswered.slice();
          tempQA[this.state.questionId] = true;

          //set background color
          var checkedID = this.getCheckedIndex();
          var tempC = this.state.backColor.slice();
          checkedID.forEach((index) => {
            var colorclass = defaultC;
            if (this.state.answerData[this.state.questionId][index] === true)
              colorclass = correct;
            else colorclass = incorrect;

            tempC[this.state.questionId][index] = colorclass;
          });
          const tempAttempt = this.state.attempt - 1;
          this.setState(
            {
              done: this.state.answerData[this.state.questionId][checkedID],
              backColor: tempC,
              qAnswered: tempQA,
              attempt: tempAttempt,
              submit: this.state.submit + 1,
            },
            () => {
              //update return object
              this.updateStudentData();
            }
          );
        }
      } else {
        //Open response
        let tempQA = this.state.qAnswered.slice();
        tempQA[this.state.questionId] = true;
        // const tempAttempt = this.state.attempt - 1

        this.setState(
          {
            qAnswered: tempQA,
            attempt: 0,
            submit: 0,
          },
          () => {
            //update return object
            this.updateStudentData();
          }
        );
        // NOTE: Since Firebase currently not working. This is a temporary fix to save the answer from the Oral
        // Section (1.5 - Summary) so that the answer can be used for Oral 1.6 - Read Aloud
        // Works by storing in local storage. The other part of this implementation is in ReadAloud.js
        // which checks to see if if the ReadAloud text provided contains "LOCALSTORAGE" and if so,
        // it will fetch the text from local storage.
        if (
          window.location.href.includes("oral") &&
          this.state.question.toLowerCase().includes("summary")
        ) {
          localStorage.setItem("Oral_1.5_Summary", this.state.editorState);
        }
      }
    } else {
      //set question status
      let tempQA = this.state.qAnswered.slice();
      tempQA[this.state.questionId] = true;

      const tempAttempt = 0;
      this.setState({
        qAnswered: tempQA,
        attempt: tempAttempt,
        submit: this.state.submit + 1,
      });
    }

    //send student data to parent component
    // this.props.FunctionName(this.getStudentData())
  }

  handleRetry() {
    //clean checked array
    var tempChecked = this.state.checked.slice();
    tempChecked[this.state.questionId].fill(false);

    var tempColor = this.state.backColor.slice();
    tempColor[this.state.questionId] = this.colorInit(this.state.questionId);

    //reset answer status
    var tempQA = this.state.qAnswered.slice();
    tempQA[this.state.questionId] = false;

    this.setState({
      checked: tempChecked,
      backColor: tempColor,
      qAnswered: tempQA,
    });
  }

  answerHelper() {
    try {
      var allCorrect = true;

      var i = 0;

      this.state.backColor[this.state.questionId].forEach((each) => {
        if (each === incorrect || each === defaultC) i++;
      });

      if (i === this.state.answerData[this.state.questionId].length)
        allCorrect = false;

      return allCorrect;
    } catch (err) {
      console.log(err);
      return "error";
    }
  }

  getStudentData() {
    return studentData;
  }

  /***********helper functions***********/
  colorInit(index) {
    var temp = this.state.backColor[index].fill(defaultC);
    return temp;
  }

  checkQuestionCompletion() {
    var result = false;

    if (this.state.attempt === 0) {
      //used all attempts
      result = true;
    } else if (this.state.attempt === 2) {
      //bug check, shouldn't go here
      // console.log('checkQuestionCompletion, error')
      result = false;
    } else {
      if (this.state.type === MSQ || this.state.type === MCQ) {
        var allCorrect = this.answerHelper();
        if (allCorrect === true) {
          //selected all correct answer
          result = true;
        }
      } else {
        if (this.state.qAnswered[this.state.questionId] === true) {
          result = true;
        }
      }
    }

    return result;
  }

  getNextQButton() {
    var display = false;
    if (this.state.questionId === this.state.totalLength - 1) {
      //last question
      display = false;
    } else {
      display = this.checkQuestionCompletion();
    }

    return display;
  }

  getRetryButton() {
    var display = true;
    if (this.getNextTopicButton() === true) display = false;
    else if (
      this.state.attempt === 0 ||
      this.state.qAnswered[this.state.questionId] === false
    )
      display = false;

    return display;
  }

  getNextTopicButton() {
    var nextBDisplay = false;

    if (this.state.questionId === this.state.totalLength - 1) {
      //reaches the last question
      if (this.state.type === MCQ || this.state.type === MSQ) {
        var allCorrect = this.answerHelper();
        if (
          this.state.attempt === 0 ||
          (allCorrect === true &&
            this.state.qAnswered[this.state.questionId] === true)
        ) {
          //last one answered and it's correct
          //used all attempts
          nextBDisplay = true;
          // this.getUserAnswers();
        }
      } else {
        if (this.state.qAnswered[this.state.questionId] === true)
          nextBDisplay = true;
      }
    }

    return nextBDisplay;
  }

  // getUserAnswers() {
  //     console.log('getUserAnswers')
  //     var result = new Array(QuizData.length)

  //     for (var i = 0; i < result.length; i++) {
  //         result[i] = new Array(index_origin[i].length)
  //         index_origin[i].forEach((value, index) => {
  //             result[i][value] = [this.state.checked[i][value], this.state.backColor[i][value]]
  //         })
  //     }
  //     console.log('getUserAnswers result=', result)

  // }

  updateStudentData() {
    var status = this.checkQuestionCompletion();
    studentData[this.state.questionId]["completed"] = status;

    if (this.state.type === MCQ || this.state.type === MSQ) {
      studentData[this.state.questionId]["timesAttempted"] =
        maxAttemp - this.state.attempt;
      studentData[this.state.questionId]["answers"] =
        this.state.checked[this.state.questionId];
    } else {
      studentData[this.state.questionId]["timesAttempted"] =
        maxAttemp - this.state.attempt;

      // const userInput =  convertToRaw(this.state.editorState.getCurrentContent());
      const userInput =
        this.state.editorState !== ""
          ? this.state.editorState
          : this.props.questions[this.state.questionId].userState.response[0];
      studentData[this.state.questionId]["answers"] = userInput;
    }
    console.log("RESPONSE", studentData);

    this.props.handleQuizData(
      studentData,
      this.props.sectionIndex,
      this.props.subSectionKey
    );
  }

  countWords(s) {
    s = s.replace(/(^\s*)|(\s*$)/gi, ""); //exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, " "); //2 or more space to 1
    s = s.replace(/\n /, "\n"); // exclude newline with a start spacing
    return s.split(" ").filter(function (str) {
      return str !== "";
    }).length;
  }

  editorOnChange = (editorState) => {
    this.setState({ wordCount: this.countWords(editorState.target.value) });
    this.setState({ editorState: editorState.target.value });
  };

  editorSaveText() {
    this.setState({
      readState: true,
    });

    this.handleSubmit();
  }

  popupOpen() {
    this.setState({
      popupOpen: true,
    });
  }
  popupClose() {
    this.setState({
      popupOpen: false,
    });
  }

  render() {
    try {
      if (this.state.type === MCQ || this.state.type === MSQ) {
        if (this.state.answerOptions.length === 2 && this.state.attempt > 1) {
          // if there are only 2 answer options (i.e., true/false), then only allow one attempt
          this.setState({
            attempt: 1,
          });
        }
        return (
          <div className="Quiz">
            <CSSTransition
              classNames="quiz-card-container"
              component="div"
              in={this.state.changeCard}
              transitionName="fade"
              timeout={500}
            >
              <QuizCard
                key={this.state.questionId}
                qType={this.state.type}
                questionId={this.state.questionId}
                question={this.state.question}
                answerOptions={this.state.answerOptions}
                totalLength={this.state.totalLength}
                checkedArray={this.state.checked}
                backColor={this.state.backColor}
                handleCheck={this.handleCheck}
                qAnswered={this.state.qAnswered[this.state.questionId]}
                attempt={this.state.attempt}
                index_origin={index_origin}
                image={this.state.image}
                disable={
                  this.state.done === true ||
                  this.state.submit >= maxAttemp ||
                  this.props.questions[this.state.questionId].userState.response
                    .length >= 1 ||
                  (this.state.answerOptions.length === 2 &&
                    this.state.submit === 1)
                }
              />
            </CSSTransition>
            {this.props.questions[this.state.questionId].userState.response
              .length < 1 && (
              <div>
                <button
                  class={"submit " + !this.getNextTopicButton()}
                  onClick={this.handleSubmit}
                >
                  Submit
                </button>
                {/* <a href="javascript:void();" class={"retry " + this.getRetryButton()} onClick={this.handleRetry} >Retry</a> */}
                <button
                  class={"next " + this.getNextQButton()}
                  onMouseDown={this.getNextQuestion}
                  onMouseUp={this.setCardState}
                >
                  Next Question &raquo;
                </button>
              </div>
            )}
            <CSSTransition
              classNames="quiz-card-container"
              component="div"
              in={this.state.changeCard}
              transitionName="fade"
              timeout={500}
            >
              <QuizExp
                show={
                  this.state.submit >= 1 ||
                  (this.state.answerOptions.length === 2 &&
                    this.state.submit === 1)
                }
                submit={this.state.submit}
                attempts={
                  this.props.questions[this.state.questionId].userState.response
                    .length
                }
                checkedIndex={this.getCheckedIndex()}
                answers={this.state.answerOptions}
                explanations={this.state.expOptions}
                options={QuizData[this.state.questionId].options}
                colorclass={this.state.backColor[this.state.questionId]}
                type={this.state.type}
                answersTF={this.state.answerData[this.state.questionId]}
              />
            </CSSTransition>

            {/* <div class={"congra-container " + this.getNextTopicButton()}>
                        <div className="congra-title">
                            <span>Congratulation! You finished all questions. </span>
                        </div>

                        <div className="buttons-bottom">
                            <a href="javascript:void();" class="nextTopic " onClick={this.handleNextTopic}>Go To Next Topic</a>
                        </div>
                    </div> */}
          </div>
        );
      } else if (this.state.type === openRes) {
        return (
          <div className="Quiz">
            <CSSTransition
              classNames="quiz-card-container"
              component="div"
              in={this.state.changeCard}
              transitionName="fade"
              timeout={500}
            >
              <RichTextEditor
                questionId={this.state.questionId}
                question={this.state.question}
                answer={
                  this.props.questions[this.state.questionId].userState.response
                }
                hideWordCount={this.props.hideWordCount}
                wordCount={this.state.wordCount}
                onChange={this.editorOnChange}
                disable={
                  this.props.questions[this.state.questionId].userState.response
                    .length > 1 || this.state.readState
                }
              />
            </CSSTransition>
            <div
              id="alertMessage"
              className={
                "afterSubmit-container " +
                (this.state.readState ||
                  this.props.questions[this.state.questionId].userState.response
                    .length > 1)
              }
            >
              <Alert
                icon={
                  <CheckCircleOutlineIcon fontSize="inherit" color="primary" />
                }
                className="popup-alert"
                severity="info"
              >
                {"submissionAlertText" in
                  this.props.questions[this.state.questionId] &&
                this.props.questions[this.state.questionId].submissionAlertText
                  .length > 0 ? (
                  <AlertTitle>
                    {
                      this.props.questions[this.state.questionId]
                        .submissionAlertText
                    }
                  </AlertTitle>
                ) : (
                  <AlertTitle>
                    Success! You've submitted your answer.
                  </AlertTitle>
                )}
              </Alert>
              {"feedback" in this.props.questions[this.state.questionId] &&
                this.props.questions[this.state.questionId].feedback.length >
                  0 && (
                  <Button
                    color="primary"
                    className="feedback floatRight"
                    onClick={this.popupOpen}
                  >
                    View Feedback
                  </Button>
                )}
            </div>
            {/* {console.log(this.props.questions[this.state.questionId].userState.response.length, "quiz")} */}
            {this.props.questions[this.state.questionId].userState.response
              .length <= 1 && (
              <div>
                <button
                  class={"submit " + !this.getNextTopicButton()}
                  onClick={this.editorSaveText}
                >
                  Submit
                </button>
                <button
                  class={"retry " + this.getRetryButton()}
                  onClick={this.handleRetry}
                >
                  Retry
                </button>
                <button
                  class={"next " + this.getNextQButton()}
                  onMouseDown={this.getNextQuestion}
                  onMouseUp={this.setCardState}
                >
                  Next Question &raquo;
                </button>
              </div>
            )}

            <Dialog
              onClose={this.popupClose}
              aria-labelledby="customized-dialog-title"
              open={this.state.popupOpen}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={this.popupClose}
              >
                Feedback
                <IconButton
                  onClick={() => window.print()}
                  className="print-icon"
                >
                  <PrintIcon></PrintIcon>
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  {this.props.content[this.props.index]["questions"][
                    this.state.questionId
                  ].feedback !== undefined &&
                    this.props.content[this.props.index]["questions"][
                      this.state.questionId
                    ].feedback
                      .split("\n")
                      .map((item) => (
                        <p dangerouslySetInnerHTML={{ __html: item }} />
                      ))}
                </Typography>
              </DialogContent>
            </Dialog>
          </div>
        );
      } else {
        return (
          <div className="Quiz">
            <h1>Error</h1>
          </div>
        );
      }
    } catch (error) {
      return (
        <div>
          <p>Error occured while loading quiz</p>
        </div>
      );
    }
  }
}

export default Quiz;
