import {db} from '../../../FirestoreRedux.js';
import {extractModuleState} from '../../modules/moduleState';

/**
 * Opts in the user with given userId to the module with moduleId.
 * @param {String} userId 
 * @param {String} moduleId 
 */
export const moduleOptIn = (userId, moduleId) => {
    
    let moduleDocRef = db.collection('modules').doc(moduleId) // Get course

    return db.collection("users").doc(userId).collection('modules').doc(moduleId).get().then(
        function(doc) {
            if (!doc.exists) {
                return moduleDocRef.get().then(function(doc) {
                    if (doc.exists) {
                        const moduleStateTemplate = extractModuleState(doc.data());
                        
                        db.collection("users").doc(userId).collection('modules').doc(moduleId)
                        .set(
                            {
                                title:doc.data().title, 
                                type:doc.data().type, 
                                userState:doc.data().userState, 
                                subModules:moduleStateTemplate
                            }
                        )
                        console.log("Module was set")
                        
                        return {code:200, message:"User successfully opted in the module."};
                    }
                    return {code:400, message:"Invalid moduleId provided"};
                }).catch(function(error) {
                    return {code: 400, message: error};
                });
            }
        }
    )
}

/**
 * Update the module state document in the Firestore collection /users/module
 * for the given userId and moduleId. 
 * @param {String} userId 
 * @param {String} moduleId 
 * @param {Object} updatedModuleData 
 */
export const updateModuleState = (userId, moduleId, updatedModuleData) => {
    return db.collection("users").doc(userId)
      .collection('modules').doc(moduleId)
      .update(updatedModuleData)
      .then(() => {
          return {code: 200, message: "Module updated successfully."}
      })
      .catch((error) => {
          return {code: 400, message: error}
      })
}