import React from "react";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ErrorBar,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const NumberLineChart = ({ score, error, saScore }) => {
  const range = [0, 100];
  const scores = [
    {
      name: score,
      us: 1,
      ls: -1,
    },
    ...(saScore
      ? [
          {
            sat: saScore,
            usa: 1.5,
            lsa: -1.5,
          },
        ]
      : []),
  ];
  // Create error bars array
  const errorBars = Array.from({ length: error }, (x, i) => i).reduce(
    (acc, curr) => [
      ...acc,
      {
        name: score + curr + 1,
        ue: 1,
        le: -1,
      },
      {
        name: score - curr - 1,
        ue: 1,
        le: -1,
      },
    ],
    []
  );
  const data = scores.concat(errorBars);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={200}
        data={data}
        barGap={-30}
        stackOffset="sign"
      >
        {/* Have two X-axis here, one for error and score bars, the other for self assessment bar (because they might overlap) */}
        <XAxis
          dataKey="name"
          type="number"
          ticks={[0, 20, 40, 60, 80, 100]}
          domain={range}
          axisLine={false}
          tickLine={false}
          xAxisId={0}
        />
        <XAxis
          dataKey="sat"
          type="number"
          ticks={[0, 20, 40, 60, 80, 100]}
          domain={range}
          axisLine={false}
          tickLine={false}
          xAxisId={1}
          hide
        />
        <YAxis tick={false} />
        <ReferenceLine y={0} stroke="#000" />
        {/* Bars for score */}
        <Bar
          dataKey="ls"
          fill="navy"
          stackId="stack"
          barSize={15}
          xAxisId={0}
        />
        <Bar
          dataKey="us"
          fill="navy"
          stackId="stack"
          barSize={15}
          xAxisId={0}
        />
        {/* Bars for error */}
        <Bar
          dataKey="ue"
          fill="orange"
          stackId="stack"
          barSize={30}
          xAxisId={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} stroke={"orange"} strokeWidth={1.2} />
          ))}
        </Bar>
        <Bar
          dataKey="le"
          fill="orange"
          stackId="stack"
          barSize={30}
          xAxisId={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} stroke={"orange"} strokeWidth={1.2} />
          ))}
        </Bar>
        {/* Bars for self assessment */}
        <Bar
          dataKey="usa"
          fill="red"
          barSize={5}
          xAxisId={1}
          fillOpacity={0.7}
          stackId="stack2"
        />
        <Bar
          dataKey="lsa"
          fill="red"
          barSize={5}
          xAxisId={1}
          fillOpacity={0.7}
          stackId="stack2"
        />
        <CartesianGrid horizontal={false} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default NumberLineChart;
