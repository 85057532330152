import React, { Component } from "react";
import {connect} from 'react-redux';
import ModuleWrapper from "../../components/Wrappers/ContentView/Module/ModuleWrapper";
import SubModuleWrapper from '../../components/Wrappers/ContentView/SubModule/SubModuleWrapper';
import DashFrame from '../../components/DashFrame/DashFrame';
import { fetchModuleById, fetchModuleStateById } from '../../store/actions/util/moduleActions';
import '../../CSS_share.css'

import {extractModuleState} from '../../service/modules/moduleState'
import {moduleOptIn, updateModuleState} from '../../service/users/contentManagement/dbService';

class ModulePage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageType: 'modulePage', // ModulePage
            selectedSubModule: null,
            subModules: [],
            renderSubModule:true,
        };
        this.switchPageType = this.switchPageType.bind(this)
        // this.userStateChange = this.userStateChange.bind(this)
        this.updateLockedState = this.updateLockedState.bind(this)
        this.updateLockedSections = this.updateLockedSections.bind(this)
    }


    componentDidMount(){
        if (this.props.moduleData.isLoaded === null) {
            try {
                const { match: { params } } = this.props;
                // this.props.fetchModuleById(params.moduleId);
                this.props.fetchModuleStateById(this.props.uid, params.moduleId);
            } catch (err) {
                console.log(err)
            }
        }
    }


    switchPageType(pageName, subModuleIndex){
        this.setState({selectedSubModule: subModuleIndex})
        this.setState({ pageType: pageName});
        
    }

    // userStateChange(updatedSubModuleArray){
        
    //     const { match: { params } } = this.props;
    //     const subModuleStateData = this.props.moduleData.moduleProgressState;
        
    //     subModuleStateData.subModules[parseInt(updatedSubModuleArray.subModuleId)] = updatedSubModuleArray.subModuleData
        
    //     updateModuleState(this.props.uid, params.moduleId, extractModuleState(subModuleStateData))
    // }

    updateLockedSections = (subModuleList, index) => {
        if (index !== 0){
            const contentObj = subModuleList[index - 1][1].content;
            let unlockedModule = Object.keys(contentObj).every( key => contentObj[key].userState.locked === false);
            if (unlockedModule){
                let currentSection = subModuleList[index];
                currentSection[1].userState.locked = false;
            }
            else {
                let currentSection = subModuleList[index];
                currentSection[1].userState.locked = true;
            }

        }

    }

    updateLockedState = (moduleData) => {
        const { match: { params } } = this.props;
        Object.entries(moduleData.subModules).map((item, index) => {
            this.updateLockedSections(Object.entries(moduleData.subModules), index)
            return null;
        })
        if (this.props.uid!== undefined){
            
            updateModuleState(this.props.uid, params.moduleId, extractModuleState(moduleData))
        }
        return null;
        
    }

    render(){
        
        
        let subModules = [];
        const { match: { params } } = this.props;
        const moduleId=params.moduleId

        if (this.props.moduleData.content.subModules !== undefined ){
            
            // this.updateLockedState(this.props.moduleData.content)
            subModules = this.props.moduleData.content.subModules
            
        }

        if (this.props.moduleData.content.subModules === undefined) {
            moduleOptIn(this.props.uid, moduleId)
        }
        return ( 
            <DashFrame location={this.props.location}>
                {this.state.pageType === "modulePage" && (
                    <ModuleWrapper moduleId={moduleId} onSwitchPageType={this.switchPageType} subModules={subModules}/>
                )}
                {this.state.pageType === "subModulePage" && (
                    <SubModuleWrapper 
                        moduleId={moduleId} 
                        subModuleId={this.state.selectedSubModule}
                        content={subModules[this.state.selectedSubModule]} 
                        onSwitchPageType={this.switchPageType}
                        loadDefault={this.state.renderSubModule}
                    />
                )}
            </DashFrame>
        );
    }
}

const mapStateTOProps = (state) => {
    
    return {
        moduleData: state.moduleData.moduleContent,
        uid: state.firebase.auth.uid
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModuleById: (moduleId) => dispatch(fetchModuleById(moduleId)),
        fetchModuleStateById: (userId, moduleId)  => dispatch(fetchModuleStateById(userId, moduleId))
    };
};

export default connect(mapStateTOProps, mapDispatchToProps)(ModulePage);