import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import VideoPlayer from "../../components/Util/VideoPlayer/VideoPlayer.js";

import {Typography, Container, Box, Grid} from '@material-ui/core/';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {storage} from '../../FirestoreRedux'

class Welcome extends Component {

    constructor(props) {
        super(props)
        this.state = {
            videoUrl: null
        }
    }

    async componentDidMount(){
        const url = await storage.ref('videos/promo.mp4').getDownloadURL()
        this.setState({
            videoUrl: url
        });
    }

    render() {
        
        return (
            <>
                <Box style={{textAlign: 'center', backgroundColor: '#25365F', color: 'white', margin: '0', height: '100%', paddingTop: '4rem', paddingBottom: '30px'}}>
                    
                    {this.state.videoUrl !== null && 
                        <VideoPlayer
                        url={this.state.videoUrl}
                    />}
                    
                    <Typography variant='h4' style={{}}>Academic Platform for Language in University Settings</Typography>
                </Box>
                <Box display='flex' style={{paddingLeft: '20%', paddingRight: '5%'}}>
                    <Container style={{marginTop: '60px', height: '100vh', width: '70%'}} >
                        <div style={{'borderRadius': '10px', color: '#25365F'}}>
                            <Typography component={'span'}>
                                <h1>Welcome, {this.props.profile.firstName}</h1>
                            </Typography><br/><br/>
                            <Typography>
                            <b>Welcome to APLUS</b>, Academic Platform for Language in University Settings. On the APLUS platform 
                            you can improve your academic language skills and deepen your understanding of academic integrity. 
                            You will also be able to self-assess your skills and knowledge and then access university resources 
                            to continue your learning.
                            </Typography><br/>
                            <Typography>
                            On the next page, you will be invited to participate in a research project. Your consent is voluntary,
                            and your participation would be greatly appreciated. The purpose of the research is to improve student
                            experience and the perspectives of students are crucial to inform the development of supports for
                            students. There are several levels of participation you can choose from.
                            </Typography><br/>
                            <Typography>
                            After the Consent page, you will be taken to an About-You Questionnaire where you can begin to
                            self-assess your skills.
                            </Typography><br/>
                            <Typography>
                            After completing the About-You Questionnaire, you will be taken to the main page of the platform where 
                            you can select one of the three platform modules.
                            </Typography><br/>
                            <Typography>
                            Enjoy the platform! If you have any questions, please contact <a style={{padding: 0}} href = 'mailto: aplusuoft.contact@gmail.com'>aplusuoft.contact@gmail.com</a>
                            </Typography><br/>
                            <Link style={{ 'cursor': 'pointer', padding: 0 }} to={'/onboarding/consent_form'} >
                                <Typography component={'span'} color="primary"  gutterBottom>
                                    <b>Lets get started</b> <ArrowForwardIcon></ArrowForwardIcon>
                                </Typography>
                            </Link>
                        </div>
                    </Container>
                    <Grid item xs='3'>
                        <Box style ={{marginTop: '60px', backgroundColor: '#e6e6e6', borderRadius: '5px', padding: '2rem'}}>
                            <Typography style={{color: '#25365F'}}><b>Dynamic Feedback</b></Typography>
                            <Typography>Receive ongoing formative feedback at every step.</Typography><br/>
                            <Typography style={{color: '#25365F'}}><b>Authentic Content</b></Typography>
                            <Typography>Learn using case studies and authentic academic tasks.</Typography><br/>
                            <Typography style={{color: '#25365F'}}><b>Flexible Schedule</b></Typography>
                            <Typography>Learn at your own pace.</Typography><br/>
                            <Typography style={{color: '#25365F'}}><b>Certificate upon Completion</b></Typography>
                            <Typography>Earn a certificate when you complete each module.</Typography><br/>
                            <Typography style={{color: '#25365F'}}><b>Resources</b></Typography>
                            <Typography>Explore and bookmark key resources to support communicative competence.</Typography>
                        </Box>
                    </Grid>
                </Box>
            </>
        );
    }
}

const mapStateTOProps = (state) => {
    return {
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(Welcome);