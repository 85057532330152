import React, { Component } from "react";
import ChangePassword from "../../components/Util/ChangePassword/ChangePassword.js";


class ChangePasswordScreen extends Component {

    render() {
        return (
            <ChangePassword>
            </ChangePassword>  
        );
    }
}

export default ChangePasswordScreen;