import React, { Component } from "react";
import { Button } from "@material-ui/core";
import Quiz from "../ReadAnArticle/Quiz";
import SecondDraftPage from "./SecondDraftPage";

class SecondDraftSilentReading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      noDraftSubmission:
        this.props.previousResponse === null ||
        this.props.previousResponse === undefined,
      noQuizSubmission:
        this.props.previousResponse === null ||
        this.props.previousResponse === undefined ||
        this.props.previousResponse.quiz === undefined ||
        this.props.previousResponse.quiz === null,
    };
  }
  submitDraft = (response) => {
    this.props.updateDraftResponse(
      response,
      this.props.sectionIndex,
      this.props.subSectionKey
    );
    this.setState({ noDraftSubmission: false });
  };

  submitQuiz = (response) => {
    this.props.updateDraftQuizResponse(
      response,
      this.props.sectionIndex,
      this.props.subSectionKey
    );
    this.setState({ noQuizSubmission: false });
  };

  render() {
    console.log(this.props);
    return (
      <div>
        {this.state.noDraftSubmission ? (
          <div
            style={{
              justifyContent: "center",
              marginBottom: "15rem",
            }}
          >
            <div style={{ margin: "5px" }}>
              You will now read through your first draft and work on your final
              draft of the critical reflection. Please feel free to add
              additional materials to build on your ideas using additional
              external resources, but don't forget to properly cite the article
              excerpt you've read and any other published materials you use.
            </div>

            <div style={{ margin: "5px" }}>
              Time management is important. <b>Set the timer</b> for how long
              you think it will take you to revise your first draft. Use the
              buttons to set your time. When you are ready, click{" "}
              <b>Start Timer</b> and the timer will start counting down. You can
              click <b>View Instructions</b> to see the requirements of writing
              the critical reflection paper
            </div>

            <div style={{ margin: "5px" }}>
              If you need help with citation styles, please refer back to the{" "}
              <a
                href="http://aplusuoft.com/knowledge/AcademicIntegrity"
                style={{ padding: 0 }}
              >
                Academic Integrity Module
              </a>
              . Click on the <b>Finished</b> button when you have completed your
              final draft writing and are ready to move to the next task. Good
              Luck!
            </div>
            <SecondDraftPage
              pdf={this.props.pdf}
              updateFunction={this.submitDraft}
              sectionIndex={this.props.sectionIndex}
              subSectionKey={this.props.subSectionKey}
              textValue={this.props.firstDraft}
            />
          </div>
        ) : this.state.noQuizSubmission ? (
          <Quiz updateFunction={this.submitQuiz} />
        ) : (
          <div>You have already made a submission for this draft.</div>
        )}
      </div>
    );
  }
}

export default SecondDraftSilentReading;
