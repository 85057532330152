import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Login from '../../components/Util/Login/Login.js'

class LoginScreen extends Component{

    state = {
        login: true
    }

    render(){
        return(
             <div style={{'min-height':'100vh'}}>
            
             <div id="test" className="home-container">
             </div>
             <Container className="login-container-position">  
                 <Login/> 
             </Container>  
         </div>
        );
    }
}
export default LoginScreen;
