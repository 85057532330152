import React, { Component } from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import { connect } from "react-redux";
import { fetchModuleSnippets } from '../../../store/actions/util/moduleActions';
import ModuleWindow from '../../../components/Wrappers/ContentView/ModuleWindow'
import '../../../CSS_share.css'

class WrittenBuildingPage extends Component {
    componentDidMount(){
        if (this.props.moduleData.isLoaded !== true) {
            if(this.props.auth.uid!==undefined){
            this.props.fetchModuleSnippets(this.props.auth.uid, "written");
            }
        }
    }

    componentWillUnmount(){
        if(this.props.auth.uid!==undefined){
            this.props.fetchModuleSnippets(this.props.auth.uid, "clear");
        }

    }

    render(){
        return ( 
            <DashFrame>
                <ModuleWindow uid={this.props.auth.uid} moduleType="written"/>
            </DashFrame>
        );
        
    }
}
const mapStateTOProps = (state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      moduleData: state.moduleData.moduleSnippets,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModuleSnippets: (userId, moduleType) => dispatch(fetchModuleSnippets(userId, moduleType))
    }
}

export default connect(mapStateTOProps,  mapDispatchToProps)(WrittenBuildingPage);