import React, { Component } from "react";
import styles from "./QuizWrittenStyles";
import { withStyles } from "@material-ui/styles";
import {
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Card,
} from "@material-ui/core";
import UnCheckedCircle from "@material-ui/icons/RadioButtonUncheckedRounded";
import CheckedCircle from "@material-ui/icons/CheckCircleRounded";
import { Check } from "@material-ui/icons";
import ReactHtmlParser from "react-html-parser";
import Close from "@material-ui/icons/Close";

class QuizNew extends Component {
  state = {
    checked:
      this.props.previousResponse.length !== 0
        ? this.props.previousResponse[0]
        : null,
    disabled: this.props.previousResponse.length !== 0,
    color: new Array(this.props.question.options.length).fill(null),
    showSubmit: this.props.previousResponse.length === 0,
    showCorrectExp: false,
    correctOpt: null,
    incorrectExp: null,
  };

  handleChange = (e) => {
    this.setState({ checked: parseInt(e.target.name) });
  };

  componentDidMount = () => {
    this.props.question.options.forEach((opt) => {
      if (opt.answer) {
        this.setState({ correctOpt: opt });
      }
    });
  };

  handleSubmit = (e) => {
    console.log("Submitted");
    let checked = this.state.checked;
    let response = [];
    this.props.question.options.forEach((_, idx) => {
      if (idx === checked) return response.push(true);
      return response.push(false);
    });
    this.props.handleQuizData(
      { 0: { answers: response } },
      this.props.sectionIndex,
      this.props.subSectionKey
    );
    this.setState({ disabled: true, showCorrectExp: true, showSubmit: false });
    if (this.props.question.options[checked].answer === true) {
      this.setState({
        color: this.state.color.map((curr, idx) =>
          idx === checked ? "green" : curr
        ),
      });
    } else if (this.props.question.options[checked].answer === false) {
      this.setState({
        color: this.state.color.map((curr, idx) =>
          idx === checked ? "red" : curr
        ),
      });
      this.setState({ incorrectExp: this.props.question.options[checked].exp });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Box className={classes.root}>
        <Box>
          <Box className={classes.quizBox}>
            {this.props.question.qText.split("\n").length === 1 ? (
              <>
                <h6>{ReactHtmlParser(this.props.question.qText)}</h6>
                <hr />
              </>
            ) : (
              this.props.question.qText.split("\n").map((elem) => {
                return <h6>{elem}</h6>;
              })
            )}

            <FormGroup>
              {/*Options */}
              {this.props.question.options.map((opt, idx) => (
                <>
                  <FormControlLabel
                    control={
                      <Checkbox
                        icon={<UnCheckedCircle />}
                        checkedIcon={<CheckedCircle />}
                        checked={this.state.checked === idx}
                        onClick={(e) => this.handleChange(e)}
                        name={idx}
                        disabled={this.state.disabled}
                        color="primary"
                      />
                    }
                    className={
                      this.state.color[idx] === "red"
                        ? classes.incorrect
                        : this.state.color[idx] === "green"
                        ? classes.correct
                        : {}
                    }
                    key={idx}
                    label={
                      <React.Fragment>
                        {ReactHtmlParser(opt.text)}
                      </React.Fragment>
                    }
                  />
                  {idx !== this.props.question.options.length - 1 && <hr />}
                </>
              ))}
            </FormGroup>
          </Box>
          {this.state.showSubmit && (
            <Button className={classes.submit} onClick={this.handleSubmit}>
              Submit
            </Button>
          )}
          {this.state.incorrectExp && (
            <>
              <Card className={classes.hint}>
                <Close style={{ color: "red" }} />
                <span>
                  {this.props.question.options[this.state.checked].text}
                </span>
              </Card>
              <span>
                <b>Explanation: </b>
                {this.state.incorrectExp}
              </span>
            </>
          )}
          {this.state.showCorrectExp && (
            <>
              <Card className={classes.hint}>
                <Check style={{ color: "green" }} />
                <span>{this.state.correctOpt.text}</span>
              </Card>
              <span>
                <b>Explanation: </b>
                {this.state.correctOpt.exp}
              </span>
            </>
          )}
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(QuizNew);
