/**
 * Author: Yuvrender Gill
 * 
 * Functions for managing the state of the module data and user state data.
 * Exports:
 *  1. mergeModuleDataAndUserState -- Merges the moduledata object and user state object
 *  2. Extract module state -- extract the module state template from the module data
 */

/**
 * Replace all the userState fields in the moduleData with the userState fields 
 * from the userState object. 
 * userState object has userState fields and has corresponding similar structure
 * as the moduleData.
 * @param {*} moduleData 
 * @param {*} userState 
 */
export const mergeModuleDataAndUserState = (moduleData, userState) => {
    try {
        moduleData.userState = userState.userState
        moduleData.subModules.map((subModule, subModuleindex) => {
            let subModuleData = subModule;
            subModuleData.userState = userState.subModules[subModuleindex].userState
            Object.entries(subModule.content).map((section, sectionIndex) =>{
                let sectionData = subModuleData.content[sectionIndex]
                let sectionUserState = userState.subModules[subModuleindex].content[sectionIndex]
                sectionData.userState = sectionUserState.userState
                Object.entries(sectionData).map((subSection) => {
                    
                    if (!isNaN(subSection[0])){
                        if (subSection[1].type === 'quiz' || subSection[1].type === 'quizNew'){
                          
                            sectionData[subSection[0]].questions.map((question, questionIndex) => {
                                sectionData[subSection[0]].questions[questionIndex].userState = sectionUserState[subSection[0]].questions[questionIndex].userState
                            })
                        } else {
                            sectionData[subSection[0]].userState = sectionUserState[subSection[0]].userState
                        }
                    }
                })
            })
            moduleData.subModules[subModuleindex] = subModuleData
        })
        return moduleData
    } catch(error){
        console.log(error)
        return moduleData;
    }
    
    
}

/**
 * Return the module state element having state of module. 
 * @param {} moduleData 
 */
export const extractModuleState = (moduleData) => {
    let moduleState = {};
    moduleState['type'] = moduleData['type'];
    moduleState['title'] = moduleData['title'];
    moduleState['modifiedAt'] = Date();
    moduleState['userState'] = moduleData['userState'];
    moduleState['subModules'] = extractSubModuleState(moduleData);
    return moduleState;
}

/**
 * Extract the userState object for each question in the quiz object
 * and return quizData object with all fields removed except questions
 * and all field removed for each question other than userState
 * @param {*} quizData 
 */
const extractQuizState = (quizData) => {
    let questionsArray = []
    quizData.questions.map((question) => {
        if (question.userState){
            questionsArray.push({userState: question.userState})
        }
    })
    return {questions: questionsArray}
}

/**
 * Return the module state element having state of module. 
 * @param {} moduleData 
 */
export const extractSubModuleState = (moduleData) => {
    
    let subModuleStateArray = [];
    let fieldNames = ["userState", "sectionTitle", "sectionIcon", "sectionIconTitle" ];

    moduleData.subModules.map((subModule) => {
        let subModuleObject = {}
        subModuleObject['userState'] = subModule.userState
        subModuleObject['content'] = {}
        
        Object.entries(subModule.content).map((section) => {
            if (section[1].userState){
                subModuleObject['content'][section[0]] = {userState: section[1].userState}
            }
            Object.entries(section[1]).map((subSection) => {
                if (!fieldNames.includes(subSection[0])){
                    if (subSection[1].type === 'quiz' || subSection[1].type === 'quizNew'){
                        subModuleObject['content'][section[0]][subSection[0]] = extractQuizState(subSection[1])
                    } else {
                        if (subSection[1].userState){
                            subModuleObject['content'][section[0]][subSection[0]] = {userState: subSection[1].userState}
                        }
                        else{
                            console.log(subSection[1])
                        }
                        
                    }
                }
            })
        })
        subModuleStateArray.push(subModuleObject)
    })
    return subModuleStateArray;
}

