import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Container, Row, Col} from 'react-bootstrap';
import ToDOList from './ToDo';

class StudentDash extends Component{

    
    render(){
        return (
            <Container fluid>
            <Row>
                <Col sm={6}><ToDOList actionItems={this.props.actionItems}/></Col>
                <Col sm={6}></Col>
            </Row>
            
            </Container> 
        );
    }
}
const mapStateTOProps = (state) => {
  
    return {
      authError: state.auth.authError,
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      actionItems: state.firebase.profile.actionItems
    };
  };

export default connect(mapStateTOProps, null)(StudentDash);

