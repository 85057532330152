
const styles = () => ({
    quizBox: {
      padding: "1rem",
      borderRadius: "3px",
      background: "#fff",
      boxShadow: "5px 5px 10px gray",
      margin: "auto",
    },
    root: {
      margin: 'auto',
      marginTop: "1rem",
      width: "95%"
    },
    submit: {
      backgroundColor: '#f1f1f1',
      color: 'black',
      border: 'none',
      padding: '0.5rem',
      marginTop: '2rem',
      marginLeft: '0.5rem',
      marginRight: '0.5rem',
      transitionDuration: '0.2s'
    },
    hint: {
      display: 'flex',
      borderRadius: 0,
      padding: '1rem',
      marginTop: '2rem',
      placeItems: 'center'
    },
    incorrect: {
        backgroundColor: 'lightcoral',
    },
    correct: {
      backgroundColor: 'lightgreen'
    },
    checkIcon: {
      color: 'green',
      marginRight: '1rem'
    },
    infoIcon: {
      color: '#2196fe',
      marginRight: '1rem'
    }
  });

  export default styles;
  