import React, {Component} from 'react';
import { Container } from 'react-bootstrap';
import Footer from '../../components/Util/Footer/Footer.js';
import SignUp from '../../components/Util/Login/SignUp.js';
import './Home.css'
import '../../CSS_share.css'


class SignUpPage extends Component{
    
    render(){
        return (
            <div style={{'min-height':'100vh'}} >
                <div id="test" className="home-container">
                </div>
                <Container className="login-container-position">  
                    <SignUp/>
                </Container>  
            </div>
        );
    }
}

export default SignUpPage;