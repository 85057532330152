import React, { Component } from "react";
import faqDocs from '../../../static/docs/faq.json';
import { Container } from 'react-bootstrap';
import Typography from '@material-ui/core/Typography';


class Faq extends Component {

    // renderDocTable(faqData) {
    //     return (
    //         <Container style={{ "padding": "20px", "background-color": "rgba(67, 63, 191, 0.2)", 'border-radius': '20px' }}>
    //             <h3>Table of Contents</h3>
    //             <ul>
    //                 {faqData.map((item, index) =>
    //                     <li key={index} onClick={() => { this.renderDocContent(item) }}>{item[0]}</li>
    //                 )}
    //             </ul>
    //         </Container>
    //     );
    // }

    createHeading(parentElement, headingType, headingContent) {
        var heading = document.createElement(headingType);
        heading.innerHTML = headingContent;
        parentElement.appendChild(heading);
    }

    createText(parentElement, textContent) {
        var text = document.createElement("p");
        text.innerHTML = textContent;
        parentElement.appendChild(text);
    }

    renderDocContent(doc) {
        var docItems = Object.entries(doc[1])
        var docContent = document.getElementById("docs-content")
        if (docContent) {
            docContent.innerHTML = "";
            // Create Heading
            this.createHeading(docContent, "h4", doc[0])
            // Insert Doc items
            docItems.map((item) => {
                if (item[0].search("inner") === -1) {
                    this.createHeading(docContent, "h6", item[0])
                    docContent.appendChild(document.createElement("hr"))
                }
                this.createText(docContent, item[1].text)
                return 0;
            })
        }
    }

    render() {
        const faqData = Object.entries(faqDocs)

        //test FAQ data
        const FAQs = [
            {
                question: 'How can I contact the research team of the APLUS platform?',
                answer: 'Ex notandum at eo locusque ignorata materiam ne assignem. Tenus capax patet motum digna rom vix vis ullis. Sacra totum lente gi cogor cujus quare re. Me immittant obversari at convenire ne gi. Quod se quos quod fide at fuit quae im ob. Perciperem consuetudo explicetur sic eam. Desunt operis vacabo to de. Uno cessarem tur utcunque existimo. Assentiar re de meditatio si conflatos devenimus an. Iste sim ejus ego quem enim fere cum novo. '
            },
            {
                question: 'Question Two Placeholder: Qui sensu tamen situm totus magna sic dei mei illas. Si sirenes id discere at credere plausum ad. Poterunt vix rea quatenus loquendo.',
                answer: 'Unde et rari erit unum quae at in ob. Nam machinam proposui dem scilicet existant non. Sed prudentiae imaginabor archimedes mem lor intellectu. Scriptura industria conservat rea lus eversioni. Scripturis perfectius quaedamque imo vix non. Idearum nullibi duo vul imo dicimus discere. Fal iis erit non sane nunc omni. Continetur devenietur id ex denegassem ponderibus imaginatio patrocinio. Falso et se re entis quasi im. Ipsum ne opera at potui ipsis mo. Hic advertisse manifestum uti blandisque objectivae imaginaria assignetur. Re dare dari data ad ex isti ad meas quin. Aeque neque at multo coeco ac. Ullius habens longum necdum negans si ut. '
            },
            {
                question: 'Question Three Placeholder,Summam pudeat capram quo sim nullos vestra exigat.',
                answer: 'Fal utilius confuse mea aliquot quidnam totaque capacem. Alterum quiddam ejusdem ex ne vi exigere ii referam. Productus fortassis sim perspicue via abducerem. Evadit perire docere quanta angeli id certam si.'

            },
            {
                question: 'Question Four Placeholder: Jam hae motarum mem luminis utilius sum.',
                answer: 'Vi immortalem de si cucurbitas perfectior desiderant. Et at concedam delapsus mutuatis importat rogassem ad. Sap obversari mei conjectus contineri pro distingui vix excludere. Sensibus quanquam et ac imagines infinite statuere judicium. Vetus versa ita negat mea nudam qua hoc foret illae. '

            }
        ]
        return (

            <Container style={{ 'padding-top': "40px" }}>
                <Typography>
                <h2>Frequently Asked Questions</h2>
                <hr /> <br></br>
               
                <h5>Who developed the APLUS platform?</h5>
                <p>The Academic Platform for Language in University Settings (APLUS) platform was developed by the IDELA Lab at the Ontario Institute for Studies in Education (OISE) and its development was funded by the University's International Student Experience Fund. The IDELA Lab is made up of graduate students at OISE under the supervision of Dr. Eunice Eunhee Jang in the Department of Applied Psychology and Human Development. <a href="https://www.oise.utoronto.ca/ejanglab/research/issd/"  target="_blank" rel="noreferrer noopener">Click here</a> to learn more about the APLUS research project.</p>
                <hr /> <br></br>
                
                <h5>How can I contact the APLUS research team?</h5> 
                <ul>
                    {/* <li>Click <a style={{"padding":"0px"}} href="https://www.oise.utoronto.ca/ejanglab/research/issd">here</a> to view the introduction to the iSSD research project​</li> */}
                    <li>Email: aplusuoft.contact@gmail.com
</li>
                </ul>
                <hr /> <br></br>
                </Typography>
            </Container>
        );
    }
}

export default Faq;