import React from "react";
import ContentBuilder from '../../components/Wrappers/ContentBuilder/ContentBuilder'
import DashFrame from '../../components/DashFrame/DashFrame'


function ContentUpload(props) {

    return (
        <DashFrame>
            <ContentBuilder/>
        </DashFrame>
    );
}

export default ContentUpload;