import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Box, Button, IconButton } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import TopicTable from "../../../Util/TopicTable/TopicTable";
import Typography from "@material-ui/core/Typography";
import { Beenhere, ExpandMore, Lock } from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";

class ModuleWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleComplete: false,
      expanded: new Array(0),
      path: "",
    };
  }

  componentDidMount() {
    this.setState({
      path:
        this.props.moduleId == "AcademicIntegrity"
          ? "knowledge"
          : this.props.moduleId == "WritingModule"
          ? "written"
          : "oral",
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.moduleData !== undefined &&
      Object.keys(props.moduleData).length !== 0
    ) {
      try {
        let complete =
          props.moduleData.userState.progress + 1 >=
          props.moduleData.userState.totalQuestions;
        if (state.expanded.length === 0) {
          return {
            moduleComplete: complete,
            expanded: new Array(
              Object.keys(props.moduleData.description).length
            ).fill(false),
          };
        }
        // if module is complete, run scoring function
        if (complete) {
          return { moduleComplete: complete };
        }
      } catch (err) {
        console.log(err);
        return {
          expanded: new Array(
            Object.keys(props.moduleData.description).length
          ).fill(false),
        };
      }
    }
    return { expanded: [] };
  }

  toSubModule = (subModuleIndex) => {
    this.props.onSwitchPageType("subModulePage", subModuleIndex);
  };

  handleExpand(index) {
    let newExpanded = this.state.expanded.map((val, idx) => {
      if (index === idx) return !val;
      return val;
    });
    console.log(newExpanded);

    this.setState({ expanded: newExpanded });
  }

  render() {
    const moduleData = this.props.moduleData;

    if (this.props.moduleData.subModules === undefined) {
      return <div></div>;
    }
    const moduleDescription =
      this.props.moduleData.description !== undefined &&
      Object.keys(this.props.moduleData.description).length !== 0 ? (
        <Container fluid>
          <h4 style={{ marginBottom: "3rem" }}>FAQ</h4>
          <hr />
          {Object.entries(this.props.moduleData.description).map(
            (item, index) => (
              <React.Fragment key={index}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    ...(index ===
                    Object.entries(this.props.moduleData.description).length - 1
                      ? { marginBottom: "10rem" }
                      : {}),
                  }}
                >
                  <h5>{item[1].header}</h5>
                  <IconButton
                    size="medium"
                    style={{ borderRadius: 100 }}
                    onClick={() => this.handleExpand(index)}
                  >
                    <ExpandMore />
                  </IconButton>
                </Box>
                <Collapse in={this.state.expanded[index]} timeout="auto">
                  <Typography>{ReactHtmlParser(item[1].body)}</Typography>
                </Collapse>
                <hr></hr>
              </React.Fragment>
            )
          )}
        </Container>
      ) : (
        <></>
      );

    const moduleInstructions =
      this.props.moduleData.instructions !== undefined ? (
        <Container fluid>
          {this.props.moduleData.instructions.header.length > 0 ? (
            <h4>
              {this.props.moduleData.instructions.header}
              <br />
            </h4>
          ) : (
            ""
          )}
          <Typography>
            {ReactHtmlParser(this.props.moduleData.instructions.body)}
          </Typography>
          <br />
        </Container>
      ) : (
        <></>
      );
    return (
      <Container key={this.props.moduleData}>
        <br />
        <br />
        <Typography variant="h3">{moduleData.title}</Typography>
        <hr />
        <br></br>
        <Row>{moduleInstructions}</Row>

        <Row>
          <Col>
            <br />
            <TopicTable
              subModules={moduleData.subModules}
              renderDefault={this.renderOnSubModuleSwitch}
              selectPage={this.props.onSwitchPageType}
              itemId={this.props.subModuleId}
              backButton={false}
            ></TopicTable>
          </Col>
          {/* <Col sm={10} id="module-content">{this.renderSubModuleCards(subModuleList)}</Col> */}
        </Row>
        <Row style={{ justifyContent: "center", alignItems: "center" }}>
          <Button
            variant="contained"
            style={{
              textTransform: "none",
              textDecoration: "none",
              color: "white",
              height: "60px",
              fontSize: "1rem",
              marginBottom: "6rem",
              marginTop: "2rem",
            }}
            color="primary"
            disabled={!this.state.moduleComplete}
            startIcon={!this.state.moduleComplete ? <Lock /> : <Beenhere />}
            component={Link}
            to={"/" + this.state.path + "/postSurvey"}
          >
            {"Survey & Certificate"}
          </Button>
        </Row>
        {moduleDescription}
      </Container>
    );
  }
}

const mapStateTOProps = (state) => {
  return {
    moduleData: state.moduleData.moduleContent.content,
  };
};

export default connect(mapStateTOProps, null)(ModuleWrapper);
