import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { isLoaded, isEmpty } from 'react-redux-firebase'
import { adminEmails } from '../config/adminEmails';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default function AdminRoute({ children, ...rest }) {
  const email = useSelector(state => {return state.firebase.auth.email})

  return (isLoaded(email) && adminEmails.includes(email)) ? <Route {...rest}/> 
          : <Redirect
              to="/overview"
            />
}