import React from "react";
import DashFrame from '../../../components/DashFrame/DashFrame'
import WrittenResources from "../../../components/Wrappers/Docs/WrittenResources";
import '../../../CSS_share.css'

function WrittenResourcePage( props ) {
    return (
        <DashFrame>
            <WrittenResources/>
        </DashFrame>
    );
}
export default WrittenResourcePage;