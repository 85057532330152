export const logInAction = (credentials) => {
    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(function() {
            
            return firebase.auth().signInWithEmailAndPassword(
                credentials.email,
                credentials.password
            );
            
        }).then(() => {
            dispatch({ type: "LOGIN_SUCCESS" })
        }).catch((err) => {
            dispatch({ type: "LOGIN_ERROR", err })
        })
    }
}

export const logOutAction = () => {
    return (dispatch, getState, {getFirebase}) => {

        const firebase = getFirebase();

        firebase.auth().signOut().then(() => {
            sessionStorage.clear()
            dispatch({
                type: "LOGOUT_SUCCESS"
            })
        }).catch((err) => {
            dispatch({
                type: "LOGOUT_ERROR",
                err
            })
        })
    }
}