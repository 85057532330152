const t5=    {
    "subModuleTitle": "How to Maintain Academic Integrity in Group Assignments",
    "userState": { "progress": 0, "locked": false },
    "content": {
      "0": {
        "0": {
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "text": "Many courses often include assignments that require group work. Do you have any group work experience where you were not sure what to do? Here is a common but difficult situation you may already have encountered.",
          "type": "text"
        },
        "1": {
          "text": "Watch the video and see if you notice any issues in this very possible scenario.",
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": ""
        },
        "2": {
          "url": "videos/academicIntegrity/t5p1.mp4",
          "type": "video",
          "subSectionTitle": "",
          "srcType": "url",
          "userState": { "viewed": false }
        },
        "sectionIcon": "info",
        "sectionTitle": "5.1. Introduction",
        "sectionIconTitle": "Introduction",
        "userState": { "locked": false, "completed": false }
      },
      "1": {
        "0": {
          "type": "text",
          "subSectionTitle": "",
          "text": "Now, let’s reflect on the situation and then respond to Fatima’s message. You can watch the video again if you would like.",
          "userState": { "viewed": false }
        },
        "1": {
          "type": "video",
          "srcType": "url",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "url": "videos/academicIntegrity/t5p1.mp4"
        },
        "2": {
            "subSectionTitle": "",
            "questions": [
              {
                "userState": { "response": [""] },
                "qText": "After receiving Fatima's message that she will not be able to contribute to the group work but still wishes to receive the same grade, how would you respond to Fatima's message?",
                "qId": "0",
                "qType": "open-res",
                "sectionComplete": true,
                "feedback": "Thank you for sharing! We will provide feedback to your response after you answer the question in 5.2. Common Situation (2).​"
              }
            ],
            "type": "quiz"
          },
        "sectionIcon": "quiz",
        "userState": { "locked": true, "completed": false },
        "sectionIconTitle": "Common Situation (1)",
        "sectionTitle": "5.2. Common Situation (1)"
      },
      "2": {
        "0": {
          "text": "Now, you’ve thought about the situation. Answer the following question. You can watch the video again if you would like.",
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "1": {
          "userState": { "viewed": false },
          "url": "videos/academicIntegrity/t5p1.mp4",
          "srcType": "url",
          "subSectionTitle": "",
          "type": "video"
        },
        "2": {
            "subSectionTitle": "",
            "type": "quiz",
            "questions": [
            {
                "userState": { "response": [] },
                "qId": "0",
                "qText": "Based on their text message exchange, who do you think did not follow academic integrity guidelines? \n Select the best answer",
                "sectionComplete": true,
                "qType": "mcq",
                "options": [
                {
                    "text": "None of them",
                    "oId": "0",
                    "exp": "Incorrect. Since Fatima’s name was on the submitted assignment but she did not complete any of the work herself, this is considered providing unauthorized assistance. By agreeing to submit the assignment with Fatima’s name on it, Sohee and Eric are contributing to the academic offense. Not only using unauthorized assistance in any academic work (i.e. Fatima’s behaviour) but also doing (or omitting to do) anything for the purpose of assisting another member to commit the offense (i.e. Sohee and Eric’s behaviour) is a violation of academic integrity. ",
                    "answer": false
                },
                {
                    "oId": "1",
                    "answer": false,
                    "exp": " Incorrect. Since Fatima’s name was on the submitted assignment but she did not complete any of the work herself, this is considered providing unauthorized assistance. By agreeing to submit the assignment with Fatima’s name on it, Sohee and Eric are contributing to the academic offense. Not only using unauthorized assistance in any academic work (i.e. Fatima’s behaviour) but also doing (or omitting to do) anything for the purpose of assisting another member to commit the offense (i.e. Sohee and Eric’s behaviour) is a violation of academic integrity. ",
                    "text": "Fatima"
                },
                {
                    "text": "Fatima and Eric",
                    "answer": false,
                    "oId": "2",
                    "exp": "Incorrect. Since Fatima’s name was on the submitted assignment but she did not complete any of the work herself, this is considered providing unauthorized assistance. By agreeing to submit the assignment with Fatima’s name on it, Sohee and Eric are contributing to the academic offense. Not only using unauthorized assistance in any academic work (i.e. Fatima’s behaviour) but also doing (or omitting to do) anything for the purpose of assisting another member to commit the offense (i.e. Sohee and Eric’s behaviour) is a violation of academic integrity. "
                },
                {
                    "exp": "Correct.  Since Fatima’s name was on the submitted assignment but she did not complete any of the work herself, this is considered providing unauthorized assistance. By agreeing to submit the assignment with Fatima’s name on it, Sohee and Eric are contributing to the academic offense. Not only using unauthorized assistance in any academic work (i.e. Fatima’s behaviour) but also doing (or omitting to do) anything for the purpose of assisting another member to commit the offense (i.e. Sohee and Eric’s behaviour) is a violation of academic integrity. ",
                    "text": "Fatima, Sohee, and Eric",
                    "oId": "3",
                    "answer": true
                }
                ]
            }]
        },
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Common Situation (2)",
        "sectionIcon": "comment",
        "sectionTitle": "5.2. Common Situation (2)"
      },
      "3": {
        "0": {
          "userState": { "viewed": false },
          "text": "Now, let's watch another video to learn what the group could have done.",
          "type": "text",
          "subSectionTitle": ""
        },
        "1": {
          "srcType": "url",
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "type": "video",
          "url": "videos/academicIntegrity/t5p2.mp4"
        },
        "sectionTitle": "5.3. Issues and Strategies",
        "sectionIcon": "video",
        "sectionIconTitle": "Issues & Strategies",
        "userState": { "locked": true, "completed": false }
      },
      "4": {
        "0": {
          "url": "videos/academicIntegrity/t5p2.mp4",
          "subSectionTitle": "",
          "srcType": "url",
          "userState": { "viewed": false },
          "type": "video"
        },
        "1": {
          "questions": [
            {
              "feedback": "We hope you have made necessary changes to your response that reflect your learning. Again, always remember that not only the student who fails to contribute but also other group members who are aware of their peer’s unfair behaviour but do not take appropriate actions, may be violating academic integrity.​​",
              "qId": "0",
              "qText": "Based on what you just learned, would you like to change your answer to the previous question: \n“After receiving Fatima's message that she will not be able to contribute to the group work but still wishes to receive the same grade, how would you respond to Fatima's message?” \n Please provide your new answer below:",
              "userState": { "response": [""] },
              "qType": "open-res"
            }
          ],
          "subSectionTitle": "Let’s reflect on the situation and answer the two questions below. ",
          "type": "quiz"
        },
        "2": {
          "questions": [
            {
              "feedback": "We hope you have learned some strategies just in case you encounter a situation like what happened with Fatima. It was good that Fatima communicated with her fellow classmates as some students do not feel the need to share private information with their peers, nor are they required to. But she should have also alerted the instructor that her ability to complete the assignment was restricted, and let the instructor decide how she could make up the assignment in another way.",
              "qText": "Have you ever encountered a situation like Fatima, where you could not contribute to a group assignment as much as you were supposed to? What would you have done similarly or differently?",
              "qType": "open-res",
              "sectionComplete": true,
              "topicComplete": true,
              "userState": { "response": [""] },
              "qId": "0"
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "userState": { "completed": false, "locked": true },
        "sectionTitle": "5.4. Issues and Strategies - Reflection",
        "sectionIcon": "comment",
        "sectionIconTitle": "Issues & Strategies - Reflection"
      }
    }
  }

export default t5;