import React, { Component } from 'react';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import '../../../styles/global/CSS_share.css'


class TopicTable extends Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            visible: true,
            visibility: 'show'
        };
    }

    toSubModule = (subModuleIndex, locked) => {
        try {

            if (!locked){
                this.props.selectPage('subModulePage', subModuleIndex);   
                this.props.renderDefault(true); 
            }
            
        } catch (err) {
            console.log(err)
        }
             
    }
    toModule = () => {
        try {
            this.props.selectPage('modulePage', 0);   
        } catch (err) {
            console.log(err)
        }
             
    }
    
    render() {
        
        const item_list = this.props.subModules;
        const listItemComponents = item_list.map((subModule, index) =>
            <div key={index} className="side-bar-item-container ">
                    <ListItem selected={this.props.itemId === index} button key={index} onClick={() => this.toSubModule(index, subModule.userState.locked)}>
                        <ListItemText 
                            style={subModule.userState.locked ? {'color':"#bdbdbd"} :{'color':"#43425D"}}
                        >
                            {"Topic "}{index+1}{": "}{subModule.subModuleTitle}
                        </ListItemText>
                    </ListItem>
                    <Divider></Divider>
            </div>
        )

        return (
            <div> 
            <List  subheader={
                <ListSubheader disableSticky component="div" id="nested-list-subheader">
                    <h4>Topics</h4>
                </ListSubheader>
            }>
            {listItemComponents}
            {this.props.backButton && 
                <ListItem button onClick={() => this.toModule()}>
                    <ArrowBackIosIcon/>
                    <ListItemText style={{'color':"#43425D"}}>{"Module Overview"}</ListItemText>
                </ListItem>
            }
            </List>
            
            </div>
        )
    }
}



export default TopicTable;