import React, { Component } from "react";
import Media from "react-bootstrap/Media";
import { Avatar, Dialog, Typography, Box } from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Help } from "@material-ui/icons";

class UserInfoTab extends Component {
  state = {
    open: false,
  };

  renderAccountType(accountType) {
    if (accountType === "student") {
      return (
        <p>
          <strong>Student Number:</strong> {this.props.accountNumber}
        </p>
      );
    } else if (accountType === "issd-dev") {
      return (
        <p>
          <strong>Account Type:</strong> APLUS Developer Account
        </p>
      );
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  renderScoreInfoDialog(props) {
    const { onClose, open } = props;

    const handleClose = () => {
      onClose();
    };

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Some General Score Info</DialogTitle>
        <Box style={{ margin: "20px" }}>
          <Box style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Score:
            </Typography>
            <Typography variant="body1">
              The scores represent your measured ability levels in each domain
              and in your overall English ability.
            </Typography>
          </Box>
          <Box style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Bands:
            </Typography>
            <Typography variant="body1">
              The bands represent the broader levels that your scores fall into.
              There are 4 bands. Band 4 indicates that you have mastered the
              English skill. Band 3 indicates that you are very satisfactory in
              the skill. Bands 1 and 2 indicate an area that you might want to
              target for improvement.
            </Typography>
          </Box>
          <Box style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Score Range:
            </Typography>
            <Typography variant="body1">
              The score range represents the level of uncertainty involved in
              the measure. All measures contain some degree of uncertainty.
            </Typography>
          </Box>
          <Box style={{ marginBottom: "20px" }}>
            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
              Self Assessment:
            </Typography>
            <Typography variant="body1">
              Your self-assessment is based on your responses to questions about
              your abilities in 4 of the skills. You can compare how your
              self-assessment relates to your measured ability.
            </Typography>
          </Box>
        </Box>
      </Dialog>
    );
  }

  render() {
    return (
      <div>
        <Media style={{ alignItems: "center" }}>
          <Avatar
            style={{ width: "110px", height: "110px" }}
            fontSize="180px"
            className="align-self-center mr-5"
            alt={this.props.userName}
            src={this.props.imageUrl}
          />
          <Media.Body>
            <h1>{this.props.userName}</h1>
            <p>
              <strong>Email:</strong> {this.props.userEmail}
            </p>
            {/* { this.renderAccountType(this.props.accountType)} */}
          </Media.Body>
          {!this.props.overview && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
                onClick={this.handleClickOpen}
              >
                <Help style={{ color: "lightgrey", fontSize: "80px" }} />
                Score Info
              </div>
              {this.renderScoreInfoDialog({
                open: this.state.open,
                onClose: this.handleClose,
              })}
            </>
          )}
        </Media>
        <hr />
      </div>
    );
  }
}

export default UserInfoTab;
