import React from "react";
import Timer from "../ReadAnArticle/Timer";
import { Button } from "@material-ui/core";
import InstructionsDialog from "./InstructionsDialog";
import DraftAndPdf from "./DraftAndPdf";

function countWords(s){
  s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
  s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
  s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
  return s.split(' ').filter(function(str){return str!=="";}).length;
  //return s.split(' ').filter(String).length; - this can also be used
}
class SecondDraftPage extends React.Component {
  state = {
    finishDraft: false,
    instructionsOpen: false,
    draftContent: this.props.textValue || '',
    wordCount: countWords(this.props.textValue || '')
  };

  onFinish = (timeTaken) => {
    console.log(timeTaken);
    console.log(this.state.draftContent);
    this.props.updateFunction(this.state.draftContent);
  };

  onChange = e => {
    this.setState({draftContent: e.target.value, wordCount: countWords(e.target.value)})
  }

  onInstructionsClose = () => {
    this.setState({ instructionsOpen: false });
  };

  render() {
    return (
      <>
        {this.state.instructionsOpen && (
          <InstructionsDialog
            onClose={this.onInstructionsClose}
            isOpen={this.state.instructionsOpen}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Timer onFinish={this.onFinish} />
          <Button
            variant="contained"
            onClick={() => this.setState({ instructionsOpen: true })}
          >
            View Instructions
          </Button>
        </div>
        <DraftAndPdf pdf={this.props.pdf} textValue={this.state.draftContent} onChange={this.onChange} wordCount={this.state.wordCount}/>

      </>
    );
  }
}

export default SecondDraftPage;
