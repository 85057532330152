import React, { Component, useDebugValue, useState } from "react";
import PdfRender from "./PdfRender";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { ButtonGroup, Button, Radio, RadioGroup, FormControlLabel, Card } from "@material-ui/core";

import { pdfjs } from "react-pdf";

import { CountdownCircleTimer } from "react-countdown-circle-timer";
import QuizWritten from "../QuizWritten/QuizWritten";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);



class SilentReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeTaken: 100000,
      start: false,
      readFinish: false,
      finish: false,
      play: false,
      pdf: this.props.pdf,
      currentPdf: 0,
      isFeedbackOpen: false,
      content: `I am the default text in here`,
      timeCounter: 20,
      responseValue1: "1",
      responseValue2: "1",
      previouslyAnswered: false
    };
    this.setOpenFeedback = this.setOpenFeedback.bind(this);
    this.setCloseFeedback = this.setCloseFeedback.bind(this);
  }


  setOpenFeedback() {
    this.setState({ isFeedbackOpen: true });
  }

  setCloseFeedback() {
    this.setState({ isFeedbackOpen: false });
  }


  showDisplay(totalTime, timeRemaining) {
    if (totalTime == 0) {
      this.setState({ readFinish: true });
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <div>{timeRemaining} : {totalTime % 60}</div>
        <div style={{ fontSize: "20px" }}>Minutes left</div>
      </div>
    );
  }

  componentDidMount() {
    if(this.props.previousResponse){
      this.setState({content: this.props.previousResponse, previouslyAnswered: true})
    }
  }

  handleChange = e => {
    this.setState({content: e.target.value})
  }

  handleIncrement = e => {
    this.setState({timeCounter: this.state.timeCounter + 1})
    this.forceUpdate()
  }

  handleDecrement = e => {
    this.setState({timeCounter: this.state.timeCounter - 1})
    this.forceUpdate()
  }

  handleQuizResponse = (e, qNumber) => {
    if (qNumber === 1) this.setState({responseValue1: e.target.value,
      content: (typeof this.state.content === 'string') ? [e.target.value, 0] : [e.target.value, this.state.content[1]]})
    else this.setState({responseValue2: e.target.value,
      content: (typeof this.state.content === 'string') ? [0, e.target.value] : [this.state.content[1], e.target.value]})
  }


  render() {
    if (this.state.finish) return <span>You've successfully finished this section. Please move onto the next one!</span>


    return (
      <div>
        {!this.state.start && !this.state.readFinish ? (<>
            
            <div>
              <p dangerouslySetInnerHTML={{ __html: this.props.instructions }} />
              <Button
                style={{
                  background: "#7270a1",
                  borderColor: "rgba(0, 32, 78)",
                  marginRight: "10px",
                  color: "white"
                }}
                onClick={() => {
                  this.setState({ start: true });
                  if(this.props.onChange){
                    this.props.onChange()
                  }
                }}
              >
                Start
              </Button>
            </div>
          </>
        ) : this.state.start ? (
          <div style={{ "font-size": "30px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              {this.state.play && <CountdownCircleTimer
                isPlaying={this.state.play}
                duration={this.state.timeCounter * 60 }
                initialRemainingTime={this.state.timeCounter * 60}
                colors={[
                  ["#004777", 0.33],
                  ["#F7B801", 0.33],
                  ["#A30000", 0.33],
                ]}
              >
                {({ remainingTime }) =>
                  this.state.readFinish
                    ? this.setState({ timeTaken: remainingTime, start: false })
                    : this.showDisplay(
                      remainingTime,
                      Math.floor(remainingTime / 60)
                    )
                }
              </CountdownCircleTimer>}

              <div style={{display: "flex", placeItems: "center", justifyContent: "center", fontSize: "16px"}}>
                <div>
                  <div>Set Time (in minutes)</div>
                  <ButtonGroup size="small" aria-label="small outlined button group" style={{marginBottom: '1rem', marginRight: '3rem'}}>
                    <Button onClick={this.handleDecrement} disabled={this.state.play}>-</Button>
                    <Button disabled style={{backgroundColor: "white", color: "black"}}>{this.state.timeCounter}</Button>
                    <Button onClick={this.handleIncrement} disabled={this.state.play}>+</Button>
                  </ButtonGroup>
                </div>
                <ol>
                  <li>Read the instructions carefully.</li><br/>
                  <li>How difficult is the task for you?</li><br/>
                  <li>How much time do you need to complete the task?</li><br/>
                  <li>Set the timer to estimate how long you will take to do the task.</li><br/>
                  <li>When you finish the task, note the time difference between your initial prediction and the actual time you took to complete the task.</li>
                </ol>
              </div>
            </div>
            <div style={{display: "flex", justifyContent: "center"}}>
            {!this.state.play ? <Button
                style={{
                  background: "#7270a1",
                  borderColor: "rgba(0, 32, 78)",
                  marginRight: "10px",
                  color: "white"
                }}
                onClick={() => {
                  this.setState({ play: true });
                }}
              >
                Start Timer
              </Button>
              :
              <Button
                style={{
                  background: "#7270a1",
                  borderColor: "rgba(0, 32, 78)",
                  marginRight: "10px",
                  color: "white",
                }}
                onClick={() => {
                  this.setState({ readFinish: true });
                  if (this.props. updateFunction) this.props.updateFunction(this.state.content, this.props.sectionIndex, this.props.subSectionKey);
                  if(this.props.updatePdf){
                    this.props.updatePdf();
                  }
                }}
              >
                Finished
              </Button>}
              
              {!this.props.hideInstructions && <Button variant="contained" onClick={() => { this.setState({isFeedbackOpen: true})}}>
                View Instructions
              </Button>}
            </div>
            {this.props.pdfComponent ? (
              <div style={{ paddingBottom: "2rem", paddingTop: "2rem" }}>


                <PdfRender draft={false} pdf={this.state.pdf[0]} stretch={this.props.stretch}/>


              </div>
            ) : (
              <div className="outer3">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-evenly" }}>
                    {this.state.pdf.length > 1 ? this.state.pdf.map((_, index) => {
                      return (
                        <div>
                          <Button style={{
                            background: "#7270a1",
                            borderColor: "rgba(0, 32, 78)",
                            color: "white"
                          }} onClick={() => { this.setState({ currentPdf: index }) }}>{index+1== 1 ? 'Main' : `Source ${index}`}</Button>
                        </div>
                      );
                    }) : ""}
                  </div>
                  <PdfRender style={{ height: "100rem" }} draft={true} pdf={this.state.pdf[this.state.currentPdf]} stretch={this.props.stretch}/>
                </div>



                {this.state.isFeedbackOpen && <Dialog
                        onClose={this.setCloseFeedback}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.isFeedbackOpen}
                      >
                        <DialogTitle
                          id="customized-dialog-title"
                          onClose={this.setCloseFeedback}
                        >
                          Instructions
                        </DialogTitle>
                        <DialogContent dividers>
                          <Typography gutterBottom>
                            <div>Instructions: Write a critical Reflection</div>
                            <div>- Length: 500 words</div>
                            <div>- Include: </div>
                            <ul>
                              <li>Content:
                                <ul>
                                  <li>Introduction</li>
                                  <li>Summary</li>
                                  <li>Critique</li>
                                  <li>Short Conclusion</li>
                                </ul>
                              </li>

                              <li>
                                Additional Source:
                                <ul>
                                  <li>Information from at least one other article</li>
                                </ul>
                              </li>
                              <li>Citation:
                                <ul>
                                  <li>In text citation</li>
                                  <li>Reference list (italics not required)</li>
                                </ul>
                              </li>
                            </ul>
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            autoFocus
                            onClick={this.setCloseFeedback}
                            color="primary"
                          >
                            Close
                          </Button>
                        </DialogActions>
                  </Dialog>}



                <div className="box">
                  <form
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      // width: "90%",
                    }}
                  >
                    <textarea
                      value={this.state.content}
                      onChange={this.handleChange}
                      style={{
                        fontSize: "20px",
                        height: "90%",
                        width: "90%",
                      }}
                    ></textarea>
                  </form>
                </div>
              </div>
            )}
          </div>
        ) : !this.state.previouslyAnswered ? (
          <div>
            <Card style={{padding: "1.5rem"}}>
              1. On a scale of 1 to 5, where 5 is the most difficult, how would you rate the difficulty of this task?
              <RadioGroup row value={this.state.responseValue1} onChange={e => this.handleQuizResponse(e, 1)}>
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Card>
            <Card style={{padding: "1.5rem"}}>
              2. Based on a scale of 1 to 5, where 5 represents your ability to manage your time effectively, how accurate was your time estimation to complete the task?
              <RadioGroup row value={this.state.responseValue2} onChange={e => this.handleQuizResponse(e, 2)}>
                <FormControlLabel value="1" control={<Radio />} label="1" />
                <FormControlLabel value="2" control={<Radio />} label="2" />
                <FormControlLabel value="3" control={<Radio />} label="3" />
                <FormControlLabel value="4" control={<Radio />} label="4" />
                <FormControlLabel value="5" control={<Radio />} label="5" />
              </RadioGroup>
            </Card>

            <Button
                style={{
                  background: "#7270a1",
                  borderColor: "rgba(0, 32, 78)",
                  marginRight: "10px",
                  color: "white",
                  marginTop: "1.5em"
                }}
                onClick={() => {
                  this.setState({ finish: true });
                  if (this.props. updateFunction) this.props.updateFunction(this.state.content, this.props.sectionIndex, this.props.subSectionKey);
                  if(this.props.updatePdf){
                    this.props.updatePdf();
                  }
                }}
              >
                Submit
              </Button>
            {/* Can remove the bottom line after storing on firebase is complete. Keep as reference until then. */}

            {/* Uncomment this later  */}
            {/* <div>{this.state.timeTaken}</div> */}
          </div>
        ): <span>You've already finished this section. Please move onto the next one!</span>}
      </div>
    );
  }
}

export default SilentReading;
