const initState = {
    dataError: null
};
const userModule = (state = initState, action) => {
    switch(action.type){
        case 'MODULE_FETCH_ERROR1':
            return {
                ...state,
                dataError: 'No Modules fetched'
            };
        case 'MODULE_FETCH_SUCCESS1':
            return action.data;
        default:
            return state;
    }
}

export { userModule };