import {auth, db} from '../../../../../FirestoreRedux.js';

/**
 * Create a new user using userData provided.
 * User is created with the firebase auth service and a 
 * one time password is created for the user.
 * User data is stored in the users collection in firebase. 
 * 
 * @param {firstName, lastName, email, accountType} userData 
 */
export function createUser(userData){
    let userDocumentData = userData;
    // if (userData.accountType === "student"){
    //     userDocumentData = createStudentDocument(userData)
    // }
    console.log("OVER HERE: ", userData)
    if (userData.accountType === "issd-dev"){
        userDocumentData = createDeveloperDocument(userData)
    }
    auth.createUserWithEmailAndPassword(userDocumentData.email, userDocumentData.OTP)
    .then(function(data){
        db.collection('users').doc(data.user.uid).set(userDocumentData);
        db.collection('users').doc(data.user.uid).collection('modules').doc('snippets').set({});
    })
    .catch(function(error) {
        // Handle Errors here.
        var errorMessage = error.message;
        console.log(errorMessage)
        // ...
    });
}

/**
 * Create document to be added in the user collections
 * for a student user.
 */
function createStudentDocument(userData){
    let documentData = userData;
    documentData.actionItems = {}; // Empty field for action items
    documentData.OTP = generateOTP(); // One time password while user is created
    documentData.creationTime = new Date(); // Creation date
    return documentData;
}

/**
 * Create document to be added in the user collections
 * for a developer user.
 */
function createDeveloperDocument(userData){
    let documentData = userData;
    documentData.actionItems = {}; // Empty field for action items
    documentData.OTP = generateOTP(); // One time password while user is created
    documentData.creationTime = new Date(); // Creation date
    return documentData;
}

/**
 * Create a numeric one time password for the user. 
 */
function generateOTP(){
    var digits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@';
    var otpLength = 20;
    var otp = '';

    for(let i=1; i<=otpLength; i++){
        var index = Math.floor(Math.random()*(digits.length));
        otp = otp + digits[index];
    }
    return otp;
}