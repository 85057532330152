import { combineReducers } from 'redux';

const initSessionState = {
    dataError: null,
    session: {}
};

const activeSession = (state = initSessionState, action) => {
    switch(action.type){
        case 'SESSION_FETCH_ERROR':
            return {
                ...state,
                dataError: 'Error'
            };
        case 'NO_ACTIVE_SESSION':
            return {
                ...state,
                dataError: 'No Active Sessions'
            };
        case 'SESSION_FETCH_SUCCESS':
            return action.session;
        default:
            return state;
    }
}

const initCourseState = {
    dataError: null,
    courses: []
}
const activeSessionCourses = (state = initCourseState, action) => {
    switch(action.type){
        case 'COURSE_FETCH_ERROR':
            return {
                ...state,
                dataError: 'Error'
            };
        case 'NO_ACTIVE_COURSES':
            return {
                ...state,
                dataError: 'No Active Courses'
            };
        case 'COURSE_FETCH_SUCCESS':
            return {
                courses: action.courses,
            }
        default:
            return state;
    }
}

const adminReducer = combineReducers({
    session : activeSession,
    courses : activeSessionCourses
});

export default adminReducer;