import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import Welcome from "../../components/Onboarding/Welcome.js";

class WelcomePage extends Component {
  render() {
    if (this.props.profile.onboarding === false) {
      return <Redirect to="/overview"></Redirect>;
    }
    return <Welcome />;
  }
}

const mapStateTOProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateTOProps)(WelcomePage);
