import t1 from "./t1";

const writingModuleData = {
  title: "Written Communication Module",
  type: "written",
  description: {
    // 0: {"header": "Why is academic integrity important?", "body": "Academic integrity is central to academic work at the University of Toronto and other academic institutions across the world.  It is often described as a scholarly practice based on justice and fairness. For students who do not uphold this concept of fairness, there can be serious academic consequences. For example, while it is important to refer to the research of others in your own writing, not properly acknowledging the work of others can constitute plagiarism which is a serious offence in academic work."},
    // 1: { "header": "What will I learn in this Academic Integrity learning module?", "body": "This module will introduce you to some of the common scenarios in academic work where academic integrity needs to be maintained. There are five topics in the module that focus on academic style, citations, evaluating sources, and groupwork. The modules contain informative videos, short practice activities, and opportunities for you to reflect on what you have learned. We hope you find these academic integrity topics informative and useful. Finally, we strongly recommend that you use an up-to-date academic style guide to keep yourself current with the academic style used in your discipline of study." },
    // 2: {"header": "How can my learning in this module be recognized?", "body": "When you complete the five topics, you will be re-directed back to this page where you can download a completion certificate."}
  },
  instructions: {
    header: "",
    body: "Welcome to the APLUS Written Communication Module! <br></br> Higher education is full of many exciting opportunities, but along with these is a demand for high-level communicative competence. The A+ Platform communication modules are designed to help you learn about academic language demands, assess both your strengths and areas for improvement in communication, and guide future learning. The written communication module focuses specifically on writing competency as you are guided through a series of sequential tasks with feedback provided along the way. <br><br><h4>Instructions</h4>Complete the Written Communication module by clicking on the “Topic” link below. When you have finished completing the Topic, you will be directed back to this page where you will have the opportunity to complete a brief post-survey and then download a certificate of completion using the button below.",
  },

  subModules: [t1],
};

export default writingModuleData;
