 const t1=    {
    "subModuleTitle": "Introduction to Academic Integrity",
    "userState": { "locked": false, "progress": 0 },
    "content": {
      "0": {
        "0": {
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "text": "Welcome to the Academic Integrity Module. As a student at University of Toronto, you are expected to conform to guidelines about academic integrity and academic writing, both of which are important to your success. This module will help you become familiar with the most common concepts and situations through a series of topics included in this Academic Integrity module. Additional resources for each topic will be available at the end of the module, for further learning.​"
        },
        "1": {
          "type": "text",
          "text": "Watch this video to help you become more familiar with what academic integrity is about.​",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "2": {
          "subSectionTitle": "",
          "url": "videos/academicIntegrity/t1.mp4",
          "srcType": "url",
          "type": "video",
          "userState": { "viewed": false }
        },
        "3": {
          "userState": { "viewed": false },
          "type": "text",
          "text": "<i>The materials and knowledge in this module are not intended to substitute the University of Toronto's official CODE OF BEHAVIOUR ON ACADEMIC MATTERS. Please consult with your instructors and academic advisor in case of doubt.</i>​",
          "subSectionTitle": "Disclaimer:"
        },
        "userState": { "locked": false, "completed": false },
        "sectionTitle": "1.1. Introduction",
        "sectionIcon": "info",
        "sectionIconTitle": "Introduction"
      },
      "1": {
        "0": {
          "text": "Another concept that students will encounter is <i><b>academic writing</b></i>. This style of writing is concise, structured, formal, and most importantly, backed up by evidence, if applicable. Academic writing includes using paragraph form (not point-form) and using a consistent font style/size, spacing and margins.  For example, an email, a text message, or a list of jot (bulleted) notes would not be considered formal academic writing.",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "text"
        },
        "1": {
          "text": "The style, rules, and conventions may vary from field to field or by instructor, but a key requirement of academic writing is that you always acknowledge the sources that you use, as a result of your research on a particular topic area. That is, the ideas used in your writing must be credited to the author who wrote them. These formal acknowledgements are called <i><b>citations</b></i>.  Depending on your area of study, there are various citation styles that are used.",
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "sectionIcon": "info",
        "sectionIconTitle": "Academic Writing",
        "sectionTitle": "1.2. What is Academic Writing",
        "userState": { "completed": false, "locked": true }
      },
      "2": {
        "0": {
          "text": "Citations include identifying information about the source including the author(s), year of publication, title of the book or journal, title of the articles, page numbers and so on. It is important that you know what academic style is used in your discipline and that you are use the most up-to-date style guide.​",
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "1": {
          "type": "text",
          "userState": { "viewed": false },
          "text": "<p>Here are three major citation styles commonly used in academia: </p><ol>                <li><strong>APA</strong> (American Psychological Association) is used by the social sciences such as Psychology, Education and Social Work. The APA 7th edition (2019) is the most recent.</li><br/>                <li><strong>MLA</strong> (Modern Language Association) is used by Language Arts, Cultural Studies and other Humanities studies. The MLA 8th edition (2016) is the most recent.</li><br/><li><strong>Chicago</strong>, is generally used by Business, History and the Fine Arts. The Chicago 17th edition (2017) is the most recent.</li>                </ol>​​",
          "subSectionTitle": ""
        },
        "2": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "text": "At the end of a formal academic paper, a list of sources is shared called <b><i>References</i></b>. Let's compare the three styles...",
          "type": "text"
        },
        "sectionTitle": "1.3. Citation Styles",
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Citation Styles",
        "sectionIcon": "info"
      },
      "3": {
        "0": {
          "type": "text",
          "userState": { "viewed": false },
          "text": "Below are three <i>reference  entries</i> for the same source in each citation style:​",
          "subSectionTitle": ""
        },
        "1": {
          "subSectionTitle": "APA",
          "url": "images/academicIntegrity/t1i1.png",
          "userState": { "viewed": false },
          "type": "image",
          "srcType": "url"
        },
        "2": {
          "url": "images/academicIntegrity/t1i2.png",
          "srcType": "url",
          "type": "image",
          "userState": { "viewed": false },
          "subSectionTitle": "MLA"
        },
        "3": {
          "type": "image",
          "url": "images/academicIntegrity/t1i3.png",
          "userState": { "viewed": false },
          "srcType": "url",
          "subSectionTitle": "Chicago"
        },
        "4": {
          "text": "Notice how the information is the same, yet the formatting and the order of the items differ among the styles.​",
          "type": "text",
          "userState": { "viewed": false },
          "subSectionTitle": ""
        },
        "sectionIcon": "info",
        "sectionIconTitle": "Citation Styles",
        "userState": { "locked": true, "completed": false },
        "sectionTitle": "1.4. Formatting and Styles"
      },
      "4": {
        "0": {
          "text": "Now, let’s reflect on the situation by answering a couple of questions. ",
          "subSectionTitle": "",
          "type": "text",
          "userState": { "viewed": false }
        },
        "1": {
          "type": "quiz",
          "questions": [
            {
              "qId": "0",
              "userState": { "response": [""] },
              "qType": "open-res",
              "feedback": "Each discipline has its own style guide for academic writing. Be sure to check with your instructor about the style and version you should use. <a href='https://owl.purdue.edu/owl/research_and_citation/using_research/resources_for_documenting_sources_in_the_disciplines/complete_discipline_listing.html' target='_blank' rel='noreferrer noopener'>Click here</a> to see what styles different disciplines use.",
              "qText": "What is the citation style  used in your discipline?"
            }
          ],
          "subSectionTitle": ""
        },
        "2": {
          "questions": [
            {
              "userState": { "response": [""] },
              "sectionComplete": true,
              "feedback": "APA is the 7th edition (2019); MLA is the 8th edition  (2016) and Chicago is the 17th edition (2017)​​",
              "qText": "Which is the most current version of that citation style?​",
              "qId": "0",
              "topicComplete": true,
              "qType": "open-res"
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "sectionIconTitle": "Reflection and Review",
        "sectionTitle": "1.5. Reflection and Review",
        "sectionIcon": "comment",
        "userState": { "completed": false, "locked": true }
      }
    }
  }

export default t1;

