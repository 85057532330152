import React from "react";
import {
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
} from "@material-ui/core";

class Quiz extends React.Component {
  state = {
    response: ["3", "3"],
    disabled: false,
    error: false,
  };

  handleQuizResponse = (e, qNumber) => {
    if (qNumber === 1)
      this.setState({
        response: [e.target.value, this.state.response[1]],
      });
    else
      this.setState({
        response: [this.state.response[0], e.target.value],
      });
  };

  onSubmit = () => {
    try {
      this.props.updateFunction(
        this.state.response,
        this.props.sectionIndex,
        this.props.subSectionKey
      );
    } catch (err) {
      this.setState({error: true})
    }
    this.setState({ disabled: true });
  };

  render() {
    return (
      <>
        <div>
          <Card style={{ padding: "1.5rem" }}>
            1. On a scale of 1 to 5, where 5 is the most difficult, how would
            you rate the difficulty of this task?
            <RadioGroup
              row
              value={this.state.response[0]}
              onChange={(e) => this.handleQuizResponse(e, 1)}
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={this.state.disabled} />}
                label="1"
              />
              <FormControlLabel
                value="2"
                control={<Radio disabled={this.state.disabled} />}
                label="2"
              />
              <FormControlLabel
                value="3"
                control={<Radio disabled={this.state.disabled} />}
                label="3"
              />
              <FormControlLabel
                value="4"
                control={<Radio disabled={this.state.disabled} />}
                label="4"
              />
              <FormControlLabel
                value="5"
                control={<Radio disabled={this.state.disabled} />}
                label="5"
              />
            </RadioGroup>
          </Card>
          <Card style={{ padding: "1.5rem" }}>
            2. Based on a scale of 1 to 5, where 5 represents your ability to
            manage your time effectively, how accurate was your time estimation
            to complete the task?
            <RadioGroup
              row
              value={this.state.response[1]}
              onChange={(e) => this.handleQuizResponse(e, 2)}
            >
              <FormControlLabel
                value="1"
                control={<Radio disabled={this.state.disabled} />}
                label="1"
              />
              <FormControlLabel
                value="2"
                control={<Radio disabled={this.state.disabled} />}
                label="2"
              />
              <FormControlLabel
                value="3"
                control={<Radio disabled={this.state.disabled} />}
                label="3"
              />
              <FormControlLabel
                value="4"
                control={<Radio disabled={this.state.disabled} />}
                label="4"
              />
              <FormControlLabel
                value="5"
                control={<Radio disabled={this.state.disabled} />}
                label="5"
              />
            </RadioGroup>
          </Card>

          <Button
            style={{
              background: "#7270a1",
              borderColor: "rgba(0, 32, 78)",
              marginRight: "10px",
              color: "white",
              marginTop: "1.5em",
            }}
            onClick={this.onSubmit}
            disabled={this.state.disabled}
          >
            Submit
          </Button>
          {(this.state.disabled && !this.state.error) && (
            <Card style={{padding: 13, backgroundColor: 'lightgreen', marginTop: 16}}>You have successfully submitted the quiz!</Card>
          )}
          {(this.state.error) && (
            <Card style={{padding: 13, backgroundColor: 'lightred', marginTop: 16}}>There was a problem. Please try again later!</Card>
          )}
        </div>
      </>
    );
  }
}

export default Quiz;
