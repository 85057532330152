import t1 from './t1'

const moduleData = {
    title: "Oral Communication",
    type: "oral",
    description: {},
    instructions: {"header": "", "body": "Welcome to the APLUS Oral Communication Module!<br><br> The A+ Platform has been designed to support you in the development of your oral communication for academic settings. You will learn about your strengths and areas in need of improvement while moving through a series of sequential tasks with feedback provided along the way. These tasks have been designed to support and prepare you in developing high-level communicative competence required for higher education.<br><br><h4>Instructions</h4>Complete the Oral Communication module by clicking on the \"Topic\" link below. When you have finished completing the Topic, you will be directed back to this page where you will have the opportunity to first complete a brief post-survey and then download a certificate of completion using the button below."},
    subModules: [
        t1
    ],
}

export default moduleData;