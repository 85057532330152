import t1 from './t1'
import t2 from './t2'
import t3 from './t3'
import t4 from './t4'
import t5 from './t5'
import t6 from './t6'

const moduleData = {
    title: "Academic Integrity",
    type: "knowledge",
    instructions: {
        "body": "There are 6 topics in the Academic Integrity module that can be completed in any order. You can access these by clicking on the \"Topics\" links below. When you have finished all topics in the module, you will be directed back to this page where you will have the opportunity to first complete a brief post-survey and then download a certificate of completion using the button below.",
        "header": "Instructions"
      },    description: {
        0: {
          "body": "Academic integrity is central to academic work at the University of Toronto and other academic institutions across the world.  It is often described as a scholarly practice based on justice and fairness. For students who do not uphold this concept of fairness, there can be serious academic consequences. For example, while it is important to refer to the research of others in your own writing, not properly acknowledging the work of others can constitute plagiarism which is a serious offence in academic work.",
          "header": "Why is academic integrity important?"
        },
        1: {
          "body": "This module will introduce you to some of the common scenarios in academic work where academic integrity needs to be maintained. There are six topics in the module that focus on academic style, citations, evaluating sources, and groupwork. The modules contain informative videos, short practice activities, and opportunities for you to reflect on what you have learned. We hope you find these academic integrity topics informative and useful. Finally, we strongly recommend that you use an up-to-date academic style guide to keep yourself current with the academic style used in your discipline of study.",
          "header": "What will I learn in this Academic Integrity learning module?"
        },
        2: {
          "body": "When you complete the six topics, you will be re-directed back to this page where you can download a completion certificate.",
          "header": "How can my learning in this module be recognized?"
        }
      },
    subModules: [
        t1,
        t2,
        t3,
        t4,
        t5,
        t6
    ]
}

export default moduleData;
