import authReducer from './authReducer';
import { userModule } from './userReducer';
import adminReducer from './adminReducer';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import  moduleReducer  from './util/moduleReducer.js';

const rootReducer = combineReducers({
    auth: authReducer,
    userModule: userModule,
    firebase: firebaseReducer,
    admin: adminReducer,
    moduleData: moduleReducer
});

export default rootReducer;