const t2=    {
    "subModuleTitle": "How to Evaluate Sources",
    "content": {
      "0": {
        "0": {
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": "",
          "text": "When you do a search on your topic, you will often get a wide range of sources on your topic. You can’t read all these sources but how do you know on which sources to focus? ​"
        },
        "1": {
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": "",
          "text": "First, you need to evaluate whether the source is <i>credible</i> (or trustworthy) and then decide how you want to use it. Not all sources are credible or reliable to the same extent and you do not want to include non-credible sources in your research.​"
        },
        "2": {
          "type": "text",
          "text": "",
          "userState": { "viewed": false },
          "subSectionTitle": ""
        },
        "sectionIconTitle": "Introduction",
        "userState": { "locked": false, "completed": false },
        "sectionTitle": "2.1. Introduction",
        "sectionIcon": "info"
      },
      "1": {
        "0": {
          "text": "Watch the video and see how the students evaluate the credibility of a source.​",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "text"
        },
        "1": {
          "url": "videos/academicIntegrity/t2.mp4",
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "srcType": "url",
          "type": "video"
        },
        "sectionIcon": "video",
        "userState": { "completed": false, "locked": true },
        "sectionTitle": "2.2. What Is a Credible Source?",
        "sectionIconTitle": "Credible Source"
      },
      "2": {
        "0": {
          "userState": { "viewed": false },
          "text": "When considering if a website is credible, try to determine if the information is out of date, inaccurate, or biased in some way.",
          "type": "text",
          "subSectionTitle": ""
        },
        "1": {
          "type": "text",
          "text": "Websites that end in <b>.gov</b> are generally reliable but be careful of political sites that have political opinions. Websites that end in <b>.edu</b> or <b>university websites</b> are usually reliable. Company sites ending in <b>.com</b> or <b>.ca</b> may have biases for certain ideas or products. Many credible organizations end in <b>.org</b> but be aware of special interest groups with certain biases. ",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "2": {
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "text",
          "text": "<b>Blogs</b> are also a source of information, but often contain opinions (and not proven facts) and/or may be written by authors who do not have recognized credentials in the topic area they are writing about."
        },
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Website Credibility",
        "sectionIcon": "info",
        "sectionTitle": "2.3. Website Credibility"
      },
      "3": {
        "0": {
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "text": "Now, let’s reflect on types of sources by completing the following quizzes. You can watch the video again if you would like.",
          "type": "text"
        },
        "1": {
          "url": "videos/academicIntegrity/t2.mp4",
          "userState": { "viewed": false },
          "srcType": "url",
          "subSectionTitle": "",
          "type": "video"
        },
        "2": {
          "questions": [
            {
              "qId": "0",
              "qType": "mcq",
              "options": [
                {
                  "text": "True",
                  "oId": "0",
                  "answer": true,
                  "exp": "TRUE- Journal articles are typically peer-reviewed sources themselves or include citations that are peer-reviewed. ​"
                },
                {
                  "text": "False",
                  "exp": "TRUE- Journal articles are typically peer-reviewed sources themselves or include citations that are peer-reviewed. ​",
                  "answer": false,
                  "oId": "1"
                }
              ],
              "userState": { "response": [] },
              "qText": "1. Journal articles"
            }
          ],
          "subSectionTitle": "Quiz 1: TRUE or FALSE:  Which sources are most appropriate for locating credible and reliable sources in your academic writing?​",
          "type": "quiz"
        },
        "3": {
          "subSectionTitle": "",
          "type": "quiz",
          "questions": [
            {
              "qId": "0",
              "qText": "2. Edited book chapter",
              "options": [
                {
                  "oId": "0",
                  "text": "True",
                  "exp": "TRUE- Edited book chapters would include peer-reviewed, credible sources.​",
                  "answer": true
                },
                {
                  "exp": "TRUE- Edited book chapters would include peer-reviewed, credible sources.​",
                  "text": "False",
                  "answer": false,
                  "oId": "1"
                }
              ],
              "userState": { "response": [] },
              "qType": "mcq"
            }
          ]
        },
        "4": {
          "type": "quiz",
          "subSectionTitle": "",
          "questions": [
            {
              "qId": "0",
              "qType": "mcq",
              "userState": { "response": [] },
              "qText": "3. Websites",
              "options": [
                {
                  "exp": "FALSE- Most websites do not cite peer-reviewed sources and the accuracy of information may not be evaluated.​",
                  "text": "True",
                  "answer": false,
                  "oId": "0"
                },
                {
                  "exp": "FALSE- Most websites do not cite peer-reviewed sources and the accuracy of information may not be evaluated.​",
                  "answer": true,
                  "text": "False",
                  "oId": "1"
                }
              ]
            }
          ]
        },
        "5": {
          "type": "quiz",
          "subSectionTitle": "",
          "questions": [
            {
              "qId": "0",
              "qType": "mcq",
              "userState": { "response": [] },
              "qText": "4. Blog entries",
              "options": [
                {
                  "exp": "FALSE- Blog entries are typically written by one author and can only represent their thoughts and knowledge. Accuracy and quality of information are often not evaluated.​",
                  "answer": false,
                  "oId": "0",
                  "text": "True"
                },
                {
                  "text": "False",
                  "exp": "FALSE- Blog entries are typically written by one author and can only represent their thoughts and knowledge. Accuracy and quality of information are often not evaluated.​",
                  "oId": "1",
                  "answer": true
                }
              ]
            }
          ]
        },
        "6": {
          "questions": [
            {
              "qId": "0",
              "qText": "5. Textbooks",
              "options": [
                {
                  "text": "True",
                  "oId": "0",
                  "answer": true,
                  "exp": "TRUE- Textbooks are great sources to locate peer-reviewed, credible work.​​"
                },
                {
                  "oId": "1",
                  "exp": "TRUE- Textbooks are great sources to locate peer-reviewed, credible work.​​",
                  "text": "False",
                  "answer": false
                }
              ],
              "qType": "mcq",
              "userState": { "response": [] }
            }
          ],
          "subSectionTitle": "",
          "type": "quiz"
        },
        "7": {
          "questions": [
            {
              "userState": { "response": [] },
              "options": [
                {
                  "text": "True",
                  "oId": "0",
                  "exp": "TRUE-The university library hosts a wide range of resources (in-print and electronic) that are available to all students. It also links major interdisciplinary databases as well as specific disciplinary databases. ​​",
                  "answer": true
                },
                {
                  "text": "False",
                  "exp": "TRUE-The university library hosts a wide range of resources (in-print and electronic) that are available to all students. It also links major interdisciplinary databases as well as specific disciplinary databases. ​​",
                  "oId": "1",
                  "answer": false
                }
              ],
              "qText": "1.  University of Toronto Library (Physical and/or online)",
              "qType": "mcq",
              "qId": "0"
            }
          ],
          "subSectionTitle": "Quiz 2: TRUE or FALSE:  Where can you usually find information or literature to cite in your research paper?​",
          "type": "quiz"
        },
        "8": {
          "subSectionTitle": "",
          "type": "quiz",
          "questions": [
            {
              "options": [
                {
                  "oId": "0",
                  "exp": " TRUE- Interdisciplinary databases can access resources across multiple disciplines and fields. University of Toronto students can link their university account to access these databases.​​",
                  "answer": true,
                  "text": "True"
                },
                {
                  "answer": false,
                  "oId": "1",
                  "exp": " TRUE- Interdisciplinary databases can access resources across multiple disciplines and fields. University of Toronto students can link their university account to access these databases. ​",
                  "text": "False"
                }
              ],
              "userState": { "response": [] },
              "qText": "2.  Interdisciplinary Database (Google Scholar, OVID, EBSCO, Proquest)",
              "qId": "0",
              "qType": "mcq"
            }
          ]
        },
        "9": {
          "questions": [
            {
              "qId": "0",
              "qType": "mcq",
              "qText": "3. Discipline-specific Journal Database (ERIC for education, PSYCInfo for Psychology) ",
              "options": [
                {
                  "exp": "TRUE- To locate disciplinary-specific information, focusing your literature search in discipline-specific databases can be strategically appropriate. ​",
                  "text": "True",
                  "oId": "0",
                  "answer": true
                },
                {
                  "answer": false,
                  "text": "False",
                  "exp": "TRUE- To locate disciplinary-specific information, focusing your literature search in discipline-specific databases can be strategically appropriate. ​",
                  "oId": "1"
                }
              ],
              "userState": { "response": [] }
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "10": {
          "type": "quiz",
          "subSectionTitle": "",
          "questions": [
            {
              "qId": "0",
              "options": [
                {
                  "answer": false,
                  "exp": "FALSE-  Be careful. Wikipedia sources and general search engines may be useful for understanding general ideas of a subject or finding primary sources. These sources of information may cite both peer-reviewed and non-peer reviewed research and information.​",
                  "text": "True",
                  "oId": "0"
                },
                {
                  "exp": "FALSE-  Be careful. Wikipedia sources and general search engines may be useful for understanding general ideas of a subject or finding primary sources. These sources of information may cite both peer-reviewed and non-peer reviewed research and information.​",
                  "text": "False",
                  "oId": "1",
                  "answer": true
                }
              ],
              "qText": "4. Wikipedia and general search engines (e.g. Google)",
              "userState": { "response": [] },
              "qType": "mcq"
            }
          ]
        },
        "11": {
          "questions": [
            {
              "options": [
                {
                  "oId": "0",
                  "answer": true,
                  "text": "True",
                  "exp": "TRUE- If the website references academic articles in which you are interested, it is best practice to read the original article, and make your own summary/paraphrase/quotation.​"
                },
                {
                  "text": "False",
                  "exp": "TRUE- If the website references academic articles in which you are interested, it is best practice to read the original article, and make your own summary/paraphrase/quotation.​",
                  "oId": "1",
                  "answer": false
                }
              ],
              "qType": "mcq",
              "qText": "​1. Read and then cite any academic articles cited by the website.",
              "qId": "0",
              "userState": { "response": [] }
            }
          ],
          "subSectionTitle": "Quiz 3: TRUE or FALSE: If you found a website on the internet that provided valuable information on your topic, what are some ways you can include that information in your paper? ​",
          "type": "quiz"
        },
        "12": {
          "questions": [
            {
              "userState": { "response": [] },
              "options": [
                {
                  "text": "True",
                  "oId": "0",
                  "exp": "TRUE- Citing the website you used is a good solution to avoid inaccurate citation or plagiarism. That said, be cognizant that the information in websites often lack the rigor of information provided in peer-reviewed articles. Academic articles are almost always preferred.​",
                  "answer": true
                },
                {
                  "answer": false,
                  "exp": "TRUE- Citing the website you used is a good solution to avoid inaccurate citation or plagiarism. That said, be cognizant that the information in websites often lack the rigor of information provided in peer-reviewed articles. Academic articles are almost always preferred.​",
                  "text": "False",
                  "oId": "1"
                }
              ],
              "qType": "mcq",
              "qText": "2. Cite the website when describing the information it provides.",
              "qId": "0"
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "13": {
          "subSectionTitle": "",
          "questions": [
            {
              "userState": { "response": [] },
              "options": [
                {
                  "answer": true,
                  "text": "True",
                  "exp": "TRUE- As long as the information you provided is directly supported by the article, then this solution is acceptable. If the article is only tangentially related to the claim, this would still count as an inaccurate citation.​​",
                  "oId": "0"
                },
                {
                  "answer": false,
                  "exp": "TRUE- As long as the information you provided is directly supported by the article, then this solution is acceptable. If the article is only tangentially related to the claim, this would still count as an inaccurate citation.​​",
                  "text": "False",
                  "oId": "1"
                }
              ],
              "topicComplete": true,
              "sectionComplete": true,
              "qType": "mcq",
              "qText": "3. Search for academic articles that provide similar information to the website; if an academic article provides similar information, cite the academic article instead of the website.",
              "qId": "0"
            }
          ],
          "type": "quiz"
        },
        "sectionIconTitle": "Credible Source",
        "sectionTitle": "2.4. What Is a Credible Source?",
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "quiz"
      }
    },
    "userState": { "locked": false, "progress": 0 }
  }

export default t2;