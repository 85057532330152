import React from "react";
import useStyles from "./QuizWrittenStyles";
import {
  Box,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Card,
} from "@material-ui/core";
import UnCheckedCircle from "@material-ui/icons/RadioButtonUncheckedRounded";
import CheckedCircle from "@material-ui/icons/CheckCircleRounded";
import {Check, Info} from "@material-ui/icons";
import ReactHtmlParser from 'react-html-parser';

/*
 *
 * @params
 * questionData = 
 */

export default function QuizWritten(props) {
  const { previousResponse, questionData, updateFunction,sectionIndex, subSectionKey } = props;

  const classes = useStyles();
  const [checked, setChecked] = React.useState(previousResponse.length === 0 ? null : previousResponse[previousResponse.length - 1]);
  const [hintIndex, setHintIndex] = React.useState(null);
  const [disabled, setDisabled] = React.useState(new Array(4).fill(previousResponse.length !== 0));
  const [color, setColor] = React.useState(new Array(4).fill(null));
  const [showSubmit, setShowSubmit] = React.useState(previousResponse.length === 0);
  const [showCorrectText, setShowCorrectText] = React.useState(false);
  const [response, setResponse] = React.useState([]);

  const handleChange = (e) => {
    setChecked(parseInt(e.target.name));

  };

  const handleSubmit = (e) => {
    // Guard clause
    if (checked === null || disabled[checked] === true) return;

    // Update firebase and redux store

    // If the correct answer was checked
    if (checked === questionData.answerIndex) {
      setResponse((response) => {
        response.push(checked);
        updateFunction(response, sectionIndex, subSectionKey);
        return response;
      })
      setDisabled(new Array(questionData.options.length).fill(true));
      setColor((color) => {
        color[checked] = 'green'
        return color;
      });
      setShowSubmit(false);
      setHintIndex(null);
      setShowCorrectText(true);
      return;
    }

    // If wrong answer was checked
    setDisabled((disabled) => {
      disabled[checked] = true;
      return disabled;
    });
    setResponse((response) => {
      response.push(checked);
      return response;
    });
    setColor((color) => {
      color[checked] = 'red';
      return color;
    });
    if (hintIndex === null) setHintIndex(0);       //Initialize hint if hint is null
    else {                                         // Move onto next hint if possible. If not, just set null
      if (hintIndex + 1 < questionData.options.length - 1) setHintIndex(hintIndex + 1)
      else setHintIndex(null)
    }
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Box className={classes.quizBox}>
          {/* <h6>{questionData.qText}</h6> */}
        {questionData.qText.split('\n').length == 1 ? <h6 style={{fontWeight: '450'}}>{ReactHtmlParser(questionData.qText)}</h6> : 
          questionData.qText.split('\n').map((elem) => {
            return <h6>{elem}</h6>
          })}
          
          <FormGroup>
            {/*Options */}
            {questionData.options.map((opt, idx) => (
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<UnCheckedCircle />}
                    checkedIcon={<CheckedCircle />}
                    checked={checked === idx}
                    onChange={handleChange}
                    name={idx}
                    disabled={disabled[idx]}
                    color="primary"
                  />
                }
                className={color[idx] === 'red' ? classes.incorrect : color[idx] === 'green' ? classes.correct : {}}
                key={idx}
                label={<React.Fragment>{ReactHtmlParser(opt)}</React.Fragment>}
              />
            ))}
          </FormGroup>
        </Box>
        {hintIndex !== null && <Card className = {classes.hint}><Info className={classes.infoIcon}/><span><b>{'Hint:  '}</b>{questionData.hints[hintIndex]}</span></Card>}
      {showSubmit && <Button className={classes.submit} onClick={handleSubmit}>Submit</Button>}
      {showCorrectText && <Card className = {classes.hint}><Check className={classes.checkIcon}/><span><b>{'Correct!  '}</b>{questionData.correctText}</span></Card>}
      </Box>
    </Box>
  );
}

const updateModuleData = () => {

}