import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchActiveSession, fetchSessionCourses } from '../../../../store/actions/adminActions';
import {createUser} from './UserRegistration/service.js';
import { Container, Row, Col } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { TextField, FormControl, InputLabel,
    Select, MenuItem, Button  } from '@material-ui/core';

const userRegistrationInformation = {
    "email": "Email",
    "firstName": "First Name",
    "lastName": "Last Name",
    "accountType": ["Select Type","student", "issd-dev"]
}
class AdminDash extends Component {

    constructor(props){
        super(props);
        this.state = {
            userFormData: {
                firstName: "",
                lastName: "",
                email: "",
                accountType: "",
            }
        }
        this.createUser = this.createUser.bind(this);
        this.props.fetchActiveSession()
    }

    componentDidUpdate(prevProps){
        
        if (this.props.admin.session.sessionId !== prevProps.admin.session.sessionId) {
            this.props.fetchSessionCourses(this.props.admin.session.sessionId)
        }
    }

    createUser(e){
        var userFormData = {...this.state.userFormData}
        if (e.target.id === "first-name"){
            userFormData.firstName = e.target.value
            this.setState({userFormData})
        }
        if (e.target.id === "last-name"){
            userFormData.lastName = e.target.value
            this.setState({userFormData})
        }
        if (e.target.id === "email"){
            userFormData.email = e.target.value
            this.setState({userFormData})
        }
        if (e.target.name === "account-type"){
            userFormData.accountType = e.target.value
            this.setState({userFormData})
        }
    }

    validEmail(email) {
        return (/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) ? true : false
    }

    validateAccountType(userType){
        return (userType === "" || userType === "Select Type") ? false : true;
    }

    validateForm(formData){
        if (this.validEmail(formData.email) && this.validateAccountType(formData.accountType)){
            if (formData.firstName !== "" && formData.lastName !== ""){
                return true
            }
        }
        return false
    }

    registerUser(){
        // Check if there is a valid email
        
        if (this.validateForm(this.state.userFormData)){
            
            createUser(this.state.userFormData)
        }
        
    }

    createUserForm(){
        return (
            <Container>
                <h5>Create User</h5>
                <Row>
                    <Col>
                        <Row>First Name</Row>
                        <Row><TextField id="first-name" label="First Name" variant="outlined" onChange={this.createUser}/></Row>
                    </Col>
                    <Col>
                        <Row>Last Name</Row>
                        <Row><TextField id="last-name" label="Last Name" variant="outlined"  onChange={this.createUser}/></Row>
                    </Col>
                </Row>
                <br></br>
                <Row>
                    <Col>
                        <Row>Email</Row>
                        <Row><TextField id="email" label="Email" variant="outlined" onChange={this.createUser}/></Row>
                    </Col>
                    <Col>
                        <Row>Account Type</Row>
                        <Row>
                            <FormControl name="account-type">
                                <InputLabel name="account-type">Account Type</InputLabel>
                                <Select  name="account-type" style={{"minWidth": "120px"}} onChange={this.createUser}>
                                {userRegistrationInformation.accountType.map((value) => 
                                    <MenuItem  value={value}>{value}</MenuItem>
                                )} 
                                </Select>
                            </FormControl>
                        </Row>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Button variant="contained" color="primary" onClick={() => this.registerUser()}>Register</Button>
                </Row>

            </Container>
        );
    }
    render(){
        const session = this.props.admin.session; 
        const courses = this.props.admin.courses;
        
        return (
            <Container fluid>
                <Row> 
                <Col>
                    <h5>Current Session: </h5> 
                    <Table striped bordered>
                    <tbody>
                    <tr><th>Session Year</th><th>{session.startYear} - {session.endYear}</th></tr>
                    <tr><th>Courses</th><th>{session.activeCourses}</th></tr>
                    <tr><th>Students</th><th>{session.activeStudents}</th></tr>
                    <tr><th>Instructors</th><th>{session.activeInstructors}</th></tr>
                    </tbody>
                    </Table>
                </Col>
                
                <hr/>
                <Col> <h5>Active Course</h5>
                    <Table bordered>
                    <thead>
                        <tr><th>Course Code</th><th>Course Name</th></tr>
                    </thead>
                    <tbody>
                        {courses.courses.map(( course, index) =>(
                            <tr><th>{course.code}</th><th>{course.title}</th></tr>
                        ))}
                    </tbody>
                    </Table>
                </Col>
                </Row>
                <Row>
                    <Col>{this.createUserForm()}</Col>
                    <Col></Col>
                </Row>
            </Container>
        );
    }
}

const mapStateTOProps = (state) => {
    return {
      admin: state.admin,
      userId: state.firebase.auth.uid
    };
};
  
const mapDispatchToProps = (dispatch) => {
    return {
        fetchActiveSession: () => dispatch(fetchActiveSession()),
        fetchSessionCourses: (sessionId) => dispatch(fetchSessionCourses(sessionId))
    }
}

export default connect(mapStateTOProps, mapDispatchToProps)(AdminDash);