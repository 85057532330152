import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import SurveyForm from "../../components/Util/SurveyForm/SurveyForm.js";
// Form Data
import {consentForm as ConsentFormData }  from '../../static/forms/ConsentForm.js';

class ConsentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {customAction: null}
        this.setAction = this.setAction.bind(this);
      }

    
    setAction=(action)=>{
        this.setState({customAction : action});
    }

    render() {
        
        if (this.props.profile.onboarding === false) { return <Redirect to='/overview'></Redirect>; }

        return (
            <SurveyForm 
                form={ConsentFormData} 
                userId={this.props.auth.uid} 
                registerAction={this.setAction}
                redirect={'/onboarding/student_survey1'}
                finishOnBoarding={this.state.customAction === "demographicForm"? false: true}>
            </SurveyForm> 
        );
    }
}

const mapStateTOProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(ConsentForm);