import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Button from "react-bootstrap/Button";
import { storage, auth } from "../../../../FirestoreRedux";

import doneIcon from "./baseline-check_circle_outline-24px.svg";

import { BounceLoader } from "react-spinners";

class Upload extends Component {
  constructor(props) {
    super(props);

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.onClear = this.onClear.bind(this);

    let uploadState = null;

    console.log("IDENTIFYING KEY", this.props);
    if (
      this.props.fileIdentifierKey !== "" &&
      this.props.fileIdentifierKey !== undefined &&
      this.props.fileIdentifierKey !== "null"
    ) {
      uploadState = localStorage.getItem(this.props.fileIdentifierKey);
    }

    if (uploadState !== null && uploadState !== "null") {
      this.state = JSON.parse(uploadState);
    } else {
      this.state = {
        files: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        submitted: false,
      };
    }
  }

  onFilesAdded(files) {
    this.setState((prevState) => ({
      files: prevState.files.concat(files),
    }));
  }

  onClear() {
    this.setState({
      files: [],
      successfullUploaded: false,
      submitted: false,
      uploadProgress: {},
    });

    if (this.props.submitOnce) {
      localStorage.setItem(this.props.fileIdentifierKey, "null");
    }
  }

  async uploadFiles() {
    try {
      this.setState({ uploadProgress: {}, uploading: true });
      await this.storeOnFirebase(this.state.files);
      if (this.props.onSubmit) this.props.onSubmit(this.state.files[0]);
      this.setState({ successfullUploaded: true, uploading: false });
    } catch (e) {
      this.setState({ successfullUploaded: false, uploading: false });
      console.log("Firebase Storage Error During File Upload" + e);
    }
  }

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded) {
      return (
        <div>
          {uploadProgress && uploadProgress.state !== "done" ? (
            <div align="center" className="loading">
              <BounceLoader
                className="CheckIcon"
                sizeUnit={"px"}
                size={24}
                color={"rgba(103, 58, 183, 1)"}
                loading={this.props.loading}
              />
            </div>
          ) : (
            <span></span>
          )}
          <img
            className="CheckIcon"
            alt="done"
            src={doneIcon}
            style={{
              opacity:
                uploadProgress && uploadProgress.state === "done" ? 0.5 : 0,
            }}
          />
        </div>
      );
    }
  }

  renderActions() {
    if (!this.state.successfullUploaded) {
      return (
        <Button
          style={{
            background: "#7270a1",
            borderColor: "rgba(0, 32, 78)",
          }}
          disabled={this.state.files.length <= 0 || this.state.uploading}
          onClick={() => this.uploadFiles()}
        >
          Upload
          {/* <img
            alt="Upload"
            className="Icon"
            src="baseline-done-24px.svg"
          /> */}
        </Button>
      );
    }
    //   else {
    //     return (
    //       <Button
    //         onClick={() => this.onClear()}>
    //         Clear
    //       </Button>
    //     );
    // }
  }

  async storeOnFirebase(array1) {
    var storageRef = storage.ref();

    await array1.forEach((ele) => {
      try {
        var fireLoc = storageRef.child(
          (this.props.saveLocation || "powerpoint_files/") +
            auth.currentUser.uid +
            "/" +
            ele.name
        );

        let preUploadProgress = Object.assign({}, this.state.uploadProgress);
        preUploadProgress[ele.name] = {
          state: "pending",
          percentage: 0,
        };
        this.setState({
          uploadProgress: preUploadProgress,
        });

        // Now we upload to the database
        fireLoc.put(ele).then((output) => {
          let newUploadProgress = Object.assign({}, this.state.uploadProgress);
          newUploadProgress[ele.name] = {
            state: "done",
            percentage: 100,
          };
          this.setState({
            uploadProgress: newUploadProgress,
            submitted: true,
          });
          console.log("Successfully added " + ele.name);

          let currentState = Object.assign({}, this.state);
          currentState.files = [];
          for (let i = 0; i < this.state.files.length; i++) {
            currentState.files.push({
              lastModifiedDate: this.state.files[i].lastModifiedDate,
              name: this.state.files[i].name,
              size: this.state.files[i].size,
              type: this.state.files[i].type,
            });
          }
          console.log("Storing current state", currentState);
          if (this.props.submitOnce) {
            localStorage.setItem(
              this.props.fileIdentifierKey,
              JSON.stringify(currentState)
            );
          }
        });
      } catch (e) {
        console.log("Firebase File Upload Error " + e);

        let errorUploadProgress = Object.assign({}, this.state.uploadProgress);

        errorUploadProgress[ele.name] = {
          state: "error",
          percentage: 0,
        };

        this.setState({
          successfullUploaded: false,
          uploading: false,
          uploadProgress: errorUploadProgress,
        });
        if (this.props.submitOnce) {
          localStorage.setItem(this.props.fileIdentifierKey, "null");
        }
      }
    });
  }

  render() {
    console.log("REDNERED PROPS", this.props);
    return (
      <div className="Upload">
        <div className="Content">
          {this.state.successfullUploaded && this.state.submitted ? (
            <span>
              <b>Your file has been successfully uploaded to the platform!</b>
            </span>
          ) : (
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              disabled={
                this.state.uploading ||
                this.state.files.length === 1 ||
                this.state.successfullUploaded
              }
            />
          )}
          <div className="Files">
            {this.state.files.map((file) => {
              return (
                <div key={file.name} className="Row">
                  <span className="Filename">{file.name}</span>
                  {this.renderProgress(file)}
                </div>
              );
            })}
          </div>
        </div>
        <div className="Actions">{this.renderActions()}</div>
      </div>
    );
  }
}

export default Upload;
