import React, { useState } from "react";

import Record from '../../Util/Asr/record/Record.js';

import "./ReadAloud.css";

function ReadAloud(props) {

    let readAloudText = props.text;
    if (props.text && props.text.length > 0 && props.text.startsWith("LOCALSTORAGE//")) {
        let key = props.text.split('//').pop();
        readAloudText = localStorage.getItem(key);
    }
    return (
        <div className="outer2">
            {(readAloudText && readAloudText.length > 0) &&
            <div className="box">
                {/* <div className="inn
                +
                
                erText">There are mainly two types of educational robots: hands-on robots and educational service robots. The foremost purpose of hands-on robots is to promote interest and enhance creativity in STEM (science, technology, engineering and mathematics) education. It is argued that educational service robots, with their friendly appearance and physical movements, can establish interactive relationships with learners, particularly with children, making learning more pleasurable by increasing learners’ interest and lowering their affective filters. </div> */}
                <div className="innerText">{readAloudText ? readAloudText : null}</div>
            </div>
            }
            <div className="box">
                <Record allowMultipleSubmit={props.allowMultipleSubmit} submitRequired={props.submitRequired} resourceButtonLink={props.resourceButtonLink} visual={false} feedback={props.feedback} saveLocation={props.saveLocation}/>
            </div>
        </div>

    );
}


export default ReadAloud;