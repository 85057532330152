import React from 'react'
import {Paper, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import './Certificate.css';

const certData = [{
    name: 'academic integrity',
    topics: 'academic style, citations, referencing, evaluating sources, and group work'
},
{
    name: 'oral communication',
    topics: 'introduction to oral communication, discussion questions, summarizing, reading aloud, drafting a presentation, and self reflection'
},
{
    name: 'written communication',
    topics: 'introduction to written communication, critical reflection, discussion questions, monitoring vocabulary, writing a reflection and self reflection'
}]

class Certificate extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            date: new Date().toLocaleString(),
            modData: props.type === 'academicIntegrityPostSurvey' ? certData[0] :  props.type === 'oralPostSurvey' ? certData[1] : certData[2]
        }
    }

    render() {
        return (
            <Paper className={"root"}>
                <div className={"header"}> 
                    <Typography className={"headerText"} align="center" variant="h2"><strong>Certificate of Completion</strong></Typography> 
                </div>
    
                <Typography className={"bodyText"} align="center" variant="h4">Academic Platform for Language in University Settings (APLUS) at the University of Toronto​</Typography>
    
                <Typography className={"bodyText"} align="center" variant="h3">
                    <strong>
                        {this.props.profile.firstName + " " + this.props.profile.lastName}
                    </strong>
                </Typography>
    
                <Typography className={"bodyText"} align="center" variant="h5">has completed the <strong>{this.props.title.split(" ")[0] + " " + this.props.title.split(" ")[1]}</strong> module of the APLUS. This module contains approximately two hours of self-directed learning related to {this.state.modData.name} knowledge and practice. Topics in this module include: {this.state.modData.topics}. ​​</Typography>
    
                <Typography className={"bodyText"} align="center" variant="h3">{this.state.date.substring(0, 10).replace(/,/g, '')}</Typography>
    
            </Paper>
        )
    }
}

const mapStateTOProps = (state) => {

    return {
        authError: state.auth.authError,
        auth: state.firebase.auth,
        profile: state.firebase.profile
    };
}

export default connect(mapStateTOProps)(Certificate);
