import React, {Component} from 'react';
import Header from '../../components/Util/Header/Header.js';
import Footer from '../../components/Util/Footer/Footer.js';
import ResetPassword from '../../components/Util/Login/ResetPassword.js'
import Container from 'react-bootstrap/Container';


class ResetPasswordScreen extends Component{

    render(){
        return(
            <Container>
                <ResetPassword/>
            </Container>
        );
    }
}

export default ResetPasswordScreen;
