import React, { Component, useDebugValue, useState } from "react";
import SilentReading from "./SilentReading";
import VideoPlayer from "../VideoPlayer/VideoPlayer";
import { storage } from "../../../FirestoreRedux";
import FirstDraftPage from "./FirstDraftPage";
import { Button } from "@material-ui/core";
import Quiz from "../ReadAnArticle/Quiz";

class FirstDraftSilentReading extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasStarted: false,
      videoUrl: "",
      noDraftSubmission:
        this.props.previousResponse === null ||
        this.props.previousResponse === undefined,
      noQuizSubmission:
        (this.props.previousResponse === null ||
        this.props.previousResponse === undefined) ||
        (this.props.previousResponse.quiz === undefined ||
          this.props.previousResponse.quiz === null),
    };
  }

  submitDraft = (response) => {
    this.props.updateDraftResponse(
      response,
      this.props.sectionIndex,
      this.props.subSectionKey
    );
    this.setState({ noDraftSubmission: false });
  };

  submitQuiz = (response) => {
    this.props.updateDraftQuizResponse(
      response,
      this.props.sectionIndex,
      this.props.subSectionKey
    );
    this.setState({ noQuizSubmission: false });
  }

  componentDidMount() {
    let self = this;
    storage
      .ref("videos/written/1.7.mp4")
      .getDownloadURL()
      .then(function (url) {
        self.setState({ videoUrl: url });
      });
  }

  onStart = () => {
    this.setState({ hasStarted: true });
  };

  render() {
    {
      var temp = (
        <a href="https://aplusuoft.com/knowledge/AcademicIntegrity">here.</a>
      );
    }
    return (
      <div>
        {!this.state.hasStarted ? (
          <div
            style={{
              justifyContent: "center",
            }}
          >
            <div style={{ margin: "5px" }}>
              You will now write a critical reflection paper about the article
              that you read, “Mothers of Invention”.
            </div>

            <div style={{ margin: "5px" }}>
              To begin, please watch the following video which provides some
              background on what a critical reflection is and outlines what you
              should include in your paper.
            </div>

            <VideoPlayer title="" url={this.state.videoUrl} />

            <b>
              <u>First Draft Instructions</u>
            </b>

            <div style={{ margin: "5px" }}>
              Now you will write the first draft of your critical reflection
              paper
            </div>

            <div style={{ margin: "5px" }}>
              Your first draft should be a maximum of <b>500 words.</b> The
              original article will be provided on your left and text box to
              type your draft on the right.
            </div>

            <div style={{ margin: "5px" }}>
              Your draft should include an{" "}
              <b>introduction, a summary, a critique and a short conclusion.</b>
            </div>

            <div style={{ margin: "5px" }}>
              In many courses, you will be asked to refer to additional sources
              to strengthen your argument. You will find four additional
              articles with similar themes to the “Mothers of Invention” article
              accessible by clicking on the boxes at the top of the article.
              Skim each article abstract and choose at least one article that
              interests you. You will need to incorporate some of the
              information from <b>at least one of these additional articles</b>{" "}
              into your reflection paper. When using additional sources, please
              remember that you must <b>follow proper academic style rules</b>{" "}
              (e.g. citations, paraphrasing, direct quotes). Be sure to include
              a reference list at the end of your paper. For a refresher on
              academic style, click {temp}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                outlineStyle: "solid",
                outlineColor: "black",
                backgroundColor: "rgba(215,109,219,0.3)",
              }}
            >
              <b>
                For the critique section, asking yourself the following
                questions may help you to develop your own critical perspective:
              </b>
              <br></br>
              <ul>
                <li>What are your own assumptions about the topic?</li>
                <li>What ideas surprised or concerned you and why?</li>
                <li>
                  Consider connections with your existing experience/knowledge
                  and the author’s key ideas.
                </li>
                <li>
                  How does this article reinforce or challenge your existing
                  ideas or assumptions?
                </li>
                <li>
                  How does this article help you to better understand the topic?
                </li>
              </ul>
            </div>

            <div style={{ margin: "5px" }}>
              When you have completed your draft, make sure to read through and{" "}
              <b>proofread your work.</b>
            </div>

            <div style={{ margin: "5px" }}>
              Once you are ready, press <b>Start</b> to begin composing your
              first draft.
            </div>
            <div style={{ margin: "5px" }}>
              When you are have finished the draft, click <b>Finished.</b>
            </div>
            <Button
              style={{
                background: "#7270a1",
                borderColor: "rgba(0, 32, 78)",
                marginRight: "10px",
                color: "white",
              }}
              onClick={this.onStart}
            >
              Start
            </Button>
          </div>
        ) : this.state.noDraftSubmission ? (
          <>
            <FirstDraftPage
              pdf={this.props.pdf}
              updateFunction={this.submitDraft}
              sectionIndex={this.props.sectionIndex}
              subSectionKey={this.props.subSectionKey}
            />
          </>
        ) : this.state.noQuizSubmission ? (
          <Quiz updateFunction={this.submitQuiz}/>
        ) : (
          <div>You have already made a submission for this draft.</div>
        )}
      </div>
    );
  }
}

export default FirstDraftSilentReading;
