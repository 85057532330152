import React from "react";
import NumberLineChart from "./NumberLineChart";
import { Typography, Grid, Box } from "@material-ui/core";

const SimpleDashboardSection = ({ header, description, score }) => {
  const renderBand = (score) => {
    return score ? (
      <span style={{ marginLeft: "50px" }}>
        Band{"      "}
        {score < 50
          ? "1 (<50)"
          : score < 60
          ? "2 (50-60)"
          : score < 70
          ? "3 (60-70)"
          : "4 (70-100)"}
      </span>
    ) : (
      <></>
    );
  };

  return (
    <Grid container spacing={1} style={{ marginBottom: "40px" }}>
      <Grid item xs={1} style={{ textAlign: "right" }}>
        <Typography variant={header === "Overall" ? "h2" : "h3"} color="navy">
          {score.score}
        </Typography>
      </Grid>
      <Grid item xs={11}>
        <Box style={{ marginLeft: "3.5rem" }}>
          <Typography variant={header === "Overall" ? "h3" : "h4"} color="navy">
            {header} {renderBand(score.score)}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {description ?? `Your ${header} score.`}
          </Typography>
        </Box>

        <div
          style={{
            height: "70px",
          }}
        >
          <NumberLineChart
            score={score.score}
            error={score.errorBar}
            saScore={score.selfAssessment}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default SimpleDashboardSection;
