import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AssessmentRowTwo from "./AssessmentRowTwo";
import TableHeaderSingle from "./TableHeaderSingle";
import { Button, Table, TableBody, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const SelfAssessmentSingle = ({
  questionsList,
  sectionTitle,
  previousResponse,
  updateFunction,
}) => {
  const classes = useStyles();
  const [response, setResponse] = React.useState(
    previousResponse === null || previousResponse === undefined
      ? new Array(questionsList.length).fill(false)
      : previousResponse
  );
  const [disabled, setDisabled] = React.useState(
    previousResponse !== null && previousResponse !== undefined
  );
  const [showSubmit, setShowSubmit] = React.useState(
    previousResponse === null || previousResponse === undefined
  );
  const [showAlert, setShowAlert] = React.useState(false);

  const handleResponse = (index) => {
    setResponse(
      response.map((item, j) => {
        if (j === index) return !item;
        return item;
      })
    );
  };

  const handleSubmit = () => {
    updateFunction(response);
    setDisabled(true);
    setShowSubmit(false);
    setShowAlert(true);
  };

  return (
    <Box className={classes.root}>
      <Table>
        <TableHeaderSingle sectionTitle={sectionTitle} />
        <TableBody>
          {questionsList.map((ques, idx) => (
            <AssessmentRowTwo
              question={ques}
              key={idx}
              disabled={disabled}
              response={response[idx]}
              lightBg={idx % 2 === 1}
              handleResponse={handleResponse}
              index={idx}
            />
          ))}
        </TableBody>
      </Table>
      {showSubmit && (
        <Box className={classes.submit}>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      )}
      {showAlert && (
        <Alert
          icon={<CheckCircleOutlineIcon fontSize="inherit" color="primary" />}
          severity="info"
          className={classes.alert}
        >
          <AlertTitle>Success! You've submitted your answer.</AlertTitle>
        </Alert>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  root: {
    width: "90%",
    margin: "auto",
  },
  submit: {
    marginTop: "2rem",
    textAlign: "center",
  },
  alert: {
    marginBottom: "3rem",
    marginTop: "2rem",
  },
});

export default SelfAssessmentSingle;
