import React from 'react';
import {withRouter} from 'react-router'
import {useHistory} from 'react-router-dom';
import { Card } from 'react-bootstrap';


const ImgCard = (props) => {
    const history = useHistory();
    return (
        <Card 
            className="shadow-sm"
            onClick={() => history.push(props.link, {moduleId:props.moduleId, subModuleId: props.subModuleId})}
            style={{'cursor': 'pointer', 'borderRadius': "30px"}}
            >
            <Card.Img 
                variant="top" 
                src={require('../../../static/images/home/students.png')} 
                style={{
                    'border-radius': "30px"
                    }}
                />
            <Card.Body>
            <Card.Title>{props.header}</Card.Title>
            <Card.Text>{props.description}</Card.Text>
            </Card.Body>
        </Card>
    );
};

export default withRouter(ImgCard);