import React, { Component } from 'react';
//import QuizData from './QuizData'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import UnCheckedCircle from '@material-ui/icons/RadioButtonUncheckedRounded';
import CheckedCircle from '@material-ui/icons/CheckCircleRounded';
import ReactHtmlParser from 'react-html-parser'; 

const MCQ = 'mcq';
const MSQ = 'msq';

class QuizCard extends Component {

    constructor(props) {
        super(props);

        this.renderAnswerOptions = this.renderAnswerOptions.bind(this);

    }

    renderAnswerOptions(key, index) {
        var origin_index = this.props.index_origin[this.props.questionId][index]
        if (this.props.qType === MCQ) {
            
            return (
                <FormControlLabel
                control={
                    <Checkbox className="answer-checkbox" key={origin_index}
                        icon={<UnCheckedCircle />}
                        checkedIcon={<CheckedCircle />}
                        checked={this.props.checkedArray[this.props.questionId][origin_index]}
                        onChange={this.props.handleCheck}
                        name={origin_index}
                        color="primary"
                        disabled={this.props.disable}
                    />
                }
                key={origin_index}
                label={
                    <React.Fragment>
                        {ReactHtmlParser (key.content)}
                        <br></br>
                        {key.image && <img className="quiz-answer-image" src={key.image} alt="quiz"></img>}
                    </React.Fragment>
                }
                className={"answerOption " + this.props.backColor[this.props.questionId][origin_index]}  
            />
            );
        }
        else if (this.props.qType === MSQ) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox className="answer-checkbox" key={origin_index}
                            checked={this.props.checkedArray[this.props.questionId][origin_index]}
                            onChange={this.props.handleCheck}
                            name={origin_index}
                            color="primary"
                            disabled={this.props.disable}
                        />
                    }
                    label={key.content}
                    className={"answerOption " + this.props.backColor[this.props.questionId][origin_index]}
                />
            );
        }

    }

    render() {
        
        return (
            <div className="quiz-card-container" id='test'>
                <div key={this.props.questionId}>
                    <h6 className="quiz-question">{this.props.question.split("\n").map((item)=> (<p>{item}<br/></p>))}</h6>
                    <FormGroup className="quiz-answerOptions">
                        {this.props.image && <img className="quiz-question-image" src={this.props.image} alt={this.props.questionId}></img>}
                        {this.props.answerOptions.map((currElement, index) => this.renderAnswerOptions(currElement, index))}
                    </FormGroup>
                </div>

            </div>
        );
    }
}


export default QuizCard;

