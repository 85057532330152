const t3=    {
    "subModuleTitle": "How to Use the Ideas of Others",
    "content": {
      "0": {
        "0": {
          "text": "When writing a paper, you often use the ideas of authors that you have researched.  This practice adds credibility to your formal academic writing.  An in-text citation is when you <b>cite</b> the author within the body of your writing.  This can happen in two ways: ",
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "type": "text"
        },
        "1": {
          "text": "1. You <i><b>paraphrase</b></i> key information presented by the author (i.e., put the ideas into your own words).",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "text"
        },
        "2": {
          "type": "text",
          "subSectionTitle": "",
          "text": "OR",
          "userState": { "viewed": false }
        },
        "3": {
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "text": "2. You <i><b>directly quote</b></i> the words of the author (i.e., using quotation marks, or “ ”).",
          "type": "text"
        },
        "4": {
          "userState": { "viewed": false },
          "type": "text",
          "text": "Each option has a slightly different formatting style. We will now explore how these options look in the APA citation style.",
          "subSectionTitle": ""
        },
        "userState": { "locked": false, "completed": false },
        "sectionIcon": "info",
        "sectionTitle": "3.1. What are In-text Citations",
        "sectionIconTitle": "In-text Citations"
      },
      "1": {
        "0": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "text": "Watch the video and identify the issues with the student’s paper.",
          "type": "text"
        },
        "1": {
          "subSectionTitle": "",
          "type": "video",
          "srcType": "url",
          "userState": { "viewed": false },
          "url": "videos/academicIntegrity/t3.mp4"
        },
        "userState": { "locked": true, "completed": false },
        "sectionIcon": "video",
        "sectionTitle": "3.2. Accurate Citations",
        "sectionIconTitle": "Accurate Citations"
      },
      "2": {
        "0": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "type": "text",
          "text": "Now, let’s reflect on the situation by answering a couple of questions. You can watch the video again if you would like."
        },
        "1": {
          "url": "videos/academicIntegrity/t3.mp4",
          "srcType": "url",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "video"
        },
        "2": {
          "questions": [
            {
              "userState": { "response": [""] },
              "qId": "0",
              "feedback": "It is important to keep a balance between direct quoting and paraphrasing in your own writing. So, make sure that direct quotes are not overused in your writing.​",
              "qType": "open-res",
              "qText": "What is more common in your discipline, paraphrasing or quoting?"
            }
          ],
          "subSectionTitle": "",
          "type": "quiz"
        },
        "3": {
          "questions": [
            {
              "qType": "open-res",
              "userState": { "response": [""] },
              "sectionComplete": true,
              "qText": "Why is it important to situate your ideas within the work of others?",
              "feedback": "Situating your ideas within the work of others adds credibility and weight to your argument. Generally, scholars identify a gap in knowledge and then address that knowledge gap through conducting their own empirical research. Using accurate academic style lends credibility to your work and matches the style of other writers.​",
              "qId": "0"
            }
          ],
          "subSectionTitle": "",
          "type": "quiz"
        },
        "sectionIconTitle": "Reflection",
        "sectionTitle": "3.3 Reflection",
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "comment"
      },
      "3": {
        "0": {
          "subSectionTitle": "",
          "text": "An in-text citation can either be <i><b>parenthetical</b></i>, when the author's name is not grammatically part of the sentence, or <b><i>narrative</i></b>  when the author's name is grammatically part of the sentence. The style choice is up to you. Both types also need the year of publication of the source. The examples are formatted in APA 7 style.",
          "userState": { "viewed": false },
          "type": "text"
        },
        "1": {
          "text": "<br/><i>1 author:</i><br/>                    There are three main causes of obesity (Weir, 2019).<br/><br/>                    <i>2 authors:</i><br/>                    There are three main causes of obesity (Weir & Taylor, 2020).<br/><br/>                    <i>3 or more authors:</i><br/>                    There are three main causes of obesity (Weir et al., 2020).",
          "type": "text",
          "userState": { "viewed": false },
          "subSectionTitle": "Parenthetical Citations"
        },
        "2": {
          "userState": { "viewed": false },
          "subSectionTitle": "Narrative Citations",
          "text": "<br/><i>1 author:</i><br/>                    Weir (2019) states that there are three main causes of obesity.<br/><br/>                    <i>2 authors:</i><br/>                    Weir and Taylor (2020) state that there three main causes of obesity.<br/><br/>                    <i>3 or more authors:</i><br/>                    Weir et al. (2020) state that there are three main causes of obesity.<br/><br/>                **Note that \"et al.\" is a Latin term meaning \"and others\", so we use it to indicate                 that there are other authors besides the principal author, without listing them all,                 if there are more than two.",
          "type": "text"
        },
        "sectionTitle": "3.4 Types of In-Text Citations: Parenthetical and Narrative​",
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "info",
        "sectionIconTitle": "Citations"
      },
      "4": {
        "0": {
          "subSectionTitle": "",
          "text": "The sentences below have citation style errors in APA style. Rewrite the sentences using accurate APA citation style by following the examples in 3.5 (previous exercise). Submit your answer and then view feedback.  In future, use Style Guides to ensure your accuracy.​",
          "type": "text",
          "userState": { "viewed": false }
        },
        "1": {
          "subSectionTitle": "Rewrite the sentence with the correct parenthetical citation style and SUBMIT.​",
          "questions": [
            {
              "qType": "open-res",
              "userState": { "response": [""] },
              "qText": "Assessment bias compromises score validity,  Kunan. (2019)​",
              "qId": "0",
              "feedback": "Assessment bias compromises score validity (Kunan, 2019).​"
            }
          ],
          "type": "quiz"
        },
        "2": {
          "questions": [
            {
              "qType": "open-res",
              "qId": "0",
              "qText": "According to  Kunan  & Wang, 2020, assessment bias compromises score validity.​",
              "feedback": "According to Kunan and Wang (2020), assessment bias compromises score validity.​​​",
              "userState": { "response": [""] }
            }
          ],
          "type": "quiz",
          "subSectionTitle": "Rewrite the sentence with the correct narrative citation style and SUBMIT.​​"
        },
        "3": {
          "type": "quiz",
          "subSectionTitle": "​Rewrite the sentence with the correct narrative citation style and SUBMIT.​ ",
          "questions": [
            {
              "userState": { "response": [""] },
              "sectionComplete": true,
              "feedback": "Baker et al. (2020) report that assessment bias compromises score validity.​​​",
              "qType": "open-res",
              "qText": "Baker, Brooks,  Kunan, & Wang, 2020, report that assessment bias compromises score validity.​​​",
              "qId": "0"
            }
          ]
        },
        "sectionIconTitle": "Practice",
        "userState": { "locked": true, "completed": false },
        "sectionTitle": "3.5 Practice ",
        "sectionIcon": "comment"
      },
      "5": {
        "0": {
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": "",
          "text": "Generally, you should quote the source exactly word for word, when you feel the information from your source is very concise or when the language used is very persuasive. You must put the exact words in quotation marks and include the book or article page number that the quote came from."
        },
        "1": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "text": "There are two ways to cite direct quotations in APA (7th Ed.)​",
          "type": "text"
        },
        "2": {
          "text": "1. <b>Narrative</b> citation for a direct quote (author's name is part of the sentence)<br/>O'Loughlin (2018) noticed that \"patterns of achievement were less related to gender than socio economic status\" (p. 453). ",
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "3": {
          "subSectionTitle": "",
          "type": "text",
          "text": "2. <b>Parenthetical</b> citation for a direct quote (author's name is not part of the sentence)​<br/>\"Test score users’ underdeveloped knowledge about the meaning of test scores can also have a profound impact on students\" (Pearce, 2008, p. 112).",
          "userState": { "viewed": false }
        },
        "4": {
          "questions": [
            {
              "qText": "PRACTICE QUESTION: Rewrite #2 below using a narrative citation (author's name as part of the sentence)",
              "feedback": "Pearce (2008) noticed that \"test score users' underdeveloped knowledge about the meaning of test scores can also have a profound impact on students\" (p. 112).​​​ \n Check your details: \n 1. The period is placed after the parenthesis, not before.\n 2. There is no punctuation after the author's name.",
              "userState": { "response": [""] },
              "qId": "0",
              "sectionComplete": true,
              "qType": "open-res"
            }
          ],
          "subSectionTitle": "​",
          "type": "quiz"
        },
        "userState": { "locked": true, "completed": false },
        "sectionTitle": "3.6 Using Quotations",
        "sectionIcon": "info",
        "sectionIconTitle": "Quotations"
      },
      "6": {
        "0": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "type": "text",
          "text": "When you use the authors ideas but say them using your own words, this is called paraphrasing. Here is an example of the author's words (original source) and a paraphrased version of those ideas:"
        },
        "1": {
          "type": "text",
          "subSectionTitle": "​Sample original source:",
          "text": "“There are a variety of possible ways of combining Automated Writing Evaluation (AWE) with teacher feedback, and of scaffolding AWE feedback. To name just a couple, students can use AWE to help them improve the quality of initial drafts and then submit to the teacher for feedback, teachers can use AWE as a diagnostic tool for identifying the problems that students have with their writing, and/or teachers can provide initial training” (Stevenson & Phakiti, 2014, p. 63). ",
          "userState": { "viewed": false }
        },
        "2": {
          "userState": { "viewed": false },
          "subSectionTitle": "Reference​",
          "type": "textIndent",
          "text": "Stevenson, M., & Phakiti, A. (2014). The effects of computer-generated feedback on the quality of    writing. <i>Assessing Writing, 19</i>, 51-65. <u>https://doi.org/10.1016/j.asw.2013.11.007</u> "
        },
        "3": {
          "subSectionTitle": "Paraphrased version of this paragraph:",
          "type": "text",
          "userState": { "viewed": false },
          "text": "Many potential approaches  can be adopted to integrate Automated Writing Evaluation (AWE) into teacher feedback and to support AWE feedback. For example, students can revise their initial drafts with AWE feedback before submitting for teacher feedback, and teachers can benefit from the help that AWE provides in diagnosing students’ writing problems (Stevenson & Phakiti, 2014). "
        },
        "4": {
          "text": " Tips for paraphrasing: <ul><li>Use synonyms, such as ‘ways-approaches’, ‘combine-integrate’, etc.</li><li>Express the same meaning with a different structure (There are a variety of possible ways - Many potential approaches can be adopted).  </li><li>You don’t have to paraphrase every word or phrase in a sentence because it’s not always possible.</li><li>A citation <b>must</b> follow the paraphrase.</li></ul>​",
          "type": "text",
          "subSectionTitle": "​",
          "userState": { "viewed": false }
        },
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "info",
        "sectionIconTitle": "Paraphrasing",
        "sectionTitle": "3.7 Paraphrasing"
      },
      "7": {
        "0": {
          "type": "text",
          "userState": { "viewed": false },
          "subSectionTitle": "​",
          "text": "<b>Now it is your turn! Paraphrase the information below in the space provided. Try to explain the ideas in your own words and add a citation based on the reference provided.​</b>"
        },
        "1": {
          "text": "<i>To many mountain climbers, Mount Everest presents the ultimate challenge. Ninety seven percent of people who want to climb to the top of this mountain need to use oxygen after reaching a certain height. However, in May 2017, Kilian Jornet ascended to the top of Mt. Everest in 26 hours without oxygen or climbing ropes in just 26 hours while suffering from food poisoning. Five days later, he repeated the same act but in just 17 hours this time. To this day, no one has been able break this record.</i>",
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": "​Practice Source"
        },
        "2": {
          "userState": { "viewed": false },
          "type": "textIndent",
          "subSectionTitle": "​Reference",
          "text": "Francis, A. (2019, June 6). Kilian Jornet's two Everest summits disputed in new book. <i>Canadian Running Magazine.</i> <br/>https://runningmagazine.ca/trail-running/kilian-jornets-two- everest- summits-disputed-in-new-book/"
        },
        "3": {
          "type": "quiz",
          "subSectionTitle": "",
          "questions": [
            {
              "feedback": "<b><u><i>Example of Paraphrasing:</i></u></b><br/><br/>For athletes who like climbing mountains, Mt. Everest is often considered the hardest challenge. Oxygen is often needed for most climbers after they have gotten to a high elevation. Yet, in May 2017, Kilian Jornet reached the top of the mountain in just 26 hours when he had food poisoning and did not have oxygen or equipment. He performed this amazing feat again five days later, but it only took him 17 hours this time. This is the current world record for climbing Mt. Everest.<i>(Francis, 2019).</i>",
              "qId": "0",
              "qType": "open-res",
              "userState": { "response": [""] },
              "topicComplete": true,
              "qText": "",
              "sectionComplete": true
            }
          ]
        },
        "sectionIcon": "comment",
        "sectionIconTitle": "Practice",
        "sectionTitle": "3.8 Paraphrasing Practice",
        "userState": { "locked": true, "completed": false }
      }
    },
    "userState": { "progress": 0, "locked": false }
  }

export default t3;