import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar.js";
import TopNavBar from "./TopNavBar/TopNavBar.js";
import '../../CSS_share.css'


class DashFrame extends Component {

  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      links: []
    };
    this.handleSideBarButton = this.handleSideBarButton.bind(this);
  }

  handleSideBarButton() {
    const oldState = this.state.sidebarOpen
    this.setState({
      sidebarOpen: !oldState
    });
  }

  render() {
    
    var contentVisibility = 'body_right'
    if (this.state.sidebarOpen === true) contentVisibility = 'body_right'
    else contentVisibility = 'body_left'

    if (!this.props.auth.uid) return <Redirect to="/home"></Redirect>;
    return (
      <div>
        <Sidebar className="float-left" open={this.state.sidebarOpen} />
        <div id="wrapper" className={'flex-grow-1 ' + contentVisibility} style={{ "marginLeft": "9rem" }}>
          <TopNavBar imageUrl={"./hellp.png"} onButtonClick={this.handleSideBarButton} />
            
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateTOProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateTOProps)(DashFrame);