import React, {Component} from 'react';
import { Container, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player';

class VideoPlayer extends Component{

    config = { file: 
                { attributes: { 
                    controlsList: 'nodownload',
                    disablePictureInPicture: true} 
                } 
            };

    render(){
        
        return(
            <Container style={{marginBottom: '30px'}}> 
                {this.props.title && <h4>{this.props.title}</h4>}
                
                <Row id="react-player" className="justify-content-center" > 
                    <ReactPlayer 
                        onContextMenu={e => e.preventDefault()}
                        controls
                        playbackRate={1}
                        url={this.props.url}
                        pip={false}
                        config={this.config}
                        onProgress={this.props.onProgress}
                    ></ReactPlayer>
                </Row>

            </Container>);
    }
}

export default VideoPlayer;