
import { db } from '../../../FirestoreRedux.js';
import { mergeModuleDataAndUserState, extractModuleState } from '../../../service/modules/moduleState';
import { updateModuleState } from '../../../service/users/contentManagement/dbService'
export const fetchModuleSnippets = (userId, moduleType) => {
    
    return (dispatch) => {   
        
        let sessionsRef = db.collection('users').doc(userId).collection('modules');
        
        sessionsRef.where('type', '==', moduleType).get()
        .then(snapshot => {
            if (snapshot.empty) {
                dispatch({ type: "NO_SNIPPETS" })
            return;
            }  

            let snippetCollection = {};
            snapshot.forEach(doc => {
                let snippet;
                snippet = doc.data()
                snippet.moduleId = doc.id
                snippetCollection[doc.id] = snippet;
            });
            // Set session storage
            
            dispatch({
                type: "SNIPPET_FETCH_SUCCESS",
                snippets: snippetCollection
            })
        })
        .catch(err => {
            dispatch({ type: "SNIPPET_FETCH_ERROR", err })
        });
    }
}

export const fetchModuleStateById = (userId, moduleId) => {
    if (moduleId === undefined){
        moduleId = "none";
    }
    if (userId === undefined){
        userId = "";
    }
    return (dispatch) => {   
        
        let docRef = db.collection("users").doc(userId).collection('modules').doc(moduleId);
        docRef.get()
        .then(function(doc) {
            if (doc.exists) {
                
                let moduleProgressState = doc.data()
                moduleProgressState.moduleId = doc.id
                
                dispatch({
                    type: "MODULE_STATE_FETCH_SUCCESS",
                    moduleProgressState: moduleProgressState
                })
                db.collection('modules').doc(moduleId).get().then(function(moduleDoc) {

                    if (moduleDoc.exists) {
                        let moduleContent = mergeModuleDataAndUserState(moduleDoc.data(), moduleProgressState)
                        moduleContent.moduleId = moduleDoc.id

                        dispatch({
                            type: "MODULE_FETCH_SUCCESS",
                            moduleContent: moduleContent
                        })
                        
                    } else {
                       dispatch({ type: "NO_MODULES" })
                    }
                })
                
            } else {
               dispatch({ type: "NO_MODULE_STATE" })
            }
        }).catch(function(err) {
            dispatch({ type: "MODULE_STATE_FETCH_ERROR", err })
        });
    }
}

export const fetchModuleById = (moduleId) => {
    if (moduleId === undefined){
        moduleId = "none";
    }
    return (dispatch) => {   
        
        let docRef = db.collection('modules').doc(moduleId);
        docRef.get()
        .then(function(doc) {
            if (doc.exists) {
                
                let moduleContent = doc.data()
                moduleContent.moduleId = doc.id
                dispatch({
                    type: "MODULE_FETCH_SUCCESS",
                    moduleContent: moduleContent
                })
                const moduleData = JSON.parse(sessionStorage.moduleData)
                moduleData[doc.id] = moduleContent
                sessionStorage.setItem('moduleData', JSON.stringify(moduleData) );
            } else {
               dispatch({ type: "NO_MODULES" })
            }
        }).catch(function(err) {
            dispatch({ type: "MODULE_FETCH_ERROR", err })
        });
    }
}

export const updateModuleData = (userId, moduleData) => {

    return (dispatch) => {   
        
        dispatch({
            type: "MODULE_FETCH_SUCCESS",
            moduleContent: moduleData
        })
        updateModuleState(userId, moduleData.moduleId, extractModuleState(moduleData))
    }
}

export const addNewModule = (moduleData) => {
    db.collection('modules').add(moduleData);
}

export const fixExistingModule = (moduleId, moduleData) => {
    
    return db.collection('modules').doc(moduleId).update(moduleData)
            .then( () => { console.log("Updated correctly")})
            .catch((err) => console.log(err))
}

