import { db } from '../../FirestoreRedux.js';

export const fetchActiveSession = () => {
    
    return (dispatch, getState) => {   
        
        let sessionsRef = db.collection('sessions');
        
        sessionsRef.where('active', '==', true).get()
        .then(snapshot => {
            if (snapshot.empty) {
                dispatch({ type: "NO_ACTIVE_SESSION" })
            return;
            }  
            let data;
            snapshot.forEach(doc => {
                data = doc.data();
                data.sessionId = doc.id;
            });
            
            dispatch({
                type: "SESSION_FETCH_SUCCESS",
                session: data
            })
        })
        .catch(err => {
            dispatch({ type: "SESSION_FETCH_ERROR", err })
        });
    }
}

export const fetchSessionCourses = (sessionId) => {
    if (sessionId === undefined){
        sessionId = "";
    }
    return (dispatch, getState) => {   
        
        let coursesRef = db.collection('courses');
        
        coursesRef.where('session', 'array-contains', sessionId).get()
        .then(snapshot => {
            if (snapshot.empty) {
                dispatch({ type: "NO_ACTIVE_COURSES" })
            return;
            }  
            let data = [];
            snapshot.forEach(doc => {
                data.push(doc.data());
            });
            dispatch({
                type: "COURSE_FETCH_SUCCESS",
                courses: data
            })
        })
        .catch(err => {
            dispatch({ type: "COURSE_FETCH_ERROR", err })
        });
    }
}