import { combineReducers } from 'redux';

const moduleSnippetState = {
    isLoaded: null,
    snippets: {}
}

const moduleSnippets = (state = moduleSnippetState, action) => {
    switch(action.type){
        case 'SNIPPET_FETCH_ERROR':
            return {
                ...state,
                isLoaded: false,
                
            };
        case 'NO_SNIPPETS':
            return {
                ...state,
                isLoaded: false,
                snippets: {}
            };
        case 'SNIPPET_FETCH_SUCCESS':
            return {
                isLoaded: true,
                snippets: action.snippets,
            }
        default:
            return state;
    }
}

const moduleContentState = {
    isLoaded: null,
    content: {},
    moduleProgressState: {}
}

const moduleContent = (state = moduleContentState, action) => {
    switch(action.type){
        case 'MODULE_FETCH_ERROR':
            return {
                ...state,
                isLoaded: false
            };
        case 'NO_MODULES':
            return {
                ...state,
                isLoaded: false
            };
        case 'MODULE_FETCH_SUCCESS':
            return {
                ...state,
                isLoaded: true,
                content: action.moduleContent,
            }
        case 'MODULE_STATE_FETCH_SUCCESS':
            return {
                ...state,
                
                moduleProgressState: action.moduleProgressState,
            }
        case 'MODULE_STATE_FETCH_ERROR':
            return {
                ...state,
                
                moduleProgressState: action.moduleProgressState,
            }
        default:
            return state;
    }
}

const moduleReducer = combineReducers({
    moduleSnippets : moduleSnippets,
    moduleContent : moduleContent
});

export default moduleReducer;