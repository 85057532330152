import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import JsPDF from "jspdf";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Autocomplete } from "@material-ui/lab";
import {
  Box,
  Button,
  capitalize,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  AggResults,
  getCategoryKey,
  getEntries,
  groupAndAverage,
  QuantileGroup,
  QuantileGroupAverage,
  RawResult,
} from "./DetailedDashboard";
import Analysis from "./Analysis";

export const StudentSelect = ({ ids, onSelect, value }) => {
  return (
    <Autocomplete
      onChange={(e, v) => onSelect(v || "")}
      options={ids}
      placeholder="search for a student"
      renderInput={(params) => <TextField {...params} variant="standard" />}
      value={value || ""}
      fullWidth={true}
    />
  );
};

const formatScore = (val) =>
  +val > 3 ? (+val).toLocaleString() : (+val).toPrecision(3);

const UserView = ({ aggResults, quantileAverages, rawResults }) => {
  const [selected, setSelected] = useState();

  const userAverages = useMemo(() => {
    if (selected) {
      return groupAndAverage(selected);
    }
  }, [selected]);

  const detailedComparison = useMemo(() => {
    if (selected && selected.Level && selected.Year) {
      const comparisonAverages =
        aggResults.year[selected.Year][selected.Level].mean;

      const ret = { written: {}, oral: {}, vocab: {} };

      for (const key in selected) {
        const categoryKey = getCategoryKey(key);
        if (categoryKey) {
          ret[categoryKey][key] = {
            individual: selected[key],
            group: comparisonAverages[key],
          };
        }
      }
      return ret;
    }
  }, [selected]);

  const basicComparisonChartData = useMemo(() => {
    if (selected && userAverages) {
      return getEntries(userAverages)
        .map(([k, v]) => ({
          [k]: [
            {
              name: "You",
              value: +(v || 0),
            },
            {
              name: "Median",
              value:
                quantileAverages.year[selected.Year][selected.Level].q["0.5"][
                  k
                ],
            },
            {
              name: "Upper",
              value:
                quantileAverages.year[selected.Year][selected.Level].q["0.75"][
                  k
                ],
            },
            {
              name: "Lower",
              value:
                quantileAverages.year[selected.Year][selected.Level].q["0.25"][
                  k
                ],
            },
          ],
        }))
        .reduce((acc, curr) => ({ ...acc, ...curr }), {});
    }
  }, [selected, userAverages]);

  const overviewConfig = [
    {
      title: "Written",
      key: "written",
      userVal: userAverages?.written || 0,
    },
    { title: "Oral", key: "oral", userVal: userAverages?.oral || 0 },
    {
      title: "Vocabulary",
      key: "vocab",
      userVal: userAverages?.vocab || 0,
    },
  ];

  return (
    <Grid container spacing={6} direction="column" alignItems="center">
      <Analysis />

      <Grid item width="300px">
        {rawResults && (
          <StudentSelect
            ids={rawResults.map((r) => r.id)}
            onSelect={(id) => setSelected(rawResults.find((r) => r.id === id))}
            value={selected?.id || ""}
          />
        )}
      </Grid>

      {selected && detailedComparison && basicComparisonChartData && (
        <>
          <Grid item container flexDirection="column" alignItems="center">
            <Typography variant="h3">Your Results</Typography>
            <Button
              onClick={() => {
                const report = new JsPDF("portrait", "pt", "a4");
                report.html(document.querySelector(".export")).then(() => {
                  report.save("report.pdf");
                });
              }}
            >
              Download Score
            </Button>
          </Grid>
          <Grid
            spacing={3}
            item
            justifyContent="space-between"
            container
            direction="row"
          >
            <Grid item>
              {!!userAverages && (
                <SummaryScoreCard
                  scores={{
                    oralAverage: userAverages.oral
                      ? formatScore(userAverages.oral)
                      : "Not Submitted",
                    writtenAverage: userAverages.written
                      ? formatScore(userAverages.written)
                      : "Not Submitted",
                    vocab: userAverages.vocab
                      ? formatScore(userAverages.vocab)
                      : "Not Submitted",
                  }}
                />
              )}
            </Grid>
            <Grid item>
              <UserIdCard
                id={selected.id}
                level={selected.level}
                year={selected.Year}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" item spacing={4}>
            {overviewConfig.map((overview, i) => (
              <Grid
                alignItems="flex-start"
                container
                item
                direction="row"
                spacing={1}
                key={i}
                xs={4}
              >
                <UserCard
                  basicComparison={basicComparisonChartData[overview.key]}
                  detailedComparison={detailedComparison[overview.key]}
                  overview={overview}
                />
              </Grid>
            ))}
          </Grid>

          {userAverages && selected && (
            <Export
              className="export"
              oral={userAverages.oral}
              written={userAverages.written}
              vocab={userAverages.vocab}
              id={selected.id}
              level={selected.Level}
              year={selected.Year}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default UserView;
const colors = ["#329ba8", "#eb4034", "#95eb34", "#e534eb"];

const VerticalBarChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={200}>
    <BarChart data={data}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" type="category" />
      <YAxis type="number" />
      <Tooltip />
      <Bar dataKey="value">
        {data.map((d, i) => (
          <Cell key={d.name} fill={colors[i]} />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

const HorizontalBarChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={100}>
    <BarChart barCategoryGap={1} layout="vertical" data={data}>
      <YAxis type="category" dataKey="name" />
      <XAxis type="number" />
      <Tooltip />
      <Bar dataKey="value">
        {data.map((d, i) => (
          <Cell key={d.name} fill={colors[i]} />
        ))}
      </Bar>
    </BarChart>
  </ResponsiveContainer>
);

const SummaryScoreCard = ({ scores }) => (
  <Card variant="outlined">
    <CardHeader
      title="Quick View"
      subheader="Averages only; See below for details"
    />
    <CardContent>
      <>
        <SummaryEntry label="Writing" result={scores.writtenAverage} />
        <SummaryEntry label="Oral" result={scores.oralAverage} />
        <SummaryEntry label="Vocabulary" result={scores.vocab} />
      </>
    </CardContent>
  </Card>
);

const UserIdCard = ({ id, level, year }) => (
  <>
    <SummaryEntry label="ID" result={id} />
    <SummaryEntry label="Level" result={level || "unknown"} />
    <SummaryEntry label="Year" result={year || "unknown"} />
  </>
);

const UserCard = ({
  basicComparison,
  detailedComparison,
  overview: { title, userVal },
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card>
      <CardHeader
        title={title}
        sx={{
          backgroundColor: (theme) => theme.palette.primary.light,
          textAlign: "center",
        }}
      />
      <CardContent>
        <SummaryEntry
          label={"Your Average Score"}
          result={formatScore(userVal)}
        />
        <Typography>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </Typography>
        <Box sx={{ padding: 2 }}>
          {!!basicComparison && <VerticalBarChart data={basicComparison} />}
        </Box>
      </CardContent>
      <CardActions>
        {!!userVal && (
          <IconButton onClick={() => setExpanded(!expanded)}>
            <>
              {expanded ? <ExpandLess /> : <ExpandMore />}{" "}
              <Typography>{expanded ? "Hide " : "Show "}Details</Typography>
            </>
          </IconButton>
        )}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ maxHeight: "500px", overflow: "auto" }}>
          {!!detailedComparison &&
            getEntries(detailedComparison).map(([k, v]) => (
              <Grid container direction="column" item key={k}>
                <Typography variant="h5">{capitalize(k)}</Typography>
                <HorizontalBarChart
                  data={[
                    {
                      name: "Your Score",
                      value: v.individual,
                    },
                    {
                      name: "Average",
                      value: v.group,
                    },
                  ]}
                />
              </Grid>
            ))}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Bold = styled.span`
  font-weight: bold;
`;

const SummaryEntry = ({ label, result }) =>
  result ? (
    <Typography>
      <Bold>{label}:&nbsp;</Bold>
      {result}
    </Typography>
  ) : null;

const _Export = ({ className, id, oral, level, vocab, written, year }) => (
  <Grid direction="column" className={className}>
    <Typography variant="h3" color="primary">
      APlus Scorecard
    </Typography>
    <Divider sx={{ width: "100%", color: "primary", margin: "5px 0px" }} />
    <UserIdCard id={id.toString()} level={level} year={year} />
    <SummaryEntry
      label="Writing"
      result={written ? formatScore(written) : "Not submitted"}
    />
    <SummaryEntry
      label="Oral"
      result={oral ? formatScore(oral) : "Not submitted"}
    />
    <SummaryEntry
      label="Vocabulary"
      result={vocab ? Math.round(+vocab).toLocaleString() : "Not submitted"}
    />
    <Typography variant="caption">Generated {getTimestamp()}</Typography>
  </Grid>
);

const Export = styled(_Export)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
`;

const getTimestamp = () => {
  const now = new Date();
  return `${now.getFullYear()}-${now.getMonth()}-${now.getDay()}`;
};
