import { db } from "../../../FirestoreRedux";
import { extractSubModuleState } from "../../../service/modules/moduleState";
/**
 * Add the module data provided in the
 * @param {} moduleData | Module data parameters
 */

export async function getAllModuleIds() {
  const modules = [];
  db.collection("modules")
    .get()
    .then((query) => {
      query.forEach((doc) => {
        modules.push(doc.id);
      });
    });
  return modules;
}

export function copyModule(moduleID) {
  db.collection("modules")
    .doc(moduleID)
    .get()
    .then((query) => {
      addModuleToDb(query.data(), "test123");
    })
    .catch((err) => {
      console.log(err);
    });
}

export function addModuleToDb(moduleData, moduleID) {
  let validatedModuleData = prepareModuleTemplate(moduleData);
  let extractedUserState = extractSubModuleState(validatedModuleData);

  // Add data to the firestore
  const moduleDocRef = db.collection("modules").doc(moduleID);
  return moduleDocRef
    .set(validatedModuleData)
    .then(function () {
      let users = db.collection("users");
      return users
        .get()
        .then(function (querySnapshot) {
          console.log("Soemthing");
          querySnapshot.forEach(function (doc) {
            db.collection("users")
              .doc(doc.id)
              .collection("modules")
              .doc(moduleDocRef.id)
              .set({
                title: validatedModuleData.title,
                type: validatedModuleData.type,
                userState: validatedModuleData.userState,
                subModules: extractedUserState,
              });
          });
          return "Added module to user";
        })
        .catch(function (err) {
          console.log(err);
          return err;
        });
      //return "SUCCESS";
    })
    .catch(function (err) {
      console.log(err);
      return err;
    });
}

export async function addMissing(moduleData, moduleID) {
  let validatedModuleData = prepareModuleTemplate(moduleData);
  let extractedUserState = extractSubModuleState(validatedModuleData);

  const moduleDocRef = db.collection("modules").doc(moduleID);

  let users = db.collection("users");
  const querySnapshot = await users.get();

  querySnapshot.forEach(async function (doc) {
    let curr = await db
      .collection("users")
      .doc(doc.id)
      .collection("modules")
      .doc(moduleDocRef.id)
      .get();
    if (!curr.exists) {
      console.log(doc.id);
      console.log(doc.data());
      await db
        .collection("users")
        .doc(doc.id)
        .collection("modules")
        .doc(moduleDocRef.id)
        .set({
          title: validatedModuleData.title,
          type: validatedModuleData.type,
          userState: validatedModuleData.userState,
          subModules: extractedUserState,
        });
    }
  });
  return "Added module to user";

  //return "SUCCESS";
}

export async function wipeUserData(moduleId, moduleData, email) {
  let validatedModuleData = prepareModuleTemplate(moduleData);
  let extractedUserState = extractSubModuleState(validatedModuleData);

  const moduleDocRef = db.collection("modules").doc(moduleId);
  console.log(moduleDocRef.id);

  const usersRef = db.collection("users");
  const users = await usersRef.get();

  users.forEach(async function (doc) {
    if (doc.data().email === email) {
      try {
        await db
          .collection("users")
          .doc(doc.id)
          .collection("modules")
          .doc(moduleId)
          .set({
            title: validatedModuleData.title,
            type: validatedModuleData.type,
            userState: validatedModuleData.userState,
            subModules: extractedUserState,
          });
      } catch (e) {
        console.log(e);
      }
      return;
    }
  });
}

export async function unlockModule(moduleId, email) {
  const usersRef = db.collection("users");
  const users = await usersRef.get();

  users.forEach(async function (doc) {
    if (doc.data().email === email) {
      const moduleDataOld = await db
        .collection("users")
        .doc(doc.id)
        .collection("modules")
        .doc(moduleId)
        .get();

      let moduleDataOldStr = JSON.stringify(moduleDataOld.data());

      let moduleDataNewStr = moduleDataOldStr.replace(
        /"locked":true/g,
        '"locked":false'
      );

      let moduleDataNew = JSON.parse(moduleDataNewStr);
      moduleDataNew.userState.progress = moduleDataNew.userState.totalQuestions;
      console.log(moduleDataNew.userState.progress);

      return db
        .collection("users")
        .doc(doc.id)
        .collection("modules")
        .doc(moduleId)
        .set(moduleDataNew);
    }
  });
}

export function updateModule(moduleData, moduleID) {
  let validatedModuleData = prepareModuleTemplate(moduleData);
  let extractedUserState = extractSubModuleState(validatedModuleData);

  // Add data to the firestore
  const moduleDocRef = db.collection("modules").doc(moduleID);
  return moduleDocRef
    .set(validatedModuleData)
    .then(function (moduleRef) {
      let users = db.collection("users");
      return users
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            db.collection("users")
              .doc(doc.id)
              .collection("modules")
              .doc(moduleDocRef.id)
              .set({
                title: validatedModuleData.title,
                type: validatedModuleData.type,
                //userState:validatedModuleData.userState,
                subModules: extractedUserState,
              });
          });
          return "Added module to user";
        })
        .catch(function (err) {
          console.log(err);
          return err;
        });
      //return "SUCCESS";
    })
    .catch(function (err) {
      return err;
    });
}

/**
 * Prints on the console, info about users
 */
export async function printInfo() {
  let users = db.collection("users");
  let i = 0;

  users.get().then(function (querySnapshot) {
    querySnapshot.forEach(function (doc) {
      let date = doc.data().creationTime.toDate();

      if (date.getFullYear() === 2021 && date.getMonth() > 6) i++;
    });
    console.log(i);
  });
}

/**
 * Fetches and writes in a file the specified module
 */
export async function writeModuleToFile(moduleId) {
  let docRef = db.collection("modules").doc(moduleId);
  docRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        let moduleContent = doc.data();
        moduleContent.moduleId = doc.id;

        console.log(JSON.stringify(moduleContent));
      }
    })
    .catch(function (err) {
      console.log(err);
    });
}

/**
 * Return true if the module data is valid.
 * @param {*} moduleData
 * @param {string} moduleID
 */
export async function updateSpecific(moduleData, moduleID) {
  // let validatedModuleData = prepareModuleTemplate(moduleData)

  const moduleDocRef = db.collection("modules").doc(moduleID);
  moduleDocRef.get().then(async function (doc) {
    //let subModuleData = doc.data().subModules
    //subModuleData[5].content[3][2].text = "For more information, click below for the U of T Libraries citation guide. It contains information on several different citation styles, tutorials, style guides, and other supplementary material to help you understand, practice, and produce correct citations."
    await moduleDocRef.update({
      instructions: {
        header: "Instructions",
        body: "Complete the Written Communication module by clicking on the “Topic” link below. When you have finished completing the Topic, you will be directed back to this page where you will have the opportunity to first complete a brief post-survey and then download a certificate of completion using the button below.",
      },
    });
  });
  // return moduleDocRef.update( {
  //                                 subModules: null
  //                             }
  //                     )
  // .then(function(moduleRef){
  //     let users = db.collection("users")
  //     return users.get().then(function(querySnapshot) {
  //         querySnapshot.forEach(function(doc) {
  //             db.collection("users").doc(doc.id)
  //             .collection('modules').doc(moduleDocRef.id)
  //             .update(
  //                 {
  //                     instructions: {"header": "Instructions", "body": "You can complete the 6 Topics in the Academic Integrity module in any order. Please complete the Survey when you begin and the brief Post-survey when you finish the module.<br/><br/>Topic 6 includes resources you can use for further learning. You need to complete all activities in each Topic and the brief Post-survey to receive a Certificate of Completion."},
  //                 }
  //             )
  //         });
  //         return "Added module to user"
  //     }).catch(function(err){
  //         console.log(err)
  //         return err
  //     })
  //     //return "SUCCESS";
  // })
  // .catch(function(err){
  //     return err;
  // });

  // return moduleDocRef.set(validatedModuleData)
  // .then(function(moduleRef){
  //     let users = db.collection("users")
  //     return users.get().then(function(querySnapshot) {
  //         querySnapshot.forEach(function(doc) {
  //             if (doc.id === 'DwgYV8l4anfNqkJtT5fk0N31tWF2') {
  //                 db.collection("users").doc(doc.id)
  //                 .collection('modules').doc(moduleDocRef.id)
  //                 .update(
  //                     {
  //                         description: {
  //                             0: {"header": "Instructions", "body": "You can complete the 6 Topics in the Academic Integrity module in any order. Please complete the Survey when you begin and the brief Post-survey when you finish the module.<br/><br/>Topic 6 includes resources you can use for further learning. You need to complete all activities in each Topic and the brief Post-survey to receive a Certificate of Completion."},
  //                             1: {"header": "Why is academic integrity important?", "body": "Academic integrity is central to academic work at the University of Toronto and other academic institutions across the world.  It is often described as a scholarly practice based on justice and fairness. For students who do not uphold this concept of fairness, there can be serious academic consequences. For example, while it is important to refer to the research of others in your own writing, not properly acknowledging the work of others can constitute plagiarism which is a serious offence in academic work."},
  //                             2: {"header": "What will I learn in this Academic Integrity learning module?", "body": "This module will introduce you to some of the common scenarios in academic work where academic integrity needs to be maintained. There are six topics in the module that focus on academic style, citations, evaluating sources, and groupwork. The modules contain informative videos, short practice activities, and opportunities for you to reflect on what you have learned. We hope you find these academic integrity topics informative and useful. Finally, we strongly recommend that you use an up-to-date academic style guide to keep yourself current with the academic style used in your discipline of study."},
  //                             3: {"header": "How can my learning in this module be recognized?", "body": "When you complete the six topics, you will be re-directed back to this page where you can download a completion certificate."}
  //                         },
  //                     }
  //                 )
  //             }
  //         });
  //         return "Added module to user"
  //     }).catch(function(err){
  //         console.log(err)
  //         return err
  //     })
  //    //return "SUCCESS";
  // })
  // .catch(function(err){
  //     return err;
  // });
}

/**
 * Return true if the module data is valid.
 * @param {*} moduleData
 */
export function validModuleData(moduleData) {
  if (!validateModuleFields(moduleData)) {
    return false;
  } // Return false if fields are invalid
  if (!validModuleType(moduleData.type)) {
    return false;
  } // Return false if the invalid module type
  return true;
}

/**
 * Function to validate the format of the object with
 * module data. If the format of the moduleData is as the required
 * format then return true or return false.
 *
 * @param {*} moduleData | Module data parameters
 */
function validateModuleFields(moduleData) {
  let moduleFields = [
    "title",
    "description",
    "type",
    "userState",
    "creationTime",
    "subModules",
  ]; // Required fields for module
  let subModuleFields = ["subModuleTitle", "content"]; // Required fields for sub module
  // let sectionFields = ['sectionTitle']; // Required fields for section
  // let subSectionFields = []; // Required fields for sub section
  // Fields of different subsection types
  // let textSubSection = [];
  // let videoSubSection = [];
  // let quizSubSection = [];
  var validate = true;
  // Check the fields of the module object
  moduleFields.map(function (moduleField) {
    if (moduleData[moduleField] === undefined) {
      validate = false;
    }
  });
  // Check fields of each subModule object
  moduleData.subModules.map(function (subModuleData) {
    subModuleFields.map(function (subModuleField) {
      if (subModuleData[subModuleField] === undefined) {
        validate = false;
      }
    });
  });
  return validate;
}

/**
 * Returns true if the given module type is valid.
 * @param {*} moduleType
 */
function validModuleType(moduleType) {
  let moduleTypeList = ["knowledge", "oral", "written"];
  let validModuleType = false;
  moduleTypeList.map((value) => {
    if (value === moduleType) {
      validModuleType = true;
    }
  });
  return validModuleType;
}

export const prepareModuleTemplate = (moduleData) => {
  let moduleTemplate = {};
  try {
    const moduleDataString = JSON.stringify(moduleData);
    // Count number of questions
    var countQues = (moduleDataString.match(/"response"/g) || []).length;

    moduleTemplate["title"] = moduleData.title;
    moduleTemplate["description"] = moduleData.description;
    if (moduleData.instructions !== undefined)
      moduleTemplate["instructions"] = moduleData.instructions;
    moduleTemplate["type"] = moduleData.type;
    moduleTemplate["subModules"] = moduleData.subModules;
    moduleTemplate["createdAt"] = new Date();
    moduleTemplate["userState"] = {
      active: true,
      progress: 0,
      subModulesCompleted: 0,
      totalQuestions: countQues,
    };

    for (let i = 0; i < moduleData.subModules.length; i++) {
      moduleTemplate.subModules[i] = prepareSubModuleTemplate(
        moduleData.subModules[i],
        i
      );
    }
    return moduleTemplate;
  } catch (error) {
    console.log(error);
    return moduleTemplate;
  }
};

const prepareSubModuleTemplate = (subModuleData) => {
  let subModuleTemplate = {};
  try {
    subModuleTemplate["subModuleTitle"] = subModuleData.subModuleTitle;
    subModuleTemplate["content"] = subModuleData.content;
    subModuleTemplate["userState"] = {
      locked: false,
      //locked: (index > 0)? true : false,
      progress: 0,
    };

    for (let i = 0; i < Object.keys(subModuleData.content).length; i++) {
      subModuleTemplate["content"][i] = prepareSectionTemplate(
        subModuleData.content[i.toString(10)],
        i
      );
    }
    return subModuleTemplate;
  } catch (error) {
    console.log(error);
    return subModuleTemplate;
  }
};

const prepareSectionTemplate = (sectionData, index) => {
  sectionData["userState"] = {
    //locked: false,
    locked: index > 0 ? true : false,
    completed: false,
  };
  delete sectionData.sectionIndex;
  delete sectionData.subModuleIndex;
  delete sectionData.numSubSections;
  delete sectionData.tempSubSections;

  for (let i = 0; i < Object.entries(sectionData).length - 2; i++) {
    if (sectionData[i]) {
      sectionData[i] = prepareSubSectionTemplate(sectionData[i]);
    }
  }
  return sectionData;
};

const prepareSubSectionTemplate = (subSectionData) => {
  if (subSectionData.type === "text") {
    return prepareTextTemplate(subSectionData);
  }
  if (subSectionData.type === "video") {
    return prepareVideoTemplate(subSectionData);
  }
  if (subSectionData.type === "quiz") {
    return prepareQuizTemplate(subSectionData);
  }
  if (subSectionData.type === "imgLink") {
    return prepareImgLinkTemplate(subSectionData);
  } else {
    return prepareImgLinkTemplate(subSectionData);
  }
};

const prepareTextTemplate = (subSectionData) => {
  return subSectionData;
};

const prepareVideoTemplate = (subSectionData) => {
  return subSectionData;
};

const prepareQuizTemplate = (subSectionData) => {
  return subSectionData;
};

const prepareImgLinkTemplate = (subSectionData) => {
  return subSectionData;
};
