import React, { Component } from "react";
import faqDocs from '../../../static/docs/faq.json';
import { Container, Row, Col } from 'react-bootstrap';
import {Typography, Grid, Card, Box, Table, TableRow, TableCell} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = theme => ({
    cell: {
        height: '100%'
    },
    card: {
        display: 'flex',
        padding: '2rem',
        textAlign: 'center',
        justifyContent: 'center',
        marginBottom: '2rem',
        alignItems:'center',
        background: '#42425D',
        color: 'white'
    },
    cardDesc: {
        padding: '2rem',
        marginLeft: '2rem',
        marginBottom: '2rem',
        backgroundImage: 'linear-gradient(to right, #dfdfdf , white)'
    },
    container: {
    },
    row: {
        '& + &': {
            marginTop: '3rem'
        }
    }
  });
class WrittenResources extends Component {

    createHeading(parentElement, headingType, headingContent) {
        var heading = document.createElement(headingType);
        heading.innerHTML = headingContent;
        parentElement.appendChild(heading);
    }

    createText(parentElement, textContent) {
        var text = document.createElement("p");
        text.innerHTML = textContent;
        parentElement.appendChild(text);
    }

    renderDocContent(doc) {
        var docItems = Object.entries(doc[1])
        var docContent = document.getElementById("docs-content")
        if (docContent) {
            docContent.innerHTML = "";
            // Create Heading
            this.createHeading(docContent, "h4", doc[0])
            // Insert Doc items
            docItems.map((item, index) => {
                if (item[0].search("inner") === -1) {
                    this.createHeading(docContent, "h6", item[0])
                    docContent.appendChild(document.createElement("hr"))
                }
                this.createText(docContent, item[1].text)
                return 0;
            })
        }
    }

    render() {
        const { classes } = this.props;

        return (
        <>
            <Container style={{paddingTop: '40px'}}>
                <Typography variant='h4'>Resources</Typography>
                <hr /> <br></br>
                <Grid container alignItems='stretch'>
                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Writing Support, Advice and Strategies</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                            <Typography variant='h6'><u>UNIVERSITY OF TORONTO: WRITING CENTRE</u></Typography>
                                <i><p><b>Writing at the University of Toronto</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://writing.utoronto.ca/">Website that contains  links to a multitude of university of Toronto resources, available to students based on campus and degree</a></p>
                            <Typography variant='h6'><u>UNIVERSITY OF TORONTO: WRITING CENTRE</u></Typography>
                                <i><p><b>Writing advice</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://advice.writing.utoronto.ca/">The advice files on this site answer the kinds of questions that University of Toronto students have about their written assignments. Most were created by writing instructors at U of T, familiar with U of T expectations.</a></p>
                            <Typography variant='h6'><u>UNIVERSITY OF TORONTO: OISE APHD</u></Typography>
                                <i><p><b>Writing papers 1: Planning {'&'} pre-writing phases</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 17 minutes</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: video lecture/slides that cover: understanding assignments; planning; refining the topic; focus on literature reviews</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/4a35d5fa807b346689a801a88743bbc2'>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1MY70rZDZMItWsvfYOwuc5X-knkqidbSc/view'>Slides (PDF)</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/15ElfeoqVjLlmCxi3H2hu4ArQiO8VLtdN/view'>Example of Literature Review Outline</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://docs.google.com/spreadsheets/d/1dEyKDtUB-Bi4MC0fNujtzlBJFOqDvn76/view">Example Literature Review Organization</a></p>

                                <i><p><b>Writing papers 2: Writing phase</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 14 minutes</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: video lecture/slides that cover: summarizing {'&'} paraphrasing; citing other works</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/c2fc34bc0362eee3d62e3a752928092a '>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1e0exSCqvjt9Izl-5zlPh-vTKDMKBSluI/view'>Slides (PDF)</a></p>
                                
                                <i><p><b>Academic Writing Strategies 3</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: video lecture/slides that cover: paragraph organization, metadiscourse, introductions</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/307c72e4939ee92aa505fe77e34c29ab'>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1k2MkOh1PTXufmVeWXAY-XRQCkthtt2ko/view'>Slides (PDF)</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/1h0niVLEUJONBXJhIIfJoxYuB8TowvLbA/view'>Activity on Introductions</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/1aExxEIwafYBTQ1dxgr60DihNoCCqnamR/view'>Activity on paragraph organization</a></p>
                                
                                <i><p><b>Academic Writing Strategies 4</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 24 minutes</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: video lecture/slides that cover: making claims and taking a stance on research</p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://play.library.utoronto.ca/play/d4796782fa257b1ed9c73217be2e07f0'>Video</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://drive.google.com/file/d/1H0kHDt2_9Qq2oQuUbWZt5coy-0RiOIdm/view'>Slides (PDF)</a></p>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href='https://docs.google.com/document/d/1EYENVYbEVhaL8BAhfBPs5vNBgX285Xm8/view'>Activity taking a stance</a></p>
                            
                                <Typography variant='h6'><u>UNIVERSITY OF TORONTO: ENGINEERING COMMUNICATION PROGRAM</u></Typography>
                                <i><p><b>The Writing Process</b></p></i>
                                <p><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://ecp.engineering.utoronto.ca/resources/online-handbook/the-writing-process/">Tips for managing the stages of the writing process, from strategic planning, outlining, drafting, to revision and editing.</a></p>

                                <Typography variant='h6'><u>UNIVERSITY OF TORONTO: SCHOOL OF GRADUATE STUDIES</u></Typography>
                                <i><p><b>Graduate Centre for Academic Communication</b></p></i>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a style={{padding:0}} target="_blank" rel="noopener noreferrer" href="https://www.sgs.utoronto.ca/resources-supports/gcac/">GCAC provides U of T graduate students with advanced training in academic writing and speaking.</a></p>

                                <Typography variant='h6'><u>ACADEMIC WRITING: A HANDBOOK FOR INTERNATIONAL STUDENTS</u></Typography>
                                <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.kau.edu.sa/files/0013287/subjects/academic-writing-handbook-international-students-3rd-ed%20(2).pdf">Free downloadable tutorial book for English language learners</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Phrasebanks</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                            <Typography variant='h6'><u>ENGLISH FOR WRITING RESEARCH PAPERS: USEFUL PHRASES </u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.springer.com/cda/content/document/cda_downloaddocument/Free+Download+-+Useful+Phrases.pdf?SGWID=0-0-45-1543172-p177775190">Free downloadable list of useful phrases for writing research papers.</a></p>
                            <Typography variant='h6'><u>UNIVERSITY OF MANCHESTER</u></Typography>
                            <i><p><b>Academic Phrasebank</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.phrasebank.manchester.ac.uk/">Academic Phrasebank that "aims to provide you with examples of some of the phraseological ‘nuts and bolts’ of writing organised according to the main sections of a research paper or dissertation".</a></p>
                            <Typography variant='h6'><u>REF-N-WRITE</u></Typography>
                            <i><p><b>Academic Phrasebank</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.ref-n-write.com/trial/academic-phrasebank/">Academic phrasebook for every section in an academic article and other types of academic writing.</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Vocabulary Support</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                            <Typography variant='h6'><u>JUST THE WORD</u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="http://www.just-the-word.com">Provides word frequency and common word combinations.</a></p>
                            <Typography variant='h6'><u>WORD SKETCH</u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://app.sketchengine.eu/#wordsketch?corpname=preloaded%2Faclarc_2">Online text analysis tool that works with large samples of language to identify typical/frequently used language vs. rare/outdated language.</a></p>
                            <Typography variant='h6'><u>SKELL</u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://skell.sketchengine.eu/"> Simple version of word sketch for English language learners.</a></p>
                            <Typography variant='h6'><u>THESAURUS.COM</u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.thesaurus.com/">Free online thesaurus; look up synonymns and antonyms for almost any word.</a></p>
                            <Typography variant='h6'><u>COLLINS DICTIONARY</u></Typography>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding:0}} href="https://www.collinsdictionary.com/dictionary/english">Instead of simply showing similar words, this dictionary provides the context in which the words are used, pronunciation video, etc.</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Reading and Note-taking</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                        <Typography variant='h6'><u>UNIVERSITY OF TORONTO: OISE APHD</u></Typography>
                        <i><p><b>Academic reading {'&'} note-taking strategies </b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 26 minutes</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: types of academic text; reading strategies; how to take notes</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://mymedia.library.utoronto.ca/play/c3b3941d7a21f5c634af5ef4f60e86cd'>Video</a></p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://drive.google.com/file/d/1KQf7M3lAVnOPYj3iRC9VnO9IF1iIQFOo/view'>Slides (PDF)</a></p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://drive.google.com/file/d/14rD2z7o1mTw1oDSwkvBVXst8piE01SjQ/view'>Purposes and order of reading academic articles (PDF)</a></p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://drive.google.com/file/d/1Ya_HKb3bcV47_EM2hfZFloh9lJOkYvvS/view'>Common Vocabulary in Academic Articles</a></p>
                        </Grid>

                        <Grid item xs={3} component={Card} className={classes.card}>
                            <Typography variant='h6'>Citations</Typography>
                        </Grid>
                        <Grid item xs={7} component={Card} className={classes.cardDesc}>
                        <Typography variant='h6'><u>UNIVERSITY OF TORONTO: OISE LIBRARY</u></Typography>
                        <i><p><b>Research Skills: How to search for sources and make citations</b></p></i>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Length: 1 hour 16 minutes</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}>Content: refining and improving a search; when and how to cite references</p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://play.library.utoronto.ca/play/3f89fe894a7835ebcc60a15c73e15f4f'>Video</a></p>
                            <p style={{marginLeft: "30px", marginRight: "50px"}}><a target="_blank" rel="noopener noreferrer" style={{padding: 0}} href='https://drive.google.com/file/d/15pNQ1JDlsXfc4t9M96pucTsfJCOGN0-x/view'>Slides (PDF)</a></p>
                        </Grid>
                </Grid>
            </Container>

            <Container style={{ 'padding-top': "40px" }}>
                <Typography>

            

                


                </Typography>
            </Container>
        </>
        );
    }
}

export default withStyles(styles)(WrittenResources);