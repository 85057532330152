const t4=    {
    "subModuleTitle": "How to Credit the Ideas of Others",
    "content": {
      "0": {
        "0": {
          "text": "In-text citations and reference lists are an integral part of incorporating source material (e.g., ideas, quoted text, etc. from other sources) into your own work. Depending on the reference style you are using, in-text citations normally include brief information about your source material such as author name and date of publication.  However, the in-text citation alone does not contain all of the source information.<br/><br/> The reference list is where you provide the full information about your source material. Remember that not following proper citation methods in your academic work can result in an academic offence such as plagiarism so it is important to follow proper referencing methods to complete your in-text citations. Always check that your in-text citations match your Reference list.",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "text"
        },
        "1": {
          "text": "In the following video, a student has unintentionally cited some sources inaccurately. Can you identify the problems his instructor has addressed in his paper?",
          "type": "text",
          "userState": { "viewed": false },
          "subSectionTitle": ""
        },
        "userState": { "completed": false, "locked": false },
        "sectionIcon": "info",
        "sectionIconTitle": "Introduction",
        "sectionTitle": "4.1. Introduction"
      },
      "1": {
        "0": {
          "subSectionTitle": "",
          "type": "text",
          "userState": { "viewed": false },
          "text": "Watch the video and identify the citation issues in this scenario."
        },
        "1": {
          "srcType": "url",
          "url": "videos/academicIntegrity/t4.mp4",
          "subSectionTitle": "",
          "userState": { "viewed": false },
          "type": "video"
        },
        "sectionIcon": "video",
        "sectionTitle": "4.2. Check Your Citations and References",
        "sectionIconTitle": "Citations and References",
        "userState": { "locked": true, "completed": false }
      },
      "2": {
        "0": {
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "text": "Now, let’s reflect on crediting the ideas of others by completing one Multiple Choice question and a True and False Quiz. You can watch the video again if you would like.",
          "type": "text"
        },
        "1": {
          "srcType": "url",
          "type": "video",
          "url": "videos/academicIntegrity/t4.mp4",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "2": {
          "type": "quiz",
          "subSectionTitle": "",
          "questions": [
            {
              "sectionComplete": true,
              "userState": { "response": [] },
              "qId": "0",
              "qText": "1. Why is adding a lot of quotations not the best writing strategy? ​Select the best option below:",
              "options": [
                {
                  "oId": "0",
                  "answer": false,
                  "text": "There is more chance to make citation errors.",
                  "exp": "Too many quotes in your work means that the reader is not reading your ideas.​"
                },
                {
                  "text": "The quotations may not be relevant to the topic.​",
                  "answer": false,
                  "exp": "Quotes may be relevant but too many quotes dilute the writer's ideas.",
                  "oId": "1"
                },
                {
                  "oId": "2",
                  "exp": "You should always use the most up to date version of your academic style (e.g. APA, MLA, Chicago, etc.).​",
                  "answer": false,
                  "text": "Academic style formats keep changing.​"
                },
                {
                  "answer": true,
                  "exp": "Quotations should mainly be used for emphasis.  Paraphrase is some disciplines is preferred to acknowledge the source of your knowledge or information.",
                  "text": "The writer's voice is less effective.",
                  "oId": "3"
                }
              ],
              "qType": "mcq"
            }
          ]
        },
        "3": {
          "type": "quiz",
          "subSectionTitle": "TRUE or FALSE: How can inaccurate citations reduce the credibility of scholarship? ​​",
          "questions": [
            {
              "userState": { "response": [] },
              "qId": "0",
              "options": [
                {
                  "exp": "TRUE- By misrepresenting the ideas of an author, the writer is not adequately acknowledging the contributions of the original author(s) in question​​",
                  "oId": "0",
                  "answer": true,
                  "text": "True"
                },
                {
                  "answer": false,
                  "text": "False",
                  "exp": "TRUE- By misrepresenting the ideas of an author, the writer is not adequately acknowledging the contributions of the original author(s) in question​​",
                  "oId": "1"
                }
              ],
              "qType": "mcq",
              "qText": "1. Inaccurate citations misrepresent the ideas of an author."
            }
          ]
        },
        "4": {
          "questions": [
            {
              "qId": "0",
              "options": [
                {
                  "text": "True",
                  "answer": true,
                  "exp": "TRUE-  Inaccurate citations bias journal and the original authors’ impact score, which have noteworthy consequences for both parties.​",
                  "oId": "0"
                },
                {
                  "answer": false,
                  "exp": "TRUE-  Inaccurate citations bias journal and the original authors’ impact score, which have noteworthy consequences for both parties.",
                  "text": "False",
                  "oId": "1"
                }
              ],
              "userState": { "response": [] },
              "qText": "2. Inaccurate citations negatively impact journal articles’ impact score (how popular an article is considered).​",
              "qType": "mcq"
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "5": {
          "questions": [
            {
              "qType": "mcq",
              "qId": "0",
              "options": [
                {
                  "exp": "TRUE-  The most predominant concern regarding incorrect citations is they cause authors to not get acknowledged for their hard work​",
                  "answer": true,
                  "oId": "0",
                  "text": "True"
                },
                {
                  "text": "False",
                  "oId": "1",
                  "exp": "TRUE-  The most predominant concern regarding incorrect citations is they cause authors to not get acknowledged for their hard work​",
                  "answer": false
                }
              ],
              "userState": { "response": [] },
              "qText": "3. Inaccurate citations cause authors to not get acknowledged for their hard work.​"
            }
          ],
          "type": "quiz",
          "subSectionTitle": ""
        },
        "6": {
          "type": "quiz",
          "questions": [
            {
              "options": [
                {
                  "answer": true,
                  "exp": "TRUE-   Inaccurate citations reduce the validity and strength of the writer’s claims.​",
                  "oId": "0",
                  "text": "True"
                },
                {
                  "text": "False",
                  "answer": false,
                  "oId": "1",
                  "exp": "TRUE-   Inaccurate citations reduce the validity and strength of the writer’s claims.​"
                }
              ],
              "qText": "4. Inaccurate citations reduce the validity of an author’s claims.​",
              "userState": { "response": [] },
              "qId": "0",
              "qType": "mcq",
              "sectionComplete": true
            }
          ],
          "subSectionTitle": ""
        },
        "sectionTitle": "4.3. Identify the Issues with Incorrect Citations",
        "sectionIconTitle": "Identify Incorrect Citations",
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "quiz"
      },
      "3": {
        "0": {
          "userState": { "viewed": false },
          "text": "In-text citations and reference list entries are similar but have different rules. Look at the punctuation differences between in-text citations and reference list entries in the examples written in APA 7 below.",
          "type": "text",
          "subSectionTitle": ""
        },
        "1": {
          "userState": { "viewed": false },
          "text": "<ul style=\"list-style-type:none;\">                <li><u>Reference list entry:</u></li></ul>",
          "type": "text",
          "subSectionTitle": ""
        },
        "2": {
          "type": "textIndent",
          "text": "<ul style=\"list-style-type:none;\">                <li>Weir, C., & Brooks, B. (2009). A cognitive processing approach towards defining listening comprehension. <i>TESOL Quarterly, 6</i>(3), 210-389. <u> https://doi.org/10.9102/00028312029003663</u></li>                </ul>",
          "userState": { "viewed": false },
          "subSectionTitle": ""
        },
        "3": {
          "type": "text",
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "text": "<ul style=\"list-style-type:none;\">                <li><u>Parenthetical in-text citation:</u></li><br/>                <li>(Weir & Brooks, 2009).</li>                </ul>"
        },
        "4": {
          "subSectionTitle": "",
          "type": "quiz",
          "questions": [
            {
              "qText": "Practice: Rewrite the reference list entry below as a parenthetical in text citation.<ind>Hinkel, T. R. & Templin, R. (2018). A brief tutorial on the development of measures in surveys. <i>Organizational Research Methods, 1</i>(1), 89-104. <u>https://doi.org/10.1197/109442819800100106</u> <ind>Write a parenthetical in-text citation below.",
              "qType": "open-res",
              "qId": "0",
              "sectionComplete": true,
              "feedback": "(Hinkel & Templin, 2018). \n Note: parenthetical in-text citations do not contain the author's initials. Include the author's initials in the reference list.​",
              "userState": { "response": [""] }
            }
          ]
        },
        "sectionTitle": "4.4. Reference List Style",
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Reference List Style",
        "sectionIcon": "comment"
      },
      "4": {
        "0": {
          "subSectionTitle": "",
          "type": "text",
          "text": "Many universities have a set of rules that govern the responsibilities of students and faculty members when producing academic work. At the University of Toronto, this is called the <b>Code of Behaviour on Academic Matters</b>. The Code describes a number of academic offences and one of these, plagiarism, is defined as:",
          "userState": { "viewed": false }
        },
        "1": {
          "userState": { "viewed": false },
          "text": "<i>1. It shall be an offence for a student knowingly:​\n(d) to represent as one’s own an idea or expression of an idea or work of another in any academic examination or term test or in connection with any other form of academic work, i.e. to commit plagiarism;​ <br/></i>​ <a href='https://www.academicintegrity.utoronto.ca/perils-and-pitfalls/improperly-cited-sources/' target='_blank' rel='noopener noreferrer'>https://www.academicintegrity.utoronto.ca/perils-and-pitfalls/improperly-cited-sources/ </a>",
          "type": "text",
          "subSectionTitle": ""
        },
        "2": {
          "userState": { "viewed": false },
          "type": "text",
          "text": "The purpose of using in-text citations and reference lists is to acknowledge your source materials to your reader. If your in-text citations and reference entries are not done properly (e.g. they do not match or do not refer to your actual source materials), you risk committing plagiarism and might receive a sanction (e.g. grade reduction, etc. ).  Always cross-reference your in-text citations with your reference list entries!",
          "subSectionTitle": ""
        },
        "sectionTitle": "4.5. Code of Behaviour on Academic Matters​",
        "sectionIconTitle": "Code of Behaviour",
        "userState": { "completed": false, "locked": true },
        "sectionIcon": "info"
      },
      "5": {
        "0": {
          "questions": [
            {
              "feedback": " There are several purposes of reference lists, but a key purpose is that reference lists contain the complete source information for your in-text citations and show the complete source material you used in your academic work. ​​",
              "userState": { "response": [""] },
              "topicComplete": true,
              "qId": "0",
              "qType": "open-res",
              "qText": "What do you think is the purpose of reference lists in academic work? ​",
              "sectionComplete": true
            }
          ],
          "type": "quiz",
          "subSectionTitle": "Reflecting on what you have learned in this topic, answer the question below.​​"
        },
        "sectionIcon": "comment",
        "sectionIconTitle": "Reflection",
        "sectionTitle": "4.6. Reflection",
        "userState": { "locked": true, "completed": false }
      }
    },
    "userState": { "locked": false, "progress": 0 }
  }
export default t4;