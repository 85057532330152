
import React from 'react';
import Container from 'react-bootstrap/Container';

export default function Footer(){
    return (
        <Container className="align-self-center footer-container stay-bottom">
            <hr/>
            <h3>© IDELA Lab at OISE. All Rights Reserved</h3>
            <br/>
        </Container>
    );
}