import React from "react";

import {
  ButtonGroup,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Card,
} from "@material-ui/core";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

class Timer extends React.Component {
  state = {
    timeCounter: 20,
    isTimerSet: false,
    timeTaken: 0
  };

  handleDecrement = () => {
    this.setState({ timeCounter: this.state.timeCounter - 1 });
  };

  handleIncrement = () => {
    this.setState({ timeCounter: this.state.timeCounter + 1 });
  };

  onTimerStart = () => {
    this.setState({ isTimerSet: true });
  };

  showDisplay(remainingTime) {
    if (this.state.timeTaken !== this.state.timeCounter * 60 - remainingTime) {
        this.setState({timeTaken:  this.state.timeCounter * 60 - remainingTime})
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          placeItems: "center",
        }}
      >
        <div style={{ fontSize: "20px" }}>
          {Math.floor(remainingTime / 60)} : {remainingTime % 60}
        </div>
        <div style={{ fontSize: "20px" }}>Minutes left</div>
      </div>
    );
  }

  render() {
    return (
      <>
        {!this.state.isTimerSet ? (
          <>
            <div>
              <div>Set Time (in minutes)</div>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
                style={{ marginBottom: "1rem", marginRight: "3rem" }}
              >
                <Button onClick={this.handleDecrement}>-</Button>
                <Button
                  disabled
                  style={{ backgroundColor: "white", color: "black" }}
                >
                  {this.state.timeCounter}
                </Button>
                <Button onClick={this.handleIncrement}>+</Button>
              </ButtonGroup>
            </div>

            <Button
              style={{
                background: "#7270a1",
                borderColor: "rgba(0, 32, 78)",
                marginRight: "10px",
                color: "white",
              }}
              onClick={this.onTimerStart}
            >
              Start Timer
            </Button>
          </>
        ) : (
          <>
            <CountdownCircleTimer
              isPlaying={this.state.isTimerSet}
              duration={this.state.timeCounter * 60}
              initialRemainingTime={this.state.timeCounter * 60}
              colors={[
                ["#004777", 0.33],
                ["#F7B801", 0.33],
                ["#A30000", 0.33],
              ]}
            >
              {({ remainingTime }) => {
                return this.showDisplay(remainingTime)
              }
              }
            </CountdownCircleTimer>
            <Button
              style={{
                background: "#7270a1",
                borderColor: "rgba(0, 32, 78)",
                marginRight: "10px",
                color: "white",
              }}
              onClick={() => this.props.onFinish(this.state.timeTaken)}
            >
              Finished
            </Button>
          </>
        )}
      </>
    );
  }
}

export default Timer;
