import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { addModuleToDb } from "./dbHelpers.js";
import { Container, Row, Col } from "react-bootstrap";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Radio,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

class ContentBuilder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loopActive: false,
      shuffleActive: false,
    };

    this.addSubModuleInfo = this.addSubModuleInfo.bind(this);
    this.addSectionTitle = this.addSectionTitle.bind(this);
    this.handleTextSubSectionData = this.handleTextSubSectionData.bind(this);
    this.handleTextSave = this.handleTextSave.bind(this);
    this.handleVideoSubSectionData = this.handleVideoSubSectionData.bind(this);
    this.handleVideoSave = this.handleVideoSave.bind(this);
    this.handleQuestionSubSectionData =
      this.handleQuestionSubSectionData.bind(this);
    this.renderQuestionBody = this.renderQuestionBody.bind(this);
    this.appendQuestionBox = this.appendQuestionBox.bind(this);
    this.handleQuizSave = this.handleQuizSave.bind(this);
  }

  moduleData = {
    title: "",
    description: "",
    type: "",
    subModules: [],
    numSubModuleContainers: 0,
  };

  // State Update Actions
  addSubModule(subModuleData) {
    console.log(this.moduleData);
  }

  addModuleInfo = (e) => {
    this.moduleData[e.target.id] = e.target.value;
  };

  addModuleType = (e) => {
    this.moduleData.type = e.target.value;
  };

  submitModule() {
    console.log(addModuleToDb(this.moduleData));
  }

  renderQuestionBody(
    subModuleIndex,
    sectionIndex,
    subSectionIndex,
    questionIndex,
    questionType
  ) {
    let quizId =
      "question-container-sub-mod-" +
      subModuleIndex +
      "-sec-" +
      sectionIndex +
      "-sub-section-" +
      subSectionIndex +
      "-q-" +
      questionIndex;
    const div = document.createElement("div");
    let component = document.getElementById(
      "question-body-sub-mod-" +
        subModuleIndex +
        "-sec-" +
        sectionIndex +
        "-sub-section-" +
        subSectionIndex +
        "-q-" +
        questionIndex
    );
    component.innerHTML = "";
    if (questionType === "open-res") {
      component.className = "Col";
      if (component.childElementCount === 0) {
        ReactDOM.render(
          <Container fluid>
            <br />
            <TextField
              fullWidth
              id={"j"}
              label="Question Feedback"
              variant="outlined"
              onChange={"k"}
            />
          </Container>,
          component.appendChild(div)
        );
      }
    }
    if (questionType === "mcq" || questionType === "mca") {
      component.className = "Col";
      let qOption = (
        <div>
          <br></br>
          <Row>
            <Col xs={1}>
              <Radio
                // checked={selectedValue === 'd'}
                // onChange={handleChange}
                value="d"
                color="default"
                name="radio-button-demo"
                inputProps={{ "aria-label": "D" }}
              />
            </Col>
            <Col>
              <TextField
                fullWidth
                id={"j"}
                label="Option"
                variant="outlined"
                onChange={"k"}
              />
            </Col>
            <Col>
              <TextField
                fullWidth
                id={"j"}
                label="Explanation"
                variant="outlined"
                onChange={"k"}
              />
            </Col>
          </Row>
          <br></br>
        </div>
      );
      if (component.childElementCount === 0) {
        ReactDOM.render(
          <div>
            <Container id={"mcq-options" + quizId}>
              <br />
              {qOption}
            </Container>
            <Button
              style={{ float: "right" }}
              id="add-topic-button"
              onClick={() => {
                this.copyComponent(
                  document.getElementById("mcq-options" + quizId),
                  qOption
                );
              }}
              variant="contained"
              color="primary"
            >
              Options <AddIcon />
            </Button>
            <br />
            <br />
          </div>,
          component.appendChild(div)
        );
      }
    }
  }

  handleQuestionSubSectionData(e) {
    try {
      let id = "";
      if (e.target.name) {
        id = e.target.name;
      }
      if (e.target.id) {
        id = e.target.id;
      }
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("question-type-sub-mod-") +
            "question-type-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sub-section-") + "-sub-section-".length,
          id.indexOf("-q-")
        )
      );
      let questionIndex = parseInt(
        id.substring(id.lastIndexOf("-q-") + "-q-".length)
      );
      if (e.target.name && e.target.name.includes("question-type-")) {
        this.renderQuestionBody(
          subModuleIndex,
          sectionIndex,
          subSectionIndex,
          questionIndex,
          e.target.value
        );
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex.toString()].questions[questionIndex].qType =
          e.target.value;
      }
      if (e.target.id && e.target.id.includes("question-text-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex.toString()].questions[questionIndex].qText =
          e.target.value;
      }
    } catch (error) {
      console.log({ function: "handleQuestionSubSectionData", error: error });
    }
  }

  appendQuestionBox(
    subModuleIndex,
    sectionIndex,
    subSectionIndex,
    questionIndex
  ) {
    let question = {
      qId: questionIndex.toString(),
      qText: "",
      qType: "",
    };
    try {
      this.moduleData.subModules[subModuleIndex].content[sectionIndex][
        "tempSubSections"
      ][subSectionIndex.toString()].questions.push(question);
      this.moduleData.subModules[subModuleIndex].content[sectionIndex][
        "tempSubSections"
      ][subSectionIndex].numQuestions++;
      let subSectionDiv = document.getElementById(
        "question-container-sub-mod-" +
          subModuleIndex +
          "-sec-" +
          sectionIndex +
          "-sub-section-" +
          subSectionIndex
      );
      const div = document.createElement("div");
      ReactDOM.render(
        <Row
          fluid
          id={"question-container" + subSectionIndex + "-q-" + questionIndex}
          style={{ backgroundColor: "#96bb7c", padding: "10px" }}
        >
          <Col>
            <Row>
              <Col>
                <TextField
                  fullWidth
                  id={
                    "question-text" +
                    "-sub-mod-" +
                    subModuleIndex +
                    "-sec-" +
                    sectionIndex +
                    "-sub-section-" +
                    subSectionIndex +
                    "-q-" +
                    questionIndex
                  }
                  label={"Question " + (questionIndex + 1)}
                  variant="standard"
                  onChange={this.handleQuestionSubSectionData}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormControl>
                  <InputLabel
                    name={
                      "question-type" +
                      "-sub-mod-" +
                      subModuleIndex +
                      "-sec-" +
                      sectionIndex +
                      "-sub-section-" +
                      subSectionIndex +
                      "-q-" +
                      questionIndex
                    }
                  >
                    Question Type
                  </InputLabel>
                  <Select
                    name={
                      "question-type" +
                      "-sub-mod-" +
                      subModuleIndex +
                      "-sec-" +
                      sectionIndex +
                      "-sub-section-" +
                      subSectionIndex +
                      "-q-" +
                      questionIndex
                    }
                    style={{ minWidth: "130px" }}
                    onChange={this.handleQuestionSubSectionData}
                  >
                    <MenuItem value={"mcq"}>Multiple Choice</MenuItem>
                    <MenuItem value={"mca"}>Multiple Answer</MenuItem>
                    <MenuItem value={"open-res"}>Open Response</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            </Row>
            <Row
              id={
                "question-body-sub-mod-" +
                subModuleIndex +
                "-sec-" +
                sectionIndex +
                "-sub-section-" +
                subSectionIndex +
                "-q-" +
                questionIndex
              }
            ></Row>
          </Col>
        </Row>,
        subSectionDiv.appendChild(div)
      );
    } catch (error) {
      console.log(error);
    }
  }

  copyComponent(parentComponent, component) {
    const div = document.createElement("div");
    ReactDOM.render(component, parentComponent.appendChild(div));
    return component;
  }

  handleTextSubSectionData(e) {
    try {
      let id = e.target.id;
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("content-sub-mod-") + "content-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(id.lastIndexOf("-sub-section-") + "-sub-section-".length)
      );
      if (e.target.id.includes("text-title-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex]["subSectionTitle"] = e.target.value;
      }
      if (e.target.id.includes("text-body-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex]["text"] = e.target.value;
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleTextSave(e) {
    try {
      let id = e.target.id;
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("text-button-content-sub-mod-") +
            "text-button-content-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(id.lastIndexOf("-sub-section-") + "-sub-section-".length)
      );
      this.moduleData.subModules[subModuleIndex].content[sectionIndex][
        subSectionIndex
      ] =
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex];
    } catch (error) {
      console.log(error);
    }
  }

  handleVideoSubSectionData(e) {
    try {
      let id = e.target.id;
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("content-sub-mod-") + "content-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(id.lastIndexOf("-sub-section-") + "-sub-section-".length)
      );
      if (e.target.id.includes("video-title-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex]["subSectionTitle"] = e.target.value;
      }
      if (e.target.id.includes("video-url-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex]["url"] = e.target.value;
      }
      if (e.target.id.includes("video-srcType-")) {
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex]["srcType"] = e.target.value;
      }
    } catch (error) {
      console.log(error);
    }
  }

  handleVideoSave(e) {
    try {
      let id = e.target.id;
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("video-button-content-sub-mod-") +
            "text-button-content-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(id.lastIndexOf("-sub-section-") + "-sub-section-".length)
      );

      this.moduleData.subModules[subModuleIndex].content[sectionIndex][
        subSectionIndex
      ] =
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex];
    } catch (error) {
      console.log(error);
    }
  }

  handleQuizSave(e) {
    try {
      let id = e.target.id;
      let subModuleIndex = parseInt(
        id.substring(
          id.lastIndexOf("quiz-button-content-sub-mod-") +
            "text-button-content-sub-mod-".length,
          id.indexOf("-sec-")
        )
      );
      let sectionIndex = parseInt(
        id.substring(
          id.lastIndexOf("-sec-") + "-sec-".length,
          id.indexOf("-sub-section-")
        )
      );
      let subSectionIndex = parseInt(
        id.substring(id.lastIndexOf("-sub-section-") + "-sub-section-".length)
      );
      this.moduleData.subModules[subModuleIndex].content[sectionIndex][
        subSectionIndex
      ] =
        this.moduleData.subModules[subModuleIndex].content[sectionIndex][
          "tempSubSections"
        ][subSectionIndex];
    } catch (error) {
      console.log(error);
    }
  }

  // DOM Render Functions
  appendSubSectionInfoBox(subModuleIndex, sectionIndex, contentType) {
    let subSectionIndex =
      this.moduleData.subModules[subModuleIndex].content[sectionIndex]
        .numSubSections;
    let subSection = {
      subSectionTitle: "",
      subSectionType: contentType,
      subSectionIndex: subSectionIndex,
    };

    if (contentType === "text") {
      subSection["text"] = "";
    }
    if (contentType === "video") {
      subSection["srcType"] = "";
      subSection["url"] = "";
    }
    if (contentType === "quiz") {
      subSection["questions"] = [];
      subSection["numQuestions"] = 0;
    }
    this.moduleData.subModules[subModuleIndex].content[sectionIndex][
      "tempSubSections"
    ][subSectionIndex] = subSection;
    this.moduleData.subModules[subModuleIndex].content[sectionIndex]
      .numSubSections++;
    try {
      let componentId =
        "content-sub-mod-" + subModuleIndex + "-sec-" + sectionIndex;
      var component = document.getElementById(componentId);
      const div = document.createElement("div");
      let subSectionId = componentId + "-sub-section-" + subSectionIndex;
      ReactDOM.render(
        <div>
          {contentType === "text" && (
            <Container>
              <hr />
              <Row>Text</Row>
              <br></br>
              <Row>
                <TextField
                  fullWidth
                  id={"text-title-" + subSectionId}
                  label="Text Title"
                  variant="outlined"
                  onChange={this.handleTextSubSectionData}
                />
              </Row>
              <br />
              <Row>
                <TextField
                  fullWidth
                  id={"text-body-" + subSectionId}
                  label="Text Body"
                  variant="outlined"
                  onChange={this.handleTextSubSectionData}
                />
              </Row>
              <br />
              <Row className="justify-content-end">
                <Button
                  style={{ float: "right" }}
                  id={"text-button-" + subSectionId}
                  onClick={(e) => {
                    this.handleTextSave(e);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Row>
              <hr />
            </Container>
          )}
          {contentType === "video" && (
            <Container>
              <hr />
              <Row>Video</Row>
              <br></br>
              <Row>
                <Col>
                  <TextField
                    fullWidth
                    id={"video-title-" + subSectionId}
                    label="Video Title"
                    variant="outlined"
                    onChange={this.handleVideoSubSectionData}
                  />
                </Col>
                <Col>
                  <TextField
                    fullWidth
                    id={"video-srcType-" + subSectionId}
                    label="url/storage"
                    variant="outlined"
                    onChange={this.handleVideoSubSectionData}
                  />
                </Col>
              </Row>
              <br></br>
              <Row>
                <Col>
                  <TextField
                    fullWidth
                    id={"video-url-" + subSectionId}
                    label="Video URL"
                    variant="outlined"
                    onChange={this.handleVideoSubSectionData}
                  />
                </Col>
              </Row>
              <br></br>
              <Row className="justify-content-end">
                <Button
                  style={{ float: "right" }}
                  id={"video-button-" + subSectionId}
                  onClick={(e) => {
                    this.handleVideoSave(e);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Row>
              <hr />
            </Container>
          )}
          {contentType === "quiz" && (
            <Container fluid>
              <hr />
              <Row>Quiz</Row>
              <br></br>
              <Row>
                {" "}
                <Col
                  id={
                    "question-container-sub-mod-" +
                    subModuleIndex +
                    "-sec-" +
                    sectionIndex +
                    "-sub-section-" +
                    subSectionIndex
                  }
                ></Col>
              </Row>
              <Row>
                Press the Question + button to add questions to the quiz
              </Row>
              <br />
              <Row>
                <Button
                  id="add-topic-button"
                  onClick={() => {
                    this.appendQuestionBox(
                      subModuleIndex,
                      sectionIndex,
                      subSectionIndex,
                      subSection.numQuestions
                    );
                  }}
                  variant="contained"
                  color="primary"
                >
                  Question <AddIcon />
                </Button>
              </Row>
              <Row className="justify-content-end">
                <Button
                  style={{ float: "right" }}
                  id={"quiz-button-" + subSectionId}
                  onClick={(e) => {
                    this.handleQuizSave(e);
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Row>
              <hr />
            </Container>
          )}
        </div>,
        component.appendChild(div)
      );
    } catch (err) {
      console.log(err);
    }
  }
  /**
   * Render a box for adding section data for the given sub-module index.
   * Function creates a section template for sub-module with subModuleIndex.
   * @param {String} elementIdPrefix | Prefix for id for section box for given
   * submodele index.
   * @param {Integer} subModuleIndex | Index of the submodule to which section
   * is to be added.
   */
  appendSectionInfoBox(elementIdPrefix, subModuleIndex) {
    let numSections = this.moduleData.subModules[subModuleIndex].numSections;
    this.moduleData.subModules[subModuleIndex].content[numSections] = {
      sectionTitle: "",
      sectionIndex: numSections,
      subModuleIndex: subModuleIndex,
      numSubSections: 0,
      tempSubSections: {},
    };
    let sectionIndex = numSections;
    this.moduleData.subModules[subModuleIndex].numSections++;

    try {
      var component = document.getElementById(elementIdPrefix + subModuleIndex);
      const div = document.createElement("div");
      ReactDOM.render(
        <div>
          <hr />
          <Row style={{ padding: "10px" }}>
            <h6>Section {sectionIndex + 1}</h6>
          </Row>
          <Row>
            <Col>
              <TextField
                id={"title-sub-mod-" + subModuleIndex + "-sec-" + sectionIndex}
                label="Section Title"
                variant="outlined"
                onChange={this.addSectionTitle}
              />
            </Col>
          </Row>
          <Row>
            <br />
            <Container
              id={"content-sub-mod-" + subModuleIndex + "-sec-" + sectionIndex}
            ></Container>
          </Row>
          <Row>
            <Button
              id="add-topic-button"
              onClick={() => {
                this.appendSubSectionInfoBox(
                  subModuleIndex,
                  sectionIndex,
                  "text"
                );
              }}
              variant="contained"
              color="primary"
            >
              Text <AddIcon />
            </Button>
            <Button
              id="add-topic-button"
              onClick={() => {
                this.appendSubSectionInfoBox(
                  subModuleIndex,
                  sectionIndex,
                  "video"
                );
              }}
              variant="contained"
              color="primary"
            >
              Video <AddIcon />
            </Button>
            <Button
              id="add-topic-button"
              onClick={() => {
                this.appendSubSectionInfoBox(
                  subModuleIndex,
                  sectionIndex,
                  "quiz"
                );
              }}
              variant="contained"
              color="primary"
            >
              Quiz <AddIcon />
            </Button>
          </Row>
          <hr />
        </div>,
        component.appendChild(div)
      );
    } catch (err) {
      console.log(err);
    }
  }

  addSectionTitle(e) {
    let id = e.target.id;
    let subModuleIndex = parseInt(
      id.substring(
        id.lastIndexOf("title-sub-mod-") + "title-sub-mod-".length,
        id.indexOf("-sec-")
      )
    );
    let sectionIndex = parseInt(
      id.substring(id.lastIndexOf("-sec-") + "-sec-".length)
    );
    this.moduleData.subModules[subModuleIndex].content[
      sectionIndex
    ].sectionTitle = e.target.value;
  }

  addSubModuleInfo(e) {
    try {
      let idPrefix = "title-sub-module-";
      let subModuleIndex = e.target.id.substring(idPrefix.length);
      console.log(subModuleIndex);
      this.moduleData.subModules[subModuleIndex].subModuleTitle =
        e.target.value;
    } catch (err) {
      console.log(err);
    }
  }

  /**
   * Adds a sub module information form to container with id sub-module-info-box
   * in the module information forms.
   * Function adds a new sub module box to the module info component and adds a
   * corresponding object to the subModules array in this.moduleData.
   */
  appendSubModuleInfoBox() {
    let subModuleData = {
      subModuleTitle: "",
      content: {},
      numSections: 0,
      index: this.moduleData.numSubModuleContainers,
    };

    this.moduleData.numSubModuleContainers++;
    this.moduleData.subModules.push(subModuleData); // Append the initial state of the sub module to array.

    // Sub module component
    var component = document.getElementById("sub-module-info-box");
    const div = document.createElement("div");

    ReactDOM.render(
      <div>
        <Container
          id={"sub-mod-" + this.moduleData.numSubModuleContainers}
          fluid
          className="border"
        >
          <Row
            style={{
              padding: "10px",
              "background-color": "rgba(67, 63, 191, 0.2)",
            }}
          >
            <h4>Topic {this.moduleData.numSubModuleContainers}</h4>
          </Row>
          <br />
          <Row>
            <Col sm={2} style={{ marginTop: "10px" }}>
              <h5>Topic Title</h5>
            </Col>
            <Col>
              <TextField
                id={"title-sub-module-" + subModuleData.index}
                label="Title"
                variant="outlined"
                onChange={this.addSubModuleInfo}
              />
            </Col>
          </Row>
          <br></br>
          <Row style={{ "padding-left": "15px", "padding-right": "15px" }}>
            <h5>Content</h5>
            <Container
              id={"sec-box-sub-mod-" + subModuleData.index}
            ></Container>
            <Button
              fullWidth
              onClick={() => {
                this.appendSectionInfoBox(
                  "sec-box-sub-mod-",
                  subModuleData.index
                );
              }}
              variant="contained"
              disabled={
                this.moduleData.subModules[subModuleData.index] === undefined
                  ? true
                  : false
              }
              color="primary"
            >
              Section <AddIcon />
            </Button>
          </Row>
          <br />
          <Row style={{ "padding-left": "10px" }}>
            <Button
              id="add-topic-button"
              onClick={() => {
                this.addSubModule(subModuleData);
              }}
              variant="contained"
              color="primary"
            >
              Add Topic
            </Button>
          </Row>
          <br />
        </Container>
        <br />
      </div>,
      component.appendChild(div)
    );
  }
  /**
   * Pushes a module information form with a title and description input fields,
   * module type form menu item and two buttons for submitting module data to the
   * database and one button to add a sub-module information form to the UI.
   *
   * @param {*} component
   */
  renderModuleInfoBox = (component) => {
    const div = document.createElement("div");

    try {
      ReactDOM.render(
        <Container>
          <br />
          <Row>
            <Col sm={3}>
              <h4>Module Title</h4>
            </Col>
            <Col>
              <TextField
                id="title"
                label="Title"
                variant="outlined"
                onChange={this.addModuleInfo}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col sm={3}>
              <h4>Module Description</h4>
            </Col>
            <Col>
              <TextField
                id="description"
                label="Description"
                fullWidth
                variant="outlined"
                onChange={this.addModuleInfo}
              />
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col sm={3}>
              <h4>Module Type</h4>
            </Col>
            <Col>
              <FormControl>
                <InputLabel name="type">Module Type</InputLabel>
                <Select
                  name="module-type-select"
                  style={{ minWidth: "120px" }}
                  onChange={this.addModuleType}
                >
                  <MenuItem value={"knowledge"}>Knowledge</MenuItem>
                  <MenuItem value={"oral"}>Oral</MenuItem>
                  <MenuItem value={"written"}>Written</MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>
          <br />
          <Container id="sub-module-info-box"></Container>
          <Button
            fullWidth
            size="large"
            onClick={() => {
              this.appendSubModuleInfoBox();
            }}
            variant="outlined"
            color="primary"
          >
            Create Topic
          </Button>
          <br />
          <br />
          <Button
            fullWidth
            size="large"
            onClick={() => {
              this.submitModule();
            }}
            color="primary"
          >
            Submit Module
          </Button>
        </Container>,
        component.appendChild(div)
      );
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * Render the module information box when the page loads.
   */
  componentDidMount() {
    let component = document.getElementById("module-info-box");
    this.renderModuleInfoBox(component);
  }

  render() {
    return (
      <Container fluid id="module-info-box">
        <h1>Learning Module</h1>
        <hr />
      </Container>
    );
  }
}

export default connect(null, null)(ContentBuilder);
