
var firebaseConfig = {
    apiKey: "AIzaSyDMO4lEEpD48qMw3XoKcjRiXzPVYzzNtwU",
    authDomain: "issd-65400.firebaseapp.com",
    databaseURL: "https://issd-65400.firebaseio.com",
    projectId: "issd-65400",
    storageBucket: "issd-65400.appspot.com",
    messagingSenderId: "103438613175",
    appId: "1:103438613175:web:a330bffe05ddb39b582b32",
    measurementId: "G-7FKLTTWW1V"
  };
  
export default firebaseConfig;
