import React, { Component } from 'react';
import { Container, Badge } from 'react-bootstrap';
import './ModuleStatusList.css'
import LabelImportantRoundedIcon from '@material-ui/icons/LabelImportantRounded';
import Switch from '@material-ui/core/Switch';
import Grow from '@material-ui/core/Grow';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import Card from 'react-bootstrap/Card'

class ToDoList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            checked: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.renderEachModule = this.renderEachModule.bind(this);
        this.renderEachTopic= this.renderEachTopic.bind(this)

    }

    handleChange() {
        let temp = this.state.checked;
        this.setState({
            checked: !temp
        });
    }
    renderEachTopic(topics){
        var result=[]
        if (topics !== undefined){
            topics.forEach(element => {
                result.push(<p>{element}</p>)
            }
            )
            return result
        }
        else{
            result.push(<p>Topic Placeholder</p>)
        }
        
        return result 
        
    }

    renderEachModule(item, index) {
        return (
            <Grow in={this.state.checked} className="grow-container" key={index}>
                <Card style={{ 'width': '100%' }} key={index}>
                    <Card.Header className="action-component-title">
                        <LabelImportantRoundedIcon />
                        <h4 className="mr-auto">{item[1].title}</h4>
                        <small>11 mins ago</small>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            {this.renderEachTopic(item[1].topics)}
                         </Card.Text>
                    </Card.Body>
                </Card>
            </Grow>
        )


    }



    render() {
        const actionItems = Object.entries(this.props.actionItems)
        return (
            <div className="action-container">
                {/* <Container fluid>
                    <h3>Action Items <Badge variant="secondary">{actionItems.length}</Badge></h3>
                    <div className="overflow-auto" style={{ "padding": "20px", "background-color": "rgba(67, 63, 191, 0.2)", 'border-radius': '20px' }}>
                        {actionItems.map((item, index) =>

                            <Toast style={{ 'width': '100%' }} key={index}>
                                <Toast.Header>
                                    <LabelImportantRoundedIcon />
                                    <h4 className="mr-auto">{item[1].title}</h4>
                                    <small>11 mins ago</small>
                                </Toast.Header>
                                <Toast.Body>Hello, world! This is a toast message.</Toast.Body>
                            </Toast>
                        )}
                    </div>
                </Container> */}
                {/* <Container fluid>
                    <GrowElement />
                </Container>
 */}

                <Container fluid>
                    <FormControlLabel
                        control={<Switch checked={this.state.checked} onChange={this.handleChange} className="action-toggle-button" />}
                        label={
                            <div className="action-label">Action Items 
                                <Badge variant="secondary" style={{"margin-left":'0.5rem'}}>{actionItems.length}</Badge>
                            </div>}

                        id='test1'
                        className="action-title"
                    />
                    <div>
                        {actionItems.map((item, index) => this.renderEachModule(item, index))}

                    </div>
                </Container>
            </div>

        );
    }
}

export default ToDoList;