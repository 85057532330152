import React from 'react'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import DashFrame from '../../../components/DashFrame/DashFrame'
import { wipeUserData, unlockModule} from '../../../components/Wrappers/ContentBuilder/dbHelpers'
import oralData from "../../../static/module/oral/final"
import writingData from "../../../static/module/writing/final"
import academicIntegrityData from "../../../static/module/academicIntegrity/final"
import { adminEmails } from '../../../config/adminEmails'
import { Typography, Container } from '@material-ui/core'

export default function AdminPanel() {
    const email = useSelector(state => state.firebase.auth.email)

    const header = (title) => <Typography variant='h5' style={{marginTop: '4rem', marginBottom: '1rem'}}>{title}</Typography>;
    const link = (text, to) => <a href={to} style={{padding: 0, marginRight: '2rem'}}><Button primary variant="outlined">{text}</Button></a>
    const button = (text, func) => <Button primary variant="outlined" onClick={func} style={{marginRight: '2rem'}}>{text}</Button>


    if (!adminEmails.includes(email)) return <h2>Unauthorized</h2>
    return (
        <DashFrame>
            <Container>
                {header('Unreleased Modules')}
                {link('Writing Module', '/written/WritingModule')}
                {link('Oral Module', '/oral/oral')}

                {header('Initial Login Pages')}
                {link('Demographic Form', '/demographic')}
                {link('Consent Form', '/consent')}
                {link('Welcome Page', '/admin/welcome')}

                {header('Module Actions')}
                {button('Wipe Academic Integrity Data', async e => await wipeUserData("AcademicIntegrity", academicIntegrityData, email))}
                {button('Wipe Oral Data', async e => await wipeUserData("oral", writingData, email))}
                {button('Wipe Written Data', async e => await wipeUserData("WritingModule", oralData, email))}<br/><br/>
                {button('Unlock All Modules', async e => await Promise.all([unlockModule("AcademicIntegrity", email), unlockModule("WritingModule", email), unlockModule("oral", email)]))}
                {button('Unlock Academic Integrity Module', async e => await unlockModule("AcademicIntegrity", email))}
                {button('Unlock Writing Module', async e => await unlockModule("WritingModule", email))}
                {button('Unlock Oral Module', async e => await unlockModule("oral", email))}
            </Container>
        </DashFrame>
    )
}