import React from "react";
const t1 = {
  subModuleTitle: "Critical Reflection",
  userState: { locked: false, completed: false },
  "content": {
    "0": {
      "0": {
        "srcType": "url",
        "type": "video",
        "url": "videos/written/t1.mp4",
        "userState": { "viewed": false },
        "subSectionTitle": ""
      },
      "1": {
        "userState": { "viewed": true },
        "text": "This written module will support you in learning how to write a critical reflection. You will be guided through the following tasks: \n      \n                <br></br>\n                <ul>\n                <li>Read an article</li>\n                \n                <li>Answer questions about the article content </li> \n                \n                <li>Ask some discussion questions </li>\n                \n                <li>Read aloud an excerpt from the article </li>\n                \n                <li>Explore your vocabulary knowledge </li> \n                \n                <li>Draft a critical reflection </li>\n                \n                <li>Do a self-assessment about your draft </li>\n                \n                <li>Finalize your draft </li>\n                \n                </ul>",
        "type": "text"
      },
      "sectionIcon": "bulb",
      "sectionIconTitle": "Intro",
      "userState": { "locked": false, "completed": false },
      "sectionTitle": "1.1 Introduction to Topic 1"
    },
    "1": {
      "0": {
        "userState": { "viewed": true },
        "url": ["pdf/Invention.pdf"],
        "type": "pdf",
        "subSectionTitle": ""
      },
      "sectionIconTitle": "Read",
      "sectionTitle": "1.2 Read an Article",
      "userState": { "locked": true, "completed": false },
      "sectionIcon": "info"
    },
    "2": {
      "0": {
        "userState": { "viewed": false },
        "text": "We hope you enjoyed reading the article. Please think about what you read and answer the following multiple-choice questions. Use your notes and the text from Topic 1.2 to help you. You will be provided with feedback after you’ve answered each question. Click on the <b>SUBMIT</b> button when you are finished.",
        "subSectionTitle": "",
        "type": "text"
      },
      "1": {
        "userState": { "response": [] },
        "type": "mcqWithHints",
        "subSectionTitle": "",
        "questionData": {
          "answerIndex": 1,
          "answered": false,
          "qText": "1. Which of the following is NOT discussed in Koning et al.?",
          "options": [
            "A. Strategic blind spots can occur when inventors are from a similar background.",
            "B. Female inventors tend to come up with inventions that exclusively benefit women.",
            "C. Women’s health is an area where all-female inventor teams are 35% more likely to innovate than all-male teams.",
            "D. There is a higher percentage of women with US PhDs in the life sciences than US life science patents held by women."
          ],
          "correctText": "Great job! B is the correct answer. In Paragraph 1, the author cites Koning et al.’s research, stating that “female inventors are more likely to produce patents to solve problems that specifically or disproportionately affect women.” In other words, women may benefit from the patents, but they are not the only group of people who find the patents helpful.",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read Paragraphs 1-4 again carefully to see what Koning et al. discusses in their research.",
            "This is not the correct answer. B is the correct answer. In Paragraph 1, the author cites Koning et al.’s research, stating “female inventors are more likely to produce patents to solve problems that specifically or disproportionately affect women.” I In other words, women may benefit from the patents, but they are not the only group of people who find the patents helpful.",
            null
          ]
        }
      },
      "2": {
        "type": "mcqWithHints",
        "userState": { "response": [] },
        "subSectionTitle": "",
        "questionData": {
          "correctText": "Great job! B is the correct answer. In the first paragraph, the author expands on this idea stating, \"They will explore a solution space differently, making unusual connections linking otherwise disparate insights\".",
          "qText": "2. What does the author mean when she states that, \"Diverse inventors 'see' the world differently.\" ?",
          "options": [
            "A. Diverse inventors have different opinions on how to treat cataracts.",
            "B. Diverse inventors have unique perspectives due to their varied experiences.",
            "C. Diverse inventors typically travel the world and therefore have different experiences.",
            "D. Diverse inventors are atypical, with a greater focus on vision-related concerns."
          ],
          "answered": false,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the sentence and think about what it means to see the world differently. Look at the sentence directly after it to understand how the author further explains her idea. When you are ready, choose the correct answer.",
            "This is not the correct answer. B is the correct answer. In the first paragraph, the author expands on this idea stating, \"They will explore a solution space differently, making unusual connections linking otherwise disparate insights\".",
            null
          ],
          "answerIndex": 1
        }
      },
      "3": {
        "type": "mcqWithHints",
        "userState": { "response": [] },
        "questionData": {
          "answered": false,
          "answerIndex": 0,
          "options": [
            "A. Defining terminology",
            "B. Identifying causes of a phenomenon",
            "C. Comparing examples of gender inequality",
            "D. Citing relevant literature"
          ],
          "correctText": "Great job! A is the correct answer.  Throughout the paper, the author explains two reasons why women are unrepresented in the innovation system, making several references to previous findings and offering examples of the \"valuation discount\" encountered by women. No big terminology is used in the paper, so no effort is made to define it.",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Take a look at the four options and try to link each option to the contents in the paper. By doing so, it will help you figure out which writing strategy is not used by the author.",
            "Hint 3 This is not the correct answer. A is the correct answer. Throughout the paper, the author explains two reasons why women are unrepresented in the innovation system, making several references to previous findings and offering examples of the \"valuation discount\" encountered by women. No big terminology is used in the paper, so no effort is made to define it.",
            null
          ],
          "qText": "3. Which of the following writing strategies is NOT used by the author to illustrate her points?"
        },
        "subSectionTitle": ""
      },
      "4": {
        "subSectionTitle": "",
        "questionData": {
          "options": [
            "A. decrease",
            "B. reinforce",
            "C. restrain",
            "D. improve"
          ],
          "correctText": "Great job! C is the correct answer. Both restrain and stifle mean to hold back or repress the allocation of diverse talent.",
          "answerIndex": 2,
          "qText": "4. In the last paragraph, the author states, \"Similarly, in areas of mission-driven innovation, the sense of purpose that drives underrepresented groups is a powerful force for change. But simply using traditional approaches to prioritize and select projects and people without appreciating the subtle dynamics at play across the entire system <b>stifles</b> the allocation of diverse talent across the entire innovation economy and does not bring the best and brightest to bear on the most pressing challenges.\"   The word \"stifle\" is similar to ...",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the excerpt again and consider what the author is describing is happening to the diversity of innovators when using traditional approaches of selection.",
            "This is not the correct answer. C is the correct answer. Both restrain and stifle mean to hold back or repress the allocation of diverse talent.",
            null
          ],
          "answered": false
        },
        "type": "mcqWithHints",
        "userState": { "response": [] }
      },
      "5": {
        "userState": { "response": [] },
        "subSectionTitle": "",
        "type": "mcqWithHints",
        "questionData": {
          "answerIndex": 1,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read Paragraph 5 again carefully to find the two necessary parts of the innovation system. ",
            "This is not the correct answer. B is the correct answer. In Paragraph 6, the author mentions two major parts of the innovation system: one is \"the domains prioritized and funded\", and the other is the \"individuals selected as inventors.\"",
            null
          ],
          "options": [
            "A. key projects and people",
            "B. funded areas and inventors",
            "C. priorities of inventors and innovators",
            "D. mechanisms and the innovation landscape"
          ],
          "answered": false,
          "qText": "5. What are the two main parts of the innovation system?",
          "correctText": "Great job! B is the correct answer. In Paragraph 5, the author mentions two major parts of the innovation system: one is \"the domains prioritized and funded\", and the other is the \"individuals selected as inventors.\""
        }
      },
      "6": {
        "userState": { "response": [] },
        "type": "mcqWithHints",
        "subSectionTitle": "",
        "questionData": {
          "options": [
            "A. Different to one another in a number of significant aspects",
            "B. Opposite to one another in a number of significant aspects",
            "C. Relevant to one another in a number of significant aspects",
            "D. Similar to one another in a number of significant aspects"
          ],
          "qText": "6. In the first paragraph it states, \"They show that female inventors are more likely to produce patents to solve problems that specifically or disproportionately affect women (such as menopause and fibromyalgia). Beyond recognizing the loss of human talent that arises when women are underrepresented in innovation, this finding highlights the types of problems (and solutions) that are overlooked in the current system with its support of a <b>homogeneous</b> group of inventors.\" What does \"homogeneous\" refer to in this excerpt?",
          "answered": false,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the text again and try to think about what \"its\" in the last sentence refers to. What might the author be saying about this group of inventors?",
            "This is not the correct answer. D is the correct answer. \"Homogeneous\" means to be cohesive or uniform, therefore the best answer is \"Similar to one another\". The word \"its\" in the last sentence refers to \"the current system\" which is largely made up of male inventors, as opposed to a more diverse group of people.",
            null
          ],
          "correctText": "Great job! D is the correct answer. \"Homogeneous\" means to be cohesive or uniform, therefore the best answer is \"Similar to one another\". The word \"its\" in the last sentence refers to \"the current system\" which is largely made up of male inventors, as opposed to a more diverse group of people.",
          "answerIndex": 3
        }
      },
      "7": {
        "userState": { "response": [] },
        "subSectionTitle": "",
        "type": "mcqWithHints",
        "questionData": {
          "answerIndex": 0,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "Review the section in the article where the author talks about systems. Think about what she says about systems. When you are ready, choose the correct answer.",
            "This is not the correct answer. A is the correct answer. Systems involve a variety of elements (including people and ideas) and the author refers to systems in this article to highlight the complexity of these systems.",
            null
          ],
          "qText": "7. Why does the author focus on systems throughout the text?",
          "answered": false,
          "options": [
            "A. to highlight the complexity of the ways different elements effect outcomes",
            "B. to point out specific individuals who are responsible for the current circumstances",
            "C. to shift the focus to things we cannot change, rather than people who are responsible",
            "D. to illustrate how women and other underrepresented groups oversee new processes"
          ],
          "correctText": "Great job! A is the correct answer. Systems involve a variety of elements (including people and ideas) and the author refers to systems in this article to highlight the complexity of these systems."
        }
      },
      "8": {
        "userState": { "response": [] },
        "type": "mcqWithHints",
        "questionData": {
          "options": [
            "A. To ensure that everyone can earn equal pay.",
            "B. To support innovation expanding into more areas.",
            "C. To increase innovation in women’s health areas.",
            "D. To provide more employment for underrepresented minorities."
          ],
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Consider what the potential outcome might be if there was more diversity among innovators.",
            "This is not the correct answer. B is the correct answer. It is important that multiple perspectives are brought into the innovation process so a wider range of innovation can take place.",
            null
          ],
          "correctText": "Great job! B is the correct answer. It is important that multiple perspectives are brought into the innovation process so a wider range of innovation can take place.",
          "qText": "8. According to the article, why is it important that women and underrepresented minorities be involved at all levels of the innovation process?",
          "answered": false,
          "answerIndex": 1
        },
        "subSectionTitle": ""
      },
      "9": {
        "questionData": {
          "correctText": "Great job! C is the correct answer. In the paper, the author states that \"few venture funders prioritized women’s health or markets for female-oriented products\".",
          "qText": "9. Why has women’s health been marginalized in terms of academic or commercial investment?",
          "options": [
            "A. Because markets for male-oriented products are larger.",
            "B. Because female-oriented areas of interest are competitive.",
            "C. Because funders have made female-oriented markets a lesser priority.",
            "D. Because funders have failed to see the profits of investing in women’s health."
          ],
          "answerIndex": 2,
          "answered": false,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read Paragraph 6 carefully again to find the reason why women’s health hasn’t been a focus until recently.",
            "This is not the correct answer. C is the correct answer. In the paper, the author states that \"few venture funders prioritized women’s health or markets for female-oriented products\".",
            null
          ]
        },
        "subSectionTitle": "",
        "type": "mcqWithHints",
        "userState": { "response": [] }
      },
      "10": {
        "type": "mcqWithHints",
        "questionData": {
          "options": [
            "A. to draw the reader's attention to a popular phrase that everyone knows",
            "B. to strengthen her argument by referring to a term from another article",
            "C. to highlight how female innovators are often treated in the workplace",
            "D. to argue that there are a lot of women in the hard sciences, but no one sees them"
          ],
          "correctText": "Great job! B is the correct answer. Often authors refer to other published works in order to strengthen or clarify their arguments.",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read through the paragraph and consider why an author would include a specific term in quotes. When you are ready, choose the correct answer.",
            "This is not the correct answer. B is the correct answer. Often authors refer to other published works in order to strengthen or clarify their arguments.",
            null
          ],
          "qText": "10. Why does the author include the phrase \"invisible women\" in the following paragraph?  \"A key system-level driver of these choices has been women's underrepresentation in the highest levels of decision-making (where markets or diseases are prioritized for investment) and in grant and investment making (where specific choices are made). Being absent shapes attention, or lack thereof, to these critical problems, leading to what Criado-Perez has called <b>\"invisible women\"</b> (7). More broadly, a lack of women in decision-making roles has likely shaped decisions— by predominantly male investors—to overlook key problems and markets. The same system dynamics are true for underrepresented minorities. Not having diverse voices at the table defining problem spaces perpetuates a system that narrows the projects and problems that are prioritized.\"",
          "answered": false,
          "answerIndex": 1
        },
        "subSectionTitle": "",
        "userState": { "response": [] }
      },
      "11": {
        "questionData": {
          "qText": "11. \"Consider what funders are searching for - how problems and missions are scoped and prioritized both in early-stage science and in later-stage translation. It is notable that women’s health has, until recently, been viewed as a <b>marginal</b> area of academic or commercial investment where few venture funders prioritized women’s health or markets for female-oriented products.\" In this context, which of the following words is closest in meaning to the word \"marginal\"?",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the context again and pay attention to the meaning of the word \"few\" in the last sentence.",
            "This is not the correct answer. D is the correct answer. \"Insignificant\" is closest in meaning to the word \"marginal\". Both words describe how women’s health has not been a priority or central area of focus. The word \"few\" explicitly demonstrates the insignificant position of women’s health or female-oriented products in the area of academic and commercial investment.",
            null
          ],
          "options": [
            "A. emerging",
            "B. remarkable",
            "C. dated",
            "D. insignificant"
          ],
          "correctText": "Great job! D is the correct answer. \"Insignificant\" is closest in meaning to the word \"marginal\". Both words describe how women’s health has not been a priority or central area of focus.",
          "answerIndex": 3,
          "answered": false
        },
        "subSectionTitle": "",
        "userState": { "response": [] },
        "type": "mcqWithHints"
      },
      "12": {
        "userState": { "response": [] },
        "questionData": {
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Women are represented on 25% of patents but hold 50% of PhDs. Why is there such a significant difference?",
            "This is not the correct answer. A is the correct answer. The barriers at all stages of the innovation process need to be broken down.",
            null
          ],
          "qText": "12. Choose the best alternate title for the article.",
          "answered": false,
          "correctText": "Great job! A is the correct answer. The barriers at all stages of the innovation process need to be broken down.",
          "answerIndex": 0,
          "options": [
            "A. Breaking down systemic barriers in the innovation process",
            "B. How women and underrepresented groups work together in research projects",
            "C. How to get more women interested in women’s health research",
            "D. Using traditional approaches to select women for research projects"
          ]
        },
        "subSectionTitle": "",
        "type": "mcqWithHints"
      },
      "13": {
        "questionData": {
          "answerIndex": 3,
          "answered": false,
          "correctText": "Great job! D is the correct answer. The paper states: \"A \"quick fix,\" e.g., training aimed at unconscious bias, is not enough. Considering who is at the table making selection decisions and the criteria they use are essential to system change.\" We can see that \"encouraging an interactive environment\" is not mentioned.",
          "qText": "13. Which of the following is NOT essential to change the biased innovation system?",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read Paragraph 10 again carefully to find the three essential factors stated regarding changing the biased innovation system.",
            "This is not the correct answer. D is the correct answer. The paper states: \"A \"quick fix,\" e.g., training aimed at unconscious bias, is not enough. Considering who is at the table making selection decisions and the criteria they use are essential to system change.\" We can see that \"encouraging an interactive environment\" is not mentioned.",
            null
          ],
          "options": [
            "A. adopting bias-free criteria",
            "B. raising awareness about bias",
            "C. diversifying the decision makers",
            "D. encouraging an interactive environment"
          ]
        },
        "type": "mcqWithHints",
        "subSectionTitle": "",
        "userState": { "response": [] }
      },
      "14": {
        "userState": { "response": [] },
        "subSectionTitle": "",
        "type": "mcqWithHints",
        "questionData": {
          "options": [
            "A. intelligible",
            "B. static",
            "C. intimidating",
            "D. ambiguous"
          ],
          "qText": "14. In paragraph 12, it states: \"When overlaying upon this system of selection a context in which diverse problem domains and missions are often underfunded or overlooked (for lack of effective diversity within priority setting bodies), then the drivers of underrepresentation on many dimensions in the innovation economy become clearer—albeit more <b>daunting</b>.\" According to the excerpt, the word \"daunting\" is closest in meaning to",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the excerpt again and try to consider the authors’ perspective regarding the issue of underrepresentation and lack of diversity.",
            "This is not the correct answer. C is the correct answer. \"Intimidating\" is closest in meaning to \"daunting\". While the drivers of underrepresentation become clearer when we notice the lack of diversity, this remains a significant problem to contend with, and is therefore a daunting one.",
            null
          ],
          "correctText": "Great job! C is the correct answer. \"Intimidating\" is closest in meaning to \"daunting\". While the drivers of underrepresentation become clearer when we notice the lack of diversity, this remains a significant problem to contend with, and is therefore a daunting one.",
          "answerIndex": 2,
          "answered": false
        }
      },
      "15": {
        "userState": { "response": [] },
        "type": "mcqWithHints",
        "questionData": {
          "options": [
            "A. to encourage more women and individuals from underrepresented groups to innovate",
            "B. to encourage funding bodies to give female-led projects more money",
            "C. to encourage underrepresented groups to apply for scientific grants and pursue new topics",
            "D. to encourage funding bodies to include individuals from underrepresented groups in their decision making"
          ],
          "answerIndex": 3,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Re-read the article and consider the strongest reason the author provided for writing it. Think about who the audience is and the greatest issues she discussed. When you are ready, choose the correct answer.",
            "This is not the correct answer. D is the correct answer. Throughout the text the author explains that funding bodies need to bring those from unrepresented groups \"to the table\" to make funding decisions.",
            null
          ],
          "correctText": "Great job! D is the correct answer. Throughout the text the author explains that funding bodies need to bring those from unrepresented groups \"to the table\" to make funding decisions.",
          "answered": false,
          "qText": "15. What is the most likely reason the author wrote this article?"
        },
        "subSectionTitle": ""
      },
      "16": {
        "questionData": {
          "answered": false,
          "correctText": "Great job! A is the correct answer. Representation of people from diverse backgrounds needs to be at all stages of the selection process.",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Think about who creates the projects and who selects the researchers and what is missing.",
            "This is not the correct answer. A is the correct answer. Representation of people from diverse backgrounds needs to be at all stages of the selection process.",
            null
          ],
          "answerIndex": 0,
          "qText": "16. According to the article, how should the best projects be selected for research?",
          "options": [
            "A. People from diverse backgrounds should be represented at all levels of the selection process.",
            "B. Experts in relevant research areas should be represented at all levels of the selection process.",
            "C. Project selection committees should award a quota of projects to applicants from underrepresented groups.",
            "D. Proper incentives should be provided for diverse groups to apply for research projects."
          ]
        },
        "userState": { "response": [] },
        "subSectionTitle": "",
        "type": "mcqWithHints"
      },
      "17": {
        "userState": { "response": [] },
        "type": "mcqWithHints",
        "subSectionTitle": "",
        "questionData": {
          "answered": false,
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Read the context again and pay attention to the word \"cumulative\".",
            "This is not the correct answer. C is the correct answer. Both amplify and magnify mean to increase or augment.",
            null
          ],
          "options": [
            "A. His work efforts  will  <u>diminish</u> after the deadline.",
            "B. The government plans to <u>exempt</u> fully vaccinated travelers from quarantine.",
            "C. Technology will <u>amplify</u> company productivity outcomes.",
            "D. She will <u>establish</u> a more accurate system of tracking revenues and expenses."
          ],
          "answerIndex": 2,
          "correctText": "Great job! C is the correct answer. Both amplify and magnify mean to increase or augment.",
          "qText": "17. The author wrote, \"The interaction between what is prioritized and who is selected has important, unintended, cumulative, system-wide consequences that <b>magnify</b> inequality at every stage—from grant funding, to patenting, to translation and venture funding.\" Which of the following underlined words is closest in meaning to \"magnify\"?"
        }
      },
      "18": {
        "subSectionTitle": "",
        "questionData": {
          "answerIndex": 3,
          "qText": "18. Which of the following is NOT a main idea in the article?",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Think about representation, selection process, areas of need, and training. Which one is not an issue highlighted in the article?",
            "This is not the correct answer. D is the correct answer. Training is not mentioned as a main issue in the article, but better representation at all stages of the innovation process is an issue.",
            null
          ],
          "answered": false,
          "correctText": "Great job! D is the correct answer. Training is not mentioned as a main issue in the article, but better representation at all stages of the innovation process is an issue.",
          "options": [
            "A. Women are underrepresented in the highest levels of project decision-making.",
            "B. The selection process needs to be fairer to women and underrepresented groups.",
            "C. A group of diverse inventors can see more areas of need than a group from a similar background.",
            "D. Women and underrepresented groups need better training to do more research."
          ]
        },
        "type": "mcqWithHints",
        "userState": { "response": [] }
      },
      "19": {
        "subSectionTitle": "",
        "type": "mcqWithHints",
        "userState": { "response": [] },
        "questionData": {
          "answered": false,
          "answerIndex": 0,
          "options": [
            "A. women’s unfair funding appraisals",
            "B. women’s insignificant innovation skills",
            "C. women’s interest in male-focused business",
            "D. women’s call for an inclusive work environment"
          ],
          "correctText": "Great job! A is the correct answer. Compared to men, women encounter unfair evaluations of their proposals for funding in traditionally male-focused business sectors.",
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Find where \"valuation discount\" appears in the paper and try to figure out how women are affected by reading the surrounding text.",
            "This is not the correct answer. A is the correct answer. Compared to men, women encounter unfair evaluations of their proposals for funding in traditionally male-focused business sectors.",
            null
          ],
          "qText": "19. What does the \"valuation discount\" refer to?"
        }
      },
      "20": {
        "questionData": {
          "answered": false,
          "options": [
            "A. funder's typical approaches to prioritizing and selecting projects and inventors",
            "B. innovative ways to incorporate diverse individuals and ways of thinking into project selection",
            "C. specific projects and innovations led by women and others from underrepresented groups",
            "D. Patricia Bath's life story and her innovative patent to treat cataracts"
          ],
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Review the article and think about the way it ends. Look at the final paragraph and try to imagine what the author might write about next. When you are ready, choose the correct answer.",
            "This is not the correct answer. B is the correct answer.  In the final paragraph the author explains the dangers of \"using traditional approaches to prioritize and select projects and people\", so it is very possible that she would next write about some non-traditional, or innovative approaches to select projects.",
            null
          ],
          "answerIndex": 1,
          "correctText": "Great job! B is the correct answer. In the final paragraph the author explains the dangers of \"using traditional approaches to prioritize and select projects and people\", so it is very possible that she would next write about some non-traditional, or innovative approaches to select projects.",
          "qText": "20. If this article were longer, what might the author most likely discuss next?"
        },
        "userState": { "response": [] },
        "subSectionTitle": "",
        "type": "mcqWithHints"
      },
      "21": {
        "userState": { "response": [] },
        "subSectionTitle": "",
        "questionData": {
          "hints": [
            "This is not the correct answer. Read through the paper again and try to locate the necessary information for answering this question. When you are ready, choose the correct answer.",
            "This is not the correct answer. Think about how the author describes women’s experiences in grant-making settings.",
            "This is not the correct answer. C is the correct answer. The article says that women might receive smaller funding grants than men which makes women’s productivity over time lower than men’s. The result is that men get more projects because they seem more productive.",
            null
          ],
          "correctText": "Great job! C is the correct answer. The article says that women might receive smaller funding grants than men which makes women’s productivity over time lower than men’s. The result is that men get more projects because they seem more productive.",
          "qText": "21. According to the article, what is problematic about selecting project applicants based on merit?",
          "answerIndex": 2,
          "options": [
            "A. Measures of quality need to be made more transparent to applicants.",
            "B. Underrepresented minorities do not have enough experience.",
            "C. Objective measures of quality are not always fair.",
            "D. Underrepresented minorities cannot compete against others."
          ],
          "answered": false
        },
        "type": "mcqWithHints"
      },
      "sectionTitle": "1.3. Article Comprehension Questions",
      "sectionIconTitle": "Comprehend",
      "userState": { "locked": true, "completed": false },
      "sectionIcon": "quiz"
    },
    "3": {
      "0": {
        "text": "Please take some time to think about the article that you read and to review your notes. ",
        "userState": { "viewed": false },
        "type": "text",
        "subSectionTitle": ""
      },
      "1": {
        "text": "When you are ready, write 3 questions inspired by the article.  Make sure that the questions aren't too specific.  For example, \"What is the name of the author who wrote the paper?\"  <b>is not</b> something you can elaborate on or that fosters further expansion and discussion.  ",
        "userState": { "viewed": false },
        "type": "text",
        "subSectionTitle": ""
      },
      "2": {
        "subSectionTitle": "",
        "text": "When you have finished typing your questions, please review them carefully and click <b>submit</b>.",
        "userState": { "viewed": false },
        "type": "text"
      },
      "3": {
        "subSectionTitle": "",
        "type": "quiz",
        "questions": [
          {
            "hideWordCount": true,
            "userState": { "response": [""] },
            "submissionAlertText": "Great! What other questions can you think of?",
            "qText": "Question Number 1:",
            "qId": "0",
            "qType": "open-res"
          }
        ]
      },
      "4": {
        "type": "quiz",
        "subSectionTitle": "",
        "questions": [
          {
            "qId": "1",
            "qType": "open-res",
            "hideWordCount": true,
            "submissionAlertText": "Almost there! Can you think of one more question?",
            "qText": "Question Number 2:",
            "userState": { "response": [""] }
          }
        ]
      },
      "5": {
        "questions": [
          {
            "feedback": "See the <a style='padding:0;' href='/resources/written'>Writing Resources Section</a> for a list of question forms.​",
            "userState": { "response": [""] },
            "qType": "open-res",
            "qText": "Question Number 3:",
            "hideWordCount": true,
            "submissionAlertText": "Success! You’ve submitted your three questions.",
            "qId": "2",
            "sectionComplete": true
          }
        ],
        "type": "quiz",
        "subSectionTitle": ""
      },
      "sectionIcon": "comment",
      "userState": { "locked": true, "completed": false },
      "sectionIconTitle": "Ask",
      "sectionTitle": "1.4 Ask Discussion Questions"
    },
    "4": {
      "0": {
        "text": "In higher education, students are required to engage with and familiarize themselves with disciplinary-specific vocabulary. Through practice using this vocabulary, comfort and fluency will improve. In this task, you will read a challenging excerpt from the article out loud. Be sure to speak naturally and clearly. When you are ready, click the  <b>Record</b>  button and begin speaking. Click  <b>Stop recording</b>  when you are done, then click the  <b>Submit</b>  button.  ",
        "type": "text",
        "userState": { "viewed": true },
        "subSectionTitle": ""
      },
      "1": {
        "userState": { "viewed": true },
        "text": "Diverse inventors “see” the world differently. They will explore a solution space differently, making unusual connections linking otherwise disparate insights. What Koning et al. highlight is that diverse inventors also identify with different problems or missions—overcoming strategic blind spots that will otherwise occur if inventors are drawn from very similar backgrounds. Their approach, applied to over 440,000 US patents, is an entirely new way to map the problem spaces (i.e., applications) explored by inventors. This mapping convincingly shows the connection between inventor identity and invention domain across the life sciences (constituting over 10% of US patenting activity from 1976 to 2010). The authors show that all-female inventor teams are 35% more likely to innovate in areas of women’s health than all-male teams (and female-majority teams are 18% more likely to have a women’s focus than male-majority teams). ",
        "type": "Read Aloud",
        "subSectionTitle": "",
        "submitRequired": true,
        "allowMultipleSubmit": true,
        "saveLocation": "written/readArticle/"
      },
      "sectionTitle": "1.5 Read an Article Excerpt",
      "sectionIcon": "readAloud",
      "sectionIconTitle": "Speak",
      "userState": { "locked": true, "completed": false }
    },
    "5": {
      "0": {
        "userState": { "viewed": true },
        "type": "text",
        "subSectionTitle": "",
        "text": ""
      },
      "1": {
        "userState": { "viewed": true },
        "type": "vocab",
        "subSectionTitle": ""
      },
      "sectionIcon": "readAloud",
      "sectionIconTitle": "Monitor",
      "userState": { "locked": true, "completed": false },
      "sectionTitle": "1.6 Monitor your Vocabulary Knowledge"
    },
    "6": {
      "0": {
        "draft": false,
        "subSectionTitle": "",
        "url": [
          "pdf/Invention.pdf",
          "pdf/corporate.pdf",
          "pdf/inventors.pdf",
          "pdf/three.pdf",
          "pdf/mothers.pdf"
        ],
        "type": "firstAssessment",
        "userState": { "viewed": true, "response": null },
        "afterModule": "Thank you for your work developing the first draft of your critical reflection. Please click <b>Submit</b> and move on to the next task."
      },
      "userState": { "completed": false, "locked": true },
      "sectionIconTitle": "Write",
      "sectionIcon": "readAloud",
      "sectionTitle": "1.7 Write a First Draft"
    },
    "7": {
      "0": {
        "subSectionTitle": "",
        "userState": { "viewed": true },
        "type": "text",
        "text": "Think about the writing skills you used during the development of your first draft. How did you do? Please use the assessment below to rate your performance. When you are finished the draft, click  <b>Submit.</b> "
      },
      "1": {
        "sectionTitle": "How I demonstrated critical skills in my writing",
        "userState": { "viewed": true },
        "subSectionTitle": "",
        "type": "selfAssess",
        "questionArr": [
          "1. I provided a brief summary of the paper.",
          "2. I presented my arguments clearly.",
          "3. I provided evidence to support my arguments.",
          "4. I considered potential biases in the article.",
          "5. I thought about alternative or new perspectives on the topic that differed from the article.",
          "6. I critically evaluated the article (e.g., identified and questioned underlying assumptions presented)."
        ]
      },
      "2": {
        "type": "selfAssess",
        "questionArr": [
          "7. My vocabulary was mostly accurate; errors did not affect comprehension.",
          "8. My grammar was mostly accurate with some minor errors.",
          "9. I used a range of vocabulary related to the topic of the article.",
          "10. I used a range of simple and complex grammar forms.",
          "11. I used a range of transition words, such as “however” or “moreover”, to connect my ideas."
        ],
        "userState": { "viewed": true },
        "subSectionTitle": "",
        "sectionTitle": "How I used language"
      },
      "3": {
        "sectionTitle": "How I adhered to academic integrity",
        "questionArr": [
          "12. I provided citations to the original sources I used with appropriate academic style conventions (e.g., APA).",
          "13. I paraphrased others’ ideas with my own words using appropriate academic style conventions (e.g., MLA).",
          "14. I provided a reference list using appropriate academic style conventions (e.g., Chicago)."
        ],
        "subSectionTitle": "",
        "userState": { "viewed": true, "response": null },
        "type": "selfAssess"
      },
      "4": {
        "userState": { "viewed": true, "response": null },
        "type": "selfAssess",
        "subSectionTitle": "",
        "sectionTitle": "How I organized my writing",
        "questionArr": [
          "15. I thought about the overall structure of my paper.",
          "16. I made sure my paper had a clear introduction, summary, critique and conclusion.",
          "17. I made sure the ideas in my sentences had a logical flow and connection."
        ]
      },
      "5": {
        "type": "selfAssess",
        "subSectionTitle": "",
        "sectionTitle": "How I completed the task requirements",
        "userState": { "response": null, "viewed": true },
        "questionArr": [
          "18. I paid attention to and followed the task instructions.",
          "19. I planned how to complete the writing task.",
          "20. I monitored my writing progress and finished my first draft within the time limit.",
          "21. I monitored my writing progress and completed the task with the appropriate word length.",
          "22. I reviewed my first draft and made revisions.",
          "23. I utilized additional resources that were provided."
        ]
      },
      "sectionIcon": "readAloud",
      "sectionTitle": "1.8 Self-Assess Your Written Work",
      "sectionIconTitle": "Reflect",
      "userState": { "locked": true, "completed": false }
    },
    "8": {
      "0": {
        "type": "secondAssessment",
        "userState": { "viewed": true, "response": null },
        "url": [
          "pdf/Invention.pdf",
          "pdf/corporate.pdf",
          "pdf/inventors.pdf",
          "pdf/three.pdf",
          "pdf/mothers.pdf"
        ],
        "subSectionTitle": "",
        "draft": false,
        "index": "6",
        "afterModule": "Thank you for your work on the final draft of your critical reflection. Please click <b>Submit</b> and move on to the next task.",
        "key": "0",
        "instructions": "<div>You will have 40 minutes to read an excerpt from an article. You should take notes as you read. All of the tasks in the module will be based on this reading, including the final critical paper. You will be able to access and refer back to the article at any time throughout the tasks.</div><div>Once you are ready, click <b>start</b> to access the article. Click on the <b>finished</b> button when you have completed the reading and are ready to move to the next task. </div>"
      },
      "sectionIcon": "readAloud",
      "sectionIconTitle": "Finalize",
      "sectionTitle": "1.9 Finalize and Submit Your Written Work",
      "userState": { "completed": false, "locked": true }
    },
    "9": {
      "0": {
        "text": "CONGRATULATIONS! You have completed the Written Communication Module.  To access your Certificate of Completion, click on the button below then click on <b>Survey & Certificate</b>.",
        "subSectionTitle": "",
        "type": "text",
        "userState": { "viewed": false }
      },
      "1": {
        "userState": { "viewed": false },
        "type": "imgLink",
        "subSectionTitle": "​",
        "images": [
          {
            "link": "modulePage",
            "src": "images/academicIntegrity/t6i8.jpg",
            "urlType": "internal",
            "header": ""
          }
        ]
      },
      "sectionTitle": "2.0 Link to Completion Certificate",
      "sectionIcon": "certificate",
      "sectionIconTitle": "Complete",
      "userState": { "completed": false, "locked": true }
    }
  },
};

export default t1;
