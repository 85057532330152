import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, TableCell, Typography, TableHead } from "@material-ui/core";

const TableHeader = (props) => {
  const classes = useStyles();

  return (
    <TableHead className={classes.root}>
      <TableRow>
        <TableCell className={classes.question}>
          <Typography>
            {props.sectionTitle}
          </Typography>
        </TableCell>
        <TableCell className={classes.radio}>
          <Typography>
            <strong>{props.scale ? props.scale[0] : "Very Poor"}</strong>
          </Typography>
        </TableCell>
        <TableCell className={classes.radio}>
          <Typography>
            <strong>{props.scale ? props.scale[1] : "Poor"}</strong>
          </Typography>
        </TableCell>
        <TableCell className={classes.radio}>
          <Typography>
            <strong>{props.scale ? props.scale[2] : "Fair"}</strong>
          </Typography>
        </TableCell>
        <TableCell className={classes.radio}>
          <Typography>
            <strong>{props.scale ? props.scale[3] : "Good"}</strong>
          </Typography>
        </TableCell>
        <TableCell className={classes.radio}>
          <Typography>
            <strong>{props.scale ? props.scale[4] : "Very Good"}</strong>
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const useStyles = makeStyles({
  root: {
    margin: "auto",
  },
  radio: {
    border: "solid",
    borderColor: "white",
    borderWidth: "1px",
    padding: "1rem",
    width: "10%",
    textAlign: "center",
  },
  question: {
    border: "solid",
    borderColor: "white",
    borderWidth: "1px",
    padding: "1rem",
  },
});

export default TableHeader;
