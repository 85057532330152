import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import SurveyForm from "../../components/Util/SurveyForm/SurveyForm.js";
// Form Data
import {demographicForm as demographicFormData} from '../../static/forms/DemographicForm.js'

class DemoForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
      }


    render() {
        if (this.props.profile.onboarding === false) { return <Redirect to='/overview'></Redirect>; }

        return (
            <SurveyForm 
                form={demographicFormData} 
                userId={this.props.auth.uid} 
                finishOnBoarding={true}
                redirect={'/overview'}
            >
            </SurveyForm>  
        );
    }
}

const mapStateTOProps = (state) => {
    return {
        auth: state.firebase.auth,
        profile: state.firebase.profile
    }
}

export default connect(mapStateTOProps)(DemoForm);