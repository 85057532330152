import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Container, CardColumns, Row, Col } from 'react-bootstrap';
import ImgCard from '../../Util/Card/Card.js'
import Typography from '@material-ui/core/Typography';


class ModuleWindow extends Component{
    render(){
        const moduleSnippets = Object.entries(this.props.moduleData.snippets)
        if (moduleSnippets.length === 0){
                
                return(
                    <Container fluid>
                        <br/>
                        <Typography variant="h4">{this.props.moduleType.charAt(0).toUpperCase() + this.props.moduleType.slice(1)} Building</Typography>
                        <hr/> 
                        <Container className="d-flex justify-content-center" style={{padding:"100px"}}>
                        <Row>
                            <Col>
                                <img 
                                    style={{"width": "300px", "height":"300px"}} 
                                    src={require('../../../static/images/home/ComingSoon.jpg')} 
                                />
                            </Col>
                        </Row>
                        </Container>
                    </Container>
                );
        };
        return(
            <Container fluid>
                <br/>
                <Typography variant="h4">{this.props.moduleType.charAt(0).toUpperCase() + this.props.moduleType.slice(1)} Building</Typography>
                <hr/> 
                <CardColumns style={{ maxWidth: 1200 }}>
                {moduleSnippets.map((item, index) => 
                    <ImgCard  
                        key={"module-card-"+ index}
                        header={item[1].title} 
                        moduleId={item[1].moduleId} 
                        description={item[1].description} 
                        link={"/" + this.props.moduleType + "/" + item[1].moduleId}
                    />
                )}
                </CardColumns>
            </Container>
        );
    }
}

const mapStateTOProps = (state) => {
    return {
      moduleData: state.moduleData.moduleSnippets
    };
};
  
export default connect(mapStateTOProps)(ModuleWindow);