import React, { useMemo, useState } from "react";
import { Container, Grid, Tab, Tabs } from "@material-ui/core";
import aggResults from "./agg_results.json";
import rawResults from "./raw_results.json";
import analysisData from "./additional-data.json";
import UserView from "./UserView";
import AdminView from "./AdminView";

const rawResponse = rawResults;
const aggResponse = aggResults;

/* typescript-friendly implementation */
export const getEntries = (obj) => Object.entries(obj);

/**
 *  Does the student response have any answers?
 */
export const responseIsNull = (response) =>
  getEntries(response)
    .filter(
      ([k, _]) =>
        k.toString().startsWith("written") || k.toString().startsWith("oral")
    )
    .every(([_, v]) => v === null);

export const getCategoryKey = (key) =>
  key.startsWith("oral")
    ? "oral"
    : key.startsWith("written_1.3")
    ? "written"
    : key === "written_1.6_vocab_size"
    ? "vocab"
    : null;

export const groupScoresByCategory = (v) =>
  getEntries(v)
    .filter(([_, v]) => !!v)
    .reduce((acc, [k, v]) => {
      const key = getCategoryKey(k);
      if (key) {
        return {
          ...acc,
          [key]: acc[key] ? acc[key].concat(v) : [v],
        };
      } else return acc;
    }, {});

export const groupAndAverage = (v) => {
  const grouped = groupScoresByCategory(v);

  return Object.entries(grouped).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: v.reduce((acc, curr) => curr + acc, 0) / v.length,
    }),
    {}
  );
};

const getAveragesForQuantile = (q) => {
  return getEntries(q).reduce(
    (acc, [k, v]) => ({
      ...acc,
      [k]: groupAndAverage(v),
    }),
    {}
  );
};

const buildQuantileAverages = (res) => {
  const _recurse = (arg, ret = {}) => {
    for (const k in arg) {
      if (k === "q") {
        ret[k] = getAveragesForQuantile(arg[k]);
      } else if (typeof arg[k] === "object") {
        ret[k] = _recurse(arg[k]);
      } else {
        ret[k] = arg[k];
      }
    }
    return ret;
  };
  return _recurse(res);
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(0);

  const quantileAverages = useMemo(() => {
    if (aggResponse) {
      return buildQuantileAverages(aggResponse);
    }
  }, [aggResponse]);

  return (
    <Container>
      <Grid spacing={2} container alignItems="center" direction="column">
        <Grid item>
          <Tabs onChange={(_, i) => setActiveTab(i)} value={activeTab}>
            <Tab label="User view" />
            <Tab label="Admin view" />
          </Tabs>
        </Grid>
        <Grid item>
          {activeTab === 0 && quantileAverages && (
            <UserView
              aggResults={aggResponse}
              quantileAverages={quantileAverages}
              rawResults={rawResponse.filter((f) => !responseIsNull(f))}
              analysisData={analysisData}
            />
          )}
          {activeTab === 1 && <AdminView rawResults={rawResponse} />}
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
