import {auth} from '../../FirestoreRedux.js';

export const authenticatePassword = (oldPassword, newPassword) => {

    try {
            auth.signInWithEmailAndPassword(
                auth.currentUser.email, 
                oldPassword

            ).then(function(data) {
                if (data.user.uid) {
                    changePassword(newPassword)
                }
            }

            )
            .catch(function(error) {
                // Handle Errors here.
                var errorMessage = error.message;
                console.log(errorMessage)
            })

    } catch(e) {
        return {e}
    }
}

export const changePassword = (newPassword) => {

    try {
        return auth.currentUser.updatePassword(newPassword).then(function() {
                // Update successful.
               // window.location.href='/onboarding/consent_form'
                return true
            }).catch(function(error) {
                // An error happened.
                var errorMessage = error.message;
                console.log(errorMessage)
                return false
            })
        
        

    } catch(e) {
        return {e}
    }
    
}
