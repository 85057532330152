import {auth, functions, db} from '../../../FirestoreRedux.js';
import { moduleOptIn } from '../contentManagement/dbService';
import {consentForm} from '../../../static/forms/ConsentForm';
import {demographicForm} from '../../../static/forms/DemographicForm';
import {users} from './users'
import { getAllModuleIds } from '../../../components/Wrappers/ContentBuilder/dbHelpers.js';

/**
 * Create a new user using userData provided.
 * User is created with the firebase auth service and a 
 * one time password is created for the user.
 * User data is stored in the users collection in firebase. 
 * 
 * @param {firstName, lastName, email, accountType} userData 
 */
export async function createUser(userData){
    let userDocumentData;

    const moduleIds = ["AcademicIntegrity", "oral", "WritingModule"]
    if (userData.accountType === "student"){
        userDocumentData = createStudentDocument(userData)
    }
     if (userData.accountType === "issd-dev"){
         userDocumentData = createDeveloperDocument(userData)
     }
     userDocumentData = userData;
    await auth.createUserWithEmailAndPassword(userDocumentData.email, userDocumentData.password)
    .then(function(data){
        db.collection('users').doc(data.user.uid).set(userDocumentData);
        db.collection('users').doc(data.user.uid).collection('modules').doc('snippets').set({});
        moduleIds.forEach((moduleId) => {
            moduleOptIn(data.user.uid, moduleId)
        })
        addFormsToUser(data.user.uid)
        //const sendOTPEmail = functions.httpsCallable('sendOTPEmail');
        //sendOTPEmail({email: userDocumentData.email, name: userDocumentData.firstName, otp: userDocumentData.OTP})
        //.then(function(res) {
        //    console.log("Email Sent");
        //})
        //.catch(function(err) {
        //    console.log(userDocumentData.email, `error: ${JSON.stringify(err)}`);
        //})
    })
    .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage)
        alert(errorMessage)
        // ...
    });
    return null
}

/** Add consent and demographic forms to collection forms in user
 * 
 * @param {*} uid 
 */
function addFormsToUser(uid) {
    db.collection('users').doc(uid).collection('forms').doc('consentForm').set({
        consentForm
    })
    db.collection('users').doc(uid).collection('forms').doc('demographicForm').set({
        demographicForm
    })
}

/**
 * Create document to be added in the user collections
 * for a student user.
 */
function createStudentDocument(userData){
    let documentData = userData;
    documentData.actionItems = {}; // Empty field for action items
    //documentData.OTP = generateOTP(); // One time password while user is created
    documentData.creationTime = new Date(); // Creation date
    documentData.onboarding = true;
    return documentData;
}

/**
 * Create document to be added in the user collections
 * for a developer user.
 */
function createDeveloperDocument(userData){
    let documentData = userData;
    documentData.onboarding = true;
    documentData.actionItems = {}; // Empty field for action items
    documentData.OTP = generateOTP(); // One time password while user is created
    documentData.creationTime = new Date(); // Creation date
    return documentData;
}

/**
 * Create a numeric one time password for the user. 
 */
function generateOTP(){
    var digits = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@';
    var otpLength = 20;
    var otp = '';

    for(let i=1; i<=otpLength; i++){
        var index = Math.floor(Math.random()*(digits.length));
        otp = otp + digits[index];
    }
    return otp;
}

function readCSV(csv) {
    let vals = csv.split('\n');
    let new_arr = [];
    for( let i = 1, len = vals.length; i < len; i++ ) {
        let user = vals[i].split(',');
        new_arr.push({
            firstName: user[0],
            lastName: user[1],
            email: user[2],
            accountType: 'student'
        });
    }
    return new_arr
}
export async function userSignup(email, first, last, password){
    const user = {
        firstName: first,
        lastName: last,
        email: email,
        password: password,
        accountType: 'student'
    }
    await createUser(user)
    console.log("Create Account For ", email)
}
export async function addUsersFromCSV() {
    // readfile in one big string
    let total = 0
    const allUsers = readCSV(users)
    for (let i = 0; i < allUsers.length; i++){
        await createUser(allUsers[i])
        total++
    }
    console.log(total, " users added")
}