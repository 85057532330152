import React from "react";
import Faq from "../../../components/Wrappers/Docs/faq.js"
import DashFrame from '../../../components/DashFrame/DashFrame'
import '../../../CSS_share.css'

function FAQPage( props ) {
    return (
        <DashFrame>
            <Faq/>
        </DashFrame>
    );
}
export default FAQPage;