const t6=    {
    "content": {
      "0": {
        "0": {
          "subSectionTitle": "",
          "text": "In Topic 1, you were introduced to Academic Integrity. For more information on Academic Integrity, click below for the University of Toronto Governing Council’s <b>Code of Behaviour on Academic Matters</b>. It details faculty and students’ shared responsibilities in relation to teaching and learning. ",
          "type": "text",
          "userState": { "viewed": false }
        },
        "1": {
          "images": [
            {
              "src": "images/academicIntegrity/t6i1.jpg",
              "link": "https://governingcouncil.utoronto.ca/system/files/2020-03/Code%20of%20Behaviour%20on%20Academic%20Matters%20July%201%202019.pdf",
              "urlType": "external",
              "header": "U of T’s Code of Behaviour on Academic Matters"
            }
          ],
          "userState": { "viewed": false },
          "type": "imgLink",
          "subSectionTitle": "​"
        },
        "sectionIcon": "info",
        "sectionIconTitle": "Topic 1",
        "sectionTitle": "6.1. Introduction to Academic Integrity - Review",
        "userState": { "locked": false, "completed": false }
      },
      "1": {
        "0": {
          "text": "In Topic 2, you learned how to evaluate whether a source is <i>credible</i> (or trustworthy). For more information on choosing the best sources, click below.",
          "type": "text",
          "subSectionTitle": "",
          "userState": { "viewed": false }
        },
        "1": {
          "subSectionTitle": "​",
          "images": [
            {
              "src": "images/academicIntegrity/t6i2.jpg",
              "urlType": "external",
              "link": "https://guides.library.utoronto.ca/c.php?g=251905&p=1675733",
              "header": "U of T’s Guide on Choosing the best sources"
            },
            {
              "src": "images/academicIntegrity/t6i3.jpg",
              "header": "How to identify non-credible sources​",
              "urlType": "external",
              "link": "https://sites.umgc.edu/library/libhow/credibility.cfm"
            }
          ],
          "userState": { "viewed": false },
          "type": "imgLink"
        },
        "sectionTitle": "6.2. How to Evaluate Resources",
        "sectionIcon": "info",
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Topic 2"
      },
      "2": {
        "0": {
          "text": "In Topic 3, you learned how to paraphrase or use direct quotes. For more information on citing sources and avoiding plagiarism, click below.",
          "userState": { "viewed": false },
          "subSectionTitle": "",
          "type": "text"
        },
        "1": {
          "type": "imgLink",
          "userState": { "viewed": false },
          "subSectionTitle": "​",
          "images": [
            {
              "header": "How to Paraphrase",
              "urlType": "external",
              "link": "https://www.uc.utoronto.ca/paraphrase",
              "src": "images/academicIntegrity/t6i4.jpg"
            },
            {
              "src": "images/academicIntegrity/t6i5.jpg",
              "link": "https://advice.writing.utoronto.ca/using-sources/ ",
              "header": "Avoiding Plagiarism​",
              "urlType": "external"
            }
          ]
        },
        "sectionIcon": "info",
        "userState": { "locked": true, "completed": false },
        "sectionTitle": "6.3. How to Use the Ideas of Others​",
        "sectionIconTitle": "Topic 3"
      },
      "3": {
        "0": {
          "text": "In Topic 4, you learned how to create in-text citations and reference lists in APA. Watch the video and and notice the differences in formatting styles for journal articles, books, and websites in APA and MLA. You will see there are a lot of very detailed rules to cite these sources accurately. As you will see, it is very important to get a style guide when writing your papers.​​",
          "subSectionTitle": "",
          "type": "text",
          "userState": { "viewed": false }
        },
        "1": {
          "type": "video",
          "subSectionTitle": "APA and MLA compared",
          "srcType": "url",
          "url": "videos/academicIntegrity/t6.mp4",
          "userState": { "viewed": false }
        },
        "2": {
          "text": "For more information, click below. It contains information on several different citation styles, tutorials, style guides, and other supplementary material to help you understand, practice, and produce correct citations.",
          "userState": { "viewed": false },
          "type": "text",
          "subSectionTitle": ""
        },
        "3": {
          "subSectionTitle": "​",
          "images": [
            {
              "link": "https://guides.library.utoronto.ca/c.php?g=251103&p=1741147",
              "header": "",
              "urlType": "external",
              "src": "images/academicIntegrity/t6i6.jpg"
            }
          ],
          "type": "imgLink",
          "userState": { "viewed": false }
        },
        "sectionTitle": "6.4. How to Credit the Ideas of Others​",
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Topic 4",
        "sectionIcon": "video"
      },
      "4": {
        "0": {
          "userState": { "viewed": false },
          "text": "In Topic 5, you learned about ethical group participation. For more information on academic integrity in group work, click below.​​ ",
          "type": "text",
          "subSectionTitle": ""
        },
        "1": {
          "userState": { "viewed": false },
          "subSectionTitle": "​",
          "type": "imgLink",
          "images": [
            {
              "src": "images/academicIntegrity/t6i7.jpg",
              "link": "https://uwaterloo.ca/academic-integrity/integrity-students/group-work",
              "header": "",
              "urlType": "external"
            }
          ]
        },
        "sectionIconTitle": "Topic 5",
        "sectionTitle": "6.5. How to Maintain Academic Integrity in Group Assignments​​",
        "userState": { "locked": true, "completed": false },
        "sectionIcon": "info"
      },
      "5": {
        "0": {
          "type": "text",
          "text": "CONGRATULATIONS! You have completed the Academic Integrity Module.  To access your Certificate of Completion, click on the button below, and then click on <b>Survey & Certificate</b>.",
          "userState": { "viewed": false },
          "subSectionTitle": ""
        },
        "1": {
          "images": [
            {
              "src": "images/academicIntegrity/t6i8.jpg",
              "urlType": "internal",
              "header": "",
              "link": "modulePage"
            }
          ],
          "type": "imgLink",
          "userState": { "viewed": false },
          "subSectionTitle": "​"
        },
        "userState": { "completed": false, "locked": true },
        "sectionIconTitle": "Topic 6",
        "sectionIcon": "certificate",
        "sectionTitle": "6.6. Completion Certificate"
      }
    },
    "subModuleTitle": "Conclusion and Additional Resources",
    "userState": { "locked": false, "progress": 0 }
  }

export default t6;